import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Input } from 'baseui/input';
import { HeadingXSmall, LabelMedium, LabelSmall } from 'baseui/typography';
import { CAPTCHA_TOKEN } from 'constants/tokens';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import FormControl from 'components/FormControl';

import { useRegister } from 'api/auth';
import ApiError from 'api/errors';

import { SignupCreds } from 'types/auth';

export default function SignupForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [css, theme] = useStyletron();

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [registerUser, registerMutation] = useRegister({
    onMutate: async () => {
      if (process.env.NODE_ENV === 'production') {
        const token = await recaptchaRef.current?.executeAsync();
        console.log('token', token);
      }
    },
  });
  const { handleSubmit, formState, control } = useForm<SignupCreds>();
  const { isSubmitting } = formState;
  const errorMessage = () => {
    const apiError = registerMutation.error as ApiError;
    const errors: string[] = [];
    if (apiError.errors) {
      apiError.errors.forEach((responseError: { error: string }) => {
        const key = responseError.error.split(':').filter(Boolean)[0];
        const message = responseError.error.split(':').filter(Boolean)[1];
        if (key === 'error_description' || key === 'username') {
          errors.push(message);
        }
      });
      console.error(`${apiError.code} ${apiError.message}`, errors.join('\n'));
    }
    return errors[0] ?? 'Signup failed,try again';
  };

  const signupRoute = !location.pathname.includes('confirm-booking');
  const onSubmitCallback = handleSubmit(async (values) => {
    await registerUser(values, {
      onSuccess: () => {
        navigate(signupRoute ? 'verify' : '/confirm-booking/verify');
      },
      onFailure: ({ error }) => {
        const apiError = error as ApiError;
        const errors: string[] = [];
        if (apiError.errors) {
          apiError.errors.forEach((responseError: { error: string }) =>
            errors.push(responseError.error),
          );
          console.error(
            `${apiError.code} ${apiError.message}`,
            errors.join('\n'),
          );
        }
      },
    });
  });

  return (
    <Block
      paddingTop={theme.sizing.scale900}
      paddingLeft={[
        0,
        theme.sizing.scale900,
        theme.sizing.scale1600,
        theme.sizing.scale3200,
      ]}
      paddingRight={[
        0,
        theme.sizing.scale900,
        theme.sizing.scale1600,
        theme.sizing.scale3200,
      ]}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      })}
    >
      <FlexGrid flexGridColumnCount={1} flexGridRowGap={theme.sizing.scale1400}>
        <HeadingXSmall as={FlexGridItem} className={css({ fontWeight: 700 })}>
          {signupRoute ? 'Sign up' : 'Signup to complete booking'}
        </HeadingXSmall>
        <FlexGridItem>
          <form
            onSubmit={onSubmitCallback}
            className={css({ display: 'flex', flexDirection: 'column' })}
          >
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { value, ...fieldProps }, fieldState }) => (
                <FormControl
                  label="First Name"
                  error={fieldState.error?.message}
                >
                  <Input
                    type="text"
                    {...fieldProps}
                    error={!!fieldState.error?.message}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { value, ...fieldProps }, fieldState }) => (
                <FormControl
                  label="Last Name"
                  error={fieldState.error?.message}
                >
                  <Input
                    type="text"
                    {...fieldProps}
                    error={!!fieldState.error?.message}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="requestingOrg"
              render={({ field: { value, ...fieldProps }, fieldState }) => (
                <FormControl
                  label="Affiliation (Name of Organization/Office/Agency)"
                  error={fieldState.error?.message}
                >
                  <Input
                    type="text"
                    {...fieldProps}
                    error={!!fieldState.error?.message}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="email"
              rules={{
                required: 'This field is required',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Enter valid email',
                },
              }}
              render={({ field: { value, ...fieldProps }, fieldState }) => (
                <FormControl
                  label="Email Address"
                  error={fieldState.error?.message}
                >
                  <Input
                    type="email"
                    {...fieldProps}
                    error={!!fieldState.error?.message}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="contactNumber"
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^(09|\+639)\d{9}$/,
                  message: 'Enter valid mobile number',
                },
              }}
              render={({ field: { value, ...fieldProps }, fieldState }) => (
                <FormControl
                  label="Mobile Number"
                  error={fieldState.error?.message}
                >
                  <Input
                    type="text"
                    placeholder="Ex. 09123456789 / +639123456789"
                    {...fieldProps}
                    error={!!fieldState.error?.message}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { value, ...fieldProps }, fieldState }) => (
                <FormControl label="Password" error={fieldState.error?.message}>
                  <Input
                    type="password"
                    {...fieldProps}
                    error={!!fieldState.error?.message}
                  />
                </FormControl>
              )}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              $style={{
                marginTop: '1.25rem',
                alignSelf: 'flex-end',
              }}
            >
              Complete {signupRoute ? 'Signup' : 'Booking'}
            </Button>
            <ReCAPTCHA
              ref={recaptchaRef}
              style={{
                opacity: 0,
                bottom: '48px',
              }}
              // asyncScriptOnLoad={() => {
              //   const el: HTMLElement | null =
              //     document.querySelector('.grecaptcha-badge');
              //   if (el && el.style) {
              //     el.style.bottom = '48px';
              //   }
              // }}
              size="invisible"
              sitekey={CAPTCHA_TOKEN}
            />
          </form>
          {registerMutation.status === 'failure' && (
            <LabelMedium
              className={css({
                marginTop: theme.sizing.scale300,
                textAlign: 'center',
                color: theme.colors.negative,
              })}
            >
              {errorMessage()}
            </LabelMedium>
          )}
        </FlexGridItem>
      </FlexGrid>

      {signupRoute && (
        <Link
          className={css({
            marginTop: theme.sizing.scale1600,

            textAlign: 'center',
          })}
          to="/"
        >
          <LabelSmall color={theme.colors.primary}>
            {' '}
            Have an account? Log in instead
          </LabelSmall>
        </Link>
      )}
    </Block>
  );
}
