import { refresh } from '../api/auth';
import { setHeader } from '../services/api';
import { UserTokens } from '../types/auth';

export const storeTokens = (tokens: UserTokens) => {
  localStorage.setItem('token_data', JSON.stringify(tokens));
  setHeader('Authorization', `${tokens.tokenType} ${tokens.accessToken}`);
};

export const checkTokens = () => {
  const stored = localStorage.getItem('token_data');

  if (stored) {
    return true;
  }

  return false;
};

export function getTokens() {
  const tokens = JSON.parse(
    localStorage.getItem('token_data') ?? '{}',
  ) as UserTokens;
  return checkTokens() ? tokens : null;
}

export const getNewToken = async () => {
  const stored = localStorage.getItem('token_data');
  if (!stored) {
    alert('Cannot get new token without a refresh token.');
    window.location.href = '/logout';
  } else {
    try {
      const json: UserTokens = JSON.parse(stored);
      const { tokenType, accessToken, refreshToken } = await refresh(
        json.refreshToken,
      );

      storeTokens({
        tokenType,
        accessToken,
        refreshToken,
        isAdmin: json.isAdmin,
      });

      return accessToken;
    } catch (err) {
      // if refresh token is invalid, logout
      localStorage.removeItem('token_data');
    }
  }

  return false;
};

export const refreshTokens = async () => {
  const stored = localStorage.getItem('token_data');

  if (stored) {
    try {
      const json: UserTokens = JSON.parse(stored);
      const { tokenType, accessToken, refreshToken } = await refresh(
        json.refreshToken,
      );

      storeTokens({
        tokenType,
        accessToken,
        refreshToken,
        isAdmin: json.isAdmin,
      });
    } catch (err) {
      // if refresh token is invalid, logout
      localStorage.removeItem('token_data');
    }
  }
};
