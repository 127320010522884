/* eslint-disable react/require-default-props */
import { Responsive, Scale } from 'baseui/block';
import { FlexGrid, FlexGridItem, FlexGridProps } from 'baseui/flex-grid';
import React, { Children } from 'react';

type OwnProps = {
  x?: Responsive<Scale>;
  y?: Responsive<Scale>;
  isEqual?: boolean;
  children: React.ReactNode;
};

export default function SpaceBetween({
  children,
  x: xSpace,
  y: ySpace,
  isEqual,
  ...blockProps
}: OwnProps & Omit<FlexGridProps, keyof OwnProps | 'ref'>) {
  // const [css] = useStyletron();
  // const spaceValueCss = stitchesCSS(
  //     xSpace
  //         ? {
  //               "& > * + *": {
  //                   marginLeft: xSpace,
  //               },
  //           }
  //         : {
  //               "& > * + *": {
  //                   marginTop: ySpace,
  //               },
  //           }
  // );

  return (
    <FlexGrid
      flexGridColumnGap={xSpace}
      flexGridRowGap={ySpace}
      flexDirection={
        blockProps.flexGridColumnCount === 1 ? 'column' : undefined
      }
      {...blockProps}
      flexGridColumnCount={
        blockProps.flexGridColumnCount
          ? blockProps.flexGridColumnCount
          : React.Children.count(children)
      }
      flexWrap={false}
    >
      {Children.map(children, (child, index) => (
        <FlexGridItem
          // FIXME: Don't use index
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          overrides={{
            Block: {
              style: !isEqual
                ? {
                    width: 'auto',
                    flex: 'none',
                  }
                : {},
            },
          }}
        >
          {child}
        </FlexGridItem>
      ))}
    </FlexGrid>
  );
}
