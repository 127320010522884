/* eslint-disable react/require-default-props */
import { mergeOverrides } from 'baseui';
import {
  Banner as BaseBanner,
  PropsT as BaseBannerProps,
  KIND,
  StyledRoot,
} from 'baseui/banner';
import React, { useEffect, useRef } from 'react';

import SchedulerTheme, { withStyle } from 'theme';

type OwnProps = { show?: boolean };
type BannerProps = BaseBannerProps & OwnProps;

type StyledRootProps = {
  $nested: boolean;
  $kind: keyof KIND;
};
const Root = withStyle<typeof StyledRoot, StyledRootProps>(
  StyledRoot,
  ({ $kind }) => ({
    ...getKindStyles($kind),
  }),
);
export default function Banner(props: BannerProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const { overrides, show = true, ...propsWithoutOverrides } = props;
  const { kind } = props;
  useEffect(() => {
    if (show && rootRef.current) {
      rootRef.current.focus();
    }
  }, [show]);

  if (show) {
    return (
      <BaseBanner
        {...propsWithoutOverrides}
        overrides={mergeOverrides(
          {
            Root: {
              component: Root,
              props: { $kind: kind, ref: rootRef, tabIndex: 0 },
            },
          },
          overrides,
        )}
      />
    );
  }
  return null;
}

function getKindStyles(kind?: keyof KIND) {
  if (!kind) return Object.freeze({});
  switch (kind) {
    case KIND.negative: {
      return {
        color: SchedulerTheme.colors.negative,
        backgroundColor: SchedulerTheme.colors.negative50,
      };
    }
    case KIND.info: {
      return {
        color: SchedulerTheme.colors.white,
        backgroundColor: SchedulerTheme.colors.primary,
      };
    }
    case KIND.positive: {
      return {
        color: SchedulerTheme.colors.positive,
        backgroundColor: SchedulerTheme.colors.positive50,
      };
    }
    case KIND.warning: {
      return {
        color: SchedulerTheme.colors.warning,
        backgroundColor: SchedulerTheme.colors.warning50,
      };
    }
    default:
      return Object.freeze({});
  }
}
