export enum BookingStatus {
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
  DONE = 'Done',
  DRAFT = 'Draft',
  FOR_CONFIRMATION = 'For Confirmation',
  NO_REP = 'No Rep',
  REP = 'Rep',
}

export type Booking = {
  id: number;
  meetingType: number;
  bookingToken: string;
  location: Partial<{
    name: string;
    address: string;
  }>;

  agenda: string;
  details: string;
  duration: number;
  status: BookingStatus;
  date: Date;
  timeslot: {
    start: Date;
    end: Date;
  };
  attendees: Array<{
    id: number;
    name: string;
  }>;
  timezone: string;
  role: { id: number; name: string };
  sectors: Array<{ id: number; name: string }>;
  otherSector: string | undefined;
  audience: Array<{ id: number; name: string }>;
  otherAudience: string | undefined;
  dressCode: { id: number; name: string } | undefined;
  meetingLink: string | undefined;
  particiapantsCount: number;
  messagePlatform: string | undefined;
  requestingOrganization: string | undefined;
  hideTime: boolean;
  countryId: string;
  regionId: string;
  provinceId: string;
  cityId: string;
};

export type BookingAttachment = {
  id: string;
  link: string;
  type: string;
  name: string;
  size: number;
};

export type BookingOptions = {
  dressCode: BookingOption[];
  meetingTypes: MeetingTypeOption[];
  sectors: BookingOption[];
  audience: BookingOption[];
};

export type MeetingTypeOption = BookingOption & {
  roles: BookingOption[];
};

export type MeetingRoleOption = {
  id: number;
  name: string;
};

export type DressCodeOption = {
  id: number;
  name: string;
};

export type BookingOption = {
  id: number;
  name: string;
};
