/* eslint-disable react/require-default-props */
import type { ComponentProps } from 'react';
import React, { forwardRef } from 'react';

import { parseToUtils, stitchesConfig } from 'theme';

const { styled, config } = stitchesConfig;
const { space, borderWidths, colors } = config.theme;

const spaceX = parseToUtils(space, (key) => ({
  '& > :not([hidden]) ~ :not([hidden])': {
    marginLeft: `$space$${key}`,
  },
}));
const spaceY = parseToUtils(space, (key) => ({
  '& > :not([hidden]) ~ :not([hidden])': {
    marginTop: `$space$${key}`,
  },
}));
const padX = parseToUtils(space, (key) => ({
  '& >:not([hidden])': {
    paddingLeft: `$space$${key}`,
    paddingRight: `$space$${key}`,
  },
}));
const padY = parseToUtils(space, (key) => ({
  '& > :not([hidden])': {
    paddingTop: `$space$${key}`,
    paddingBottom: `$space$${key}`,
  },
}));

const divideX = parseToUtils(borderWidths, (key) => ({
  '& > :not([hidden]) ~ :not([hidden])': {
    borderLeftWidth: `$borderWidths$${key} `,
    borderStyle: 'solid',
  },
}));

const divideY = parseToUtils(borderWidths, (key) => ({
  '& > :not([hidden]) ~ :not([hidden])': {
    borderTopWidth: `$borderWidths$${key}`,
    borderStyle: 'solid',
  },
}));

const divideColor = parseToUtils(colors, (key) => ({
  '& > :not([hidden]) ~ :not([hidden])': {
    borderColor: key,
  },
}));

const StyledBox = styled('div', {
  variants: {
    spaceY,
    spaceX,
    padX: {
      ...padX,
      'self-none': {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },
    padY,
    divideX: {
      true: {
        '& > :not([hidden]) ~ :not([hidden])': {
          borderLeftWidth: `$borderWidths$1 `,
          borderStyle: 'solid',
        },
      },
      ...divideX,
    },
    divideY: {
      true: {
        '& > :not([hidden]) ~ :not([hidden])': {
          borderTopWidth: `$borderWidths$1`,
          borderStyle: 'solid',
        },
      },
      ...divideY,
    },
    divideColor,
    layout: {
      stack: {
        display: 'flex',
        flexDirection: 'column',
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    stack: {
      true: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    row: {
      true: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
  },
});

type OwnProps = {
  as?: keyof JSX.IntrinsicElements;
};

export type BoxProps = Omit<ComponentProps<typeof StyledBox>, keyof OwnProps> &
  OwnProps;

const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <StyledBox {...props} ref={ref} />
));
export default Box;
