import { Image, InsertDriveFile, MessageSharp } from '@mui/icons-material';
import { KIND, SIZE } from 'baseui/button';
import { Skeleton } from 'baseui/skeleton';
import React from 'react';
import { useParams } from 'react-router-dom';

import { LinkButton } from 'components/Button';

import { Button, Text } from 'components';

import { useBookingAttachment } from 'services/queries/bookings';

import { BookingAttachment } from 'types/bookings';

import { useStyletron } from 'theme';

export default function BookingAttachments({ id }: { id: string }) {
  const [css, theme] = useStyletron();
  const bookingAttachmentQuery = useBookingAttachment(id);

  if (bookingAttachmentQuery.error) {
    return (
      <div>
        <Text css={{ color: theme.colors.negative }}>
          Failed to load attachments for this booking
        </Text>
        <Button
          type="button"
          size={SIZE.mini}
          kind={KIND.tertiary}
          onClick={() => bookingAttachmentQuery.mutate()}
        >
          Try again
        </Button>
      </div>
    );
  }
  if (!bookingAttachmentQuery.attachments) {
    return <Skeleton rows={4} />;
  }

  if (bookingAttachmentQuery.attachments.length === 0) {
    return (
      <div>
        <Text>No Files added</Text>
        <LinkButton
          to="edit"
          size={SIZE.mini}
          kind={KIND.tertiary}
          $style={{
            marginTop: theme.sizing.scale300,
            marginLeft: `-${theme.sizing.scale300}`,
          }}
        >
          Add File/s
        </LinkButton>
      </div>
    );
  }

  return (
    <ul>
      {bookingAttachmentQuery.attachments.map((attachment, index) => (
        <li
          key={attachment.id}
          className={css({
            marginTop: index !== 0 ? theme.sizing.scale300 : 0,
          })}
        >
          <FileItem attachment={attachment} />
        </li>
      ))}
    </ul>
  );
}

const imageMimeTypes = ['jpg', 'png', 'jpeg'];
const documentMimeTypes = ['pdf', 'csv', 'docx', 'plain'];

function FileItem({ attachment }: { attachment: BookingAttachment }) {
  const [css, theme] = useStyletron();
  const fileType = attachment.type.split('/')[1];

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
      })}
    >
      {imageMimeTypes.includes(fileType) && (
        <Image className={css({ color: theme.colors.primary })} />
      )}
      {documentMimeTypes.includes(fileType) && (
        <InsertDriveFile className={css({ color: theme.colors.primary })} />
      )}
      <a
        href={attachment.link}
        target="_blank"
        rel="noreferrer"
        className={css({
          marginLeft: theme.sizing.scale100,
          ...theme.typography.LabelSmall,
          ':hover': {
            textDecoration: 'underline',
          },
        })}
      >
        {attachment.name}
      </a>
    </div>
  );
}
