/* eslint-disable react/require-default-props */
import { DatePicker } from 'baseui/datepicker';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Input } from 'baseui/input';
import { Textarea } from 'baseui/textarea';
import { TimePicker } from 'baseui/timepicker';
import { HeadingXSmall } from 'baseui/typography';
import { addMinutes, format, isAfter, isBefore, isEqual } from 'date-fns';
import React from 'react';
import { useForm } from 'react-hook-form';

import { FormControlLabel } from 'components/FormControl';
import { MeetingTypeSelect } from 'components/Select';
import SpaceBetween from 'components/SpaceBetween';

import { FileInput, FormController } from 'components';

import { useStyletron } from 'theme';

import {
  BasicInformationFormFields,
  BookingFormAction,
  TBookingForm,
} from './types';

/**
 * TODO: Handle editable prop when booking is confirmed
 */

export default function BasicInformationForm(
  props: TBookingForm<BasicInformationFormFields>,
) {
  const {
    initalValue = {},
    id: formId,
    onSubmit: submitHandler,
    action = BookingFormAction.CREATE,
    editable,
  } = props;
  const [css, theme] = useStyletron();
  const { control, handleSubmit, watch, setValue } =
    useForm<BasicInformationFormFields>({
      defaultValues: initalValue,
    });
  const values = watch();
  const onSubmit = handleSubmit((data) => {
    if (editable) {
      submitHandler(data);
    } else {
      submitHandler({
        ...initalValue,
        files: data.files,
      } as BasicInformationFormFields);
    }
  });

  return (
    <form
      id={formId}
      onSubmit={onSubmit}
      className={css({
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <HeadingXSmall
        as="h3"
        $style={{
          fontWeight: 600,
        }}
      >
        Basic Information
      </HeadingXSmall>
      <SpaceBetween
        flexGridColumnCount={[1, 1, 2]}
        x={theme.sizing.scale600}
        isEqual
      >
        <FormController
          control={control}
          name="type"
          rules={{
            required: 'Select Meeting',
          }}
          label={<FormControlLabel required>Event Type</FormControlLabel>}
          disabled={!editable}
        >
          {({ field }) => (
            <MeetingTypeSelect
              value={field.value?.value}
              inputRef={field.ref}
              onChange={(params) => {
                field.onChange(params.option);
              }}
            />
          )}
        </FormController>
        <FormController
          control={control}
          name="date"
          label={<FormControlLabel required>Event Date</FormControlLabel>}
          rules={{
            required: 'Select Date',
          }}
          disabled={!editable}
        >
          {({ field }) => (
            <DatePicker
              minDate={new Date()}
              value={field.value}
              onChange={({ date }) => {
                if (date && date instanceof Date) {
                  date.setHours(8);
                  date.setMinutes(0);
                  date.setMilliseconds(0);
                  field.onChange(date);
                }
              }}
            />
          )}
        </FormController>
      </SpaceBetween>
      <FlexGrid
        flexGridColumnCount={[1, 1, 2]}
        flexGridColumnGap={theme.sizing.scale600}
      >
        <FlexGridItem>
          <FormController
            control={control}
            name="timeslot.start"
            defaultValue={undefined}
            rules={{
              required: 'Select Timeslot',
              validate: {
                'is-same-as-end': (value) =>
                  isEqual(value, values.timeslot.end)
                    ? 'Starting timeslot must not be the same as starting timeslot'
                    : true,
                'is-max-time': (value) =>
                  isAfter(value, values.timeslot.end)
                    ? 'Starting timeslot must not be later than ending timeslot'
                    : true,
              },
            }}
            label={
              <FormControlLabel required>Starting Timeslot</FormControlLabel>
            }
            disabled={!editable}
          >
            {({ fieldState, field }) => (
              <TimePicker
                error={!!fieldState.error?.message}
                placeholder="Select Time"
                value={field.value}
                onChange={(date) => {
                  field.onChange(date);
                  if (date) {
                    const endTimeSlot = addMinutes(date, 30);
                    const hasDateChanged =
                      format(date, 'yyyy-MM-dd') !==
                      format(endTimeSlot, 'yyyy-MM-dd');
                    /**
                     * This is to prevent the computed end slot date to be converted to the next day
                     */
                    setValue(
                      'timeslot.end',
                      hasDateChanged ? date : endTimeSlot,
                      { shouldValidate: true },
                    );
                  }
                }}
                step={1800}
                disabled={
                  values.date === undefined &&
                  fieldState.error?.message === undefined
                }
                creatable={false}
                ignoreMinMaxDateComponent
                nullable
              />
            )}
          </FormController>
        </FlexGridItem>
        <FlexGridItem>
          <FormController
            control={control}
            name="timeslot.end"
            defaultValue={undefined}
            rules={{
              required: 'Select Timeslot',
              validate: {
                'is-same-as-start': (value) =>
                  isEqual(value, values.timeslot.start)
                    ? 'Ending timeslot must not be the same as starting timeslot'
                    : true,
                'is-earlier-than-start': (value) =>
                  isBefore(value, values.timeslot.start)
                    ? 'Ending timeslot must not be earlier than starting timeslot'
                    : true,
              },
            }}
            label={
              <FormControlLabel required>Ending Timeslot</FormControlLabel>
            }
            disabled={!editable}
          >
            {({ field, fieldState }) => (
              <TimePicker
                error={!!fieldState.error?.message}
                value={field.value}
                nullable
                step={1800}
                placeholder="Select Time"
                onChange={(date) => field.onChange(date)}
                // minTime={getMinEndTimeSlot(values.timeslot?.start)}
                // maxTime={getMaxTime(values.date)}
                disabled={
                  values.date === undefined &&
                  fieldState.error?.message === undefined
                }
                creatable={false}
                ignoreMinMaxDateComponent
              />
            )}
          </FormController>
        </FlexGridItem>
      </FlexGrid>
      <FormController
        control={control}
        defaultValue=""
        name="agenda"
        rules={{
          required: 'This field is required',
          maxLength: {
            value: 50,
            message: 'Agenda must not exceed 50 characters',
          },
        }}
        label={<FormControlLabel required>Event Title</FormControlLabel>}
        counter={{
          maxLength: 50,
        }}
        caption="A descriptive title for the event or appointment"
        disabled={!editable}
      >
        {({ field }) => <Input {...field} id={field.name} />}
      </FormController>
      <FormController
        control={control}
        name="description"
        rules={{
          required: 'This field is required',
        }}
        label={<FormControlLabel required>Event Description</FormControlLabel>}
        disabled={!editable}
      >
        {({ field, fieldState }) => (
          <Textarea
            {...field}
            id={field.name}
            error={!!fieldState.error?.message}
          />
        )}
      </FormController>
      <FormController
        control={control}
        name="files"
        defaultValue={[]}
        rules={{
          validate: {
            'has-invitation-letter': (value) => {
              if (action === BookingFormAction.EDIT) return true;

              const hasAttachment = value.some(Boolean);
              return hasAttachment
                ? true
                : 'Add an attachment file that serves an invitation letter.';
            },
          },
        }}
        label={<FormControlLabel required>File Upload</FormControlLabel>}
        caption={
          action === BookingFormAction.CREATE
            ? 'An invitation letter is required for every booking. Only PDF files are accepted.'
            : undefined
        }
      >
        {({ field, fieldState }) => (
          <FileInput
            defaultValue={field.value}
            errorMessage={fieldState.error?.message}
            onChange={(value) => field.onChange(value)}
          />
        )}
      </FormController>
    </form>
  );
}
