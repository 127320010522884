// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { Errors } from '../services/fetch2';

/**
 * Thrown when the API returns an error.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#ES6_Custom_Error_Class
 */
export default class ApiError extends Error {
  public readonly code: number;

  public readonly errors?: Errors;

  constructor(code: number, message: string, errors?: Errors) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.code = code;
    this.errors = errors;
  }
}
