import api from 'services/api';
import { Options } from 'use-mutation';
import useMutation from 'utils/hooks/use-mutation';

type SendMessagePayload = {
  message: string;
  id: string;
};

export function sendMessage(payload: SendMessagePayload) {
  return api.post(`/bookings/${payload.id}/messages`, payload);
}

export function useSendMessage(options?: Options<SendMessagePayload, any, any>) {
  return useMutation(sendMessage, options);
}
