import React from 'react';

import { useStyletron } from 'baseui';
import { HeadingSmall } from 'baseui/typography';
import seal from 'assets/images/ovp-seal.png';
import vector from 'assets/images/scheduler-vector.png';
import { Spinner } from 'baseui/spinner';

export default function SplashScreen() {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        minHeight: '100vh',
      })}
    >
      <div
        className={css({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.primary,
        })}
      >
        <img src={seal} width={200} height={200} alt="ovp seal" />
        <img
          src={vector}
          width={230}
          height={40}
          alt="scheduler vector"
          className={css({
            marginTop: theme.sizing.scale600,
          })}
        />
        <HeadingSmall
          color={theme.colors.white}
          $style={{
            textAlign: 'center',
            fontWeight: 400,
            marginTop: theme.sizing.scale300,
          }}
        >
          FOR THE OFFICE OF THE VICE PRESIDENT
        </HeadingSmall>
        <Spinner />
      </div>
    </div>
  );
}
