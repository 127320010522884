/* eslint-disable @typescript-eslint/no-explicit-any */
import { toDate } from 'date-fns-tz';

import {
  Booking,
  BookingAttachment,
  BookingOptions,
  BookingStatus,
} from 'types/bookings';
import { Note } from 'types/messages';

export function deserializeBooking(data: any): Booking {
  const audience = data.type_of_audience_ids as any[];
  const sectors = data.sector_ids as any[];
  const resolvedStatus = Object.entries(BookingStatus).find((entry) => {
    const [key] = entry;
    return key === data.state.toUpperCase();
  });

  return {
    id: data.id,
    bookingToken: data.access_token,
    location: {
      name: data.location_name || '',
      address: data.location_address || '',
    },
    agenda: data.name,
    attendees:
      data.attendees?.map((attendee: any) => ({
        id: attendee.id,
        name: attendee.name,
      })) ?? [],
    details: data.description ? data.description : '',
    meetingType: data.appointment_type_id,
    duration: data.duration,
    status: resolvedStatus
      ? (resolvedStatus[1] as BookingStatus)
      : BookingStatus.DRAFT,
    date: toDate(data.start_datetime, { timeZone: data.event_tz }),
    timeslot: {
      start: toDate(data.start_datetime, { timeZone: data.event_tz }),
      end: toDate(data.stop_datetime, { timeZone: data.event_tz }),
    },
    timezone: data.event_tz,
    sectors: sectors ?? [],
    otherSector: data.other_sector || '',
    audience: audience ?? [],
    otherAudience: data.other_audience || '',
    dressCode: data.dress_code_id || undefined,
    role: data.role_id,
    particiapantsCount: data.no_of_participants,
    messagePlatform: data.location || '',
    meetingLink: data.meeting_link || '',
    requestingOrganization: data.requesting_organization
    ? data.requesting_organization
    : undefined,
    hideTime: data.hide_time,
    countryId: data.country_id,
    regionId: data.region_id,
    provinceId: data.province_id,
    cityId: data.city_id,
  };
}

export function deserializeAttachment(data: any): BookingAttachment {
  return {
    id: data.create_date.includes('T')
      ? new Date(data.create_date).toISOString()
      : new Date(data.create_date.replace(/-/g, '/')).toISOString(),
    link: data.link,
    name: data.name,
    size: data.size,
    type: data.mimetype,
  };
}

export function deserializeBookingOptions(
  data: Record<string, any[]>,
): BookingOptions {
  return {
    meetingTypes: data.meeting_type.map((item) => ({
      id: item.id,
      name: item.name,
      roles: item.event_roles.map((role: any) => ({
        id: role.id,
        name: role.name,
      })),
    })),
    dressCode: data.dress_code.map((item) => ({
      id: item.id,
      name: item.name,
    })),
    audience: data.type_of_audience.map((item) => ({
      id: item.id,
      name: item.name,
    })),
    sectors: data.sector.map((item) => ({
      id: item.id,
      name: item.name,
    })),
  };
}

export function deserializeNote(data: Record<string, any>): Note {
  return {
    id: data.id,
    authorName: data.author_name,
    dateSent: data.datetime_send.includes('T')
      ? new Date(data.datetime_send)
      : new Date(data.datetime_send.replace(/-/g, '/')),
    isAuthor: data.is_author,
    isDiscussion: data.is_discussion,
    isNote: data.is_note,
    isNotification: data.is_notification,
    message: data.message,
    message_type: data.message_type,
  };
}
