import useSWR, { SWRConfiguration } from 'swr';

import { get } from 'services/api';

import { Booking, BookingAttachment, BookingOptions } from 'types/bookings';
import { Note } from 'types/messages';

import {
  deserializeAttachment,
  deserializeBooking,
  deserializeBookingOptions,
  deserializeNote,
} from 'deserializers/bookings';

type BookingListFilters = {
  appointment_status?: string;
  date_order?: string;
  appointment_type_id?: string;
  date_start?: string;
  date_end?: string;
};

export async function getBookingsList(
  endpoint: string,
  filters?: BookingListFilters,
) {
  const searchParams = new URLSearchParams();

  Object.entries(filters ?? {})
    .filter((entry) => Boolean(entry[1]))
    .map((entry) => {
      const [key, value] = entry;
      searchParams.set(key, value.toString());
      return null;
    });

  const response = await get(`${endpoint}?${searchParams.toString()}`, {});

  return Object.entries(response)
    .reduce((prev, current) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const bookingArray = current[1] as any[];
      return [
        ...prev,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...bookingArray.map((item: any) => deserializeBooking(item)),
      ];
    }, [] as Booking[])
    .reverse();
}
export function useBookings(args?: {
  filters?: BookingListFilters;
  config?: SWRConfiguration;
}) {
  const { data, error, isValidating, mutate } = useSWR<Booking[]>(
    [`/bookings`, args?.filters ?? {}],
    (endpoint: string, filters?: BookingListFilters) =>
      getBookingsList(endpoint, filters),
    {
      ...(args?.config ?? {}),
    },
  );

  return {
    bookings: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    error,
    mutate,
  };
}

export async function getBooking(endpoint: string) {
  const response = await get(endpoint);

  return deserializeBooking(response);
}

export function useBooking(id: string, config?: SWRConfiguration) {
  const { data, error, isValidating, mutate } = useSWR<Booking>(
    `/bookings/${id}`,
    getBooking,
    { ...(config || {}) },
  );

  return {
    booking: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    error,
    mutate,
  };
}

export async function getBookingAttachments(endpoint: string) {
  const response = await get(endpoint);
  return response.map((item: unknown) => deserializeAttachment(item));
}

export function useBookingAttachment(id: string, config?: SWRConfiguration) {
  const { data, error, isValidating, mutate } = useSWR<BookingAttachment[]>(
    `/bookings/${id}/attachments`,
    getBookingAttachments,
    {
      ...(config || {}),
      isPaused() {
        return Boolean(id) === false;
      },
    },
  );

  return {
    attachments: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    error,
    mutate,
  };
}

export async function getBookingOptions(endpoint: string) {
  const response = await get(endpoint);
  return deserializeBookingOptions(response);
}

export function useBookingOptions(config?: SWRConfiguration) {
  const { data, error, isValidating, mutate } = useSWR<BookingOptions>(
    `/bookings/options`,
    getBookingOptions,
    { ...(config || {}), revalidateIfStale: false },
  );

  return {
    bookingOptions: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    error,
    mutate,
  };
}

export async function getBookingNotes(endpoint: string) {
  const response = await get(endpoint);
  return response.notes.map((item: any) => deserializeNote(item));
}

export function useBookingNotes(id: string, config?: SWRConfiguration) {
  const { data, error, isValidating, mutate } = useSWR<Note[]>(
    id ? `/bookings/${id}/messages` : null,
    getBookingNotes,
    {
      ...(config ?? {}),
    },
  );

  return {
    notes: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    error,
    mutate,
  };
}
