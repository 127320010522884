import { User } from 'types/auth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deserializeUser(data: any): User {
  return {
    id: data.id,
    name: data.name,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    status: data.odoo_status,
    verified: data.is_verified,
    requestingOrganization: data.request_organization
      ? data.request_organization
      : '',
    isAdmin: data.appointment_admin ? data.appointment_admin : false,
  };
}
