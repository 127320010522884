/* eslint-disable react/require-default-props */
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { devtools } from 'zustand/middleware';
import shallow from 'zustand/shallow';

export type WizardHandler = (() => Promise<boolean>) | (() => boolean) | null;
export enum Actions {
  NEXT,
  PREV,
  BOTH,
  NONE,
}
export type StepShape = ReadonlyArray<readonly Step[]>;
export type Step = {
  segment: string;
  name: string;
};

export type State = {
  steps: StepShape;
  meta: {
    fallbackURL: string;
    basePath?: string;
    allowedAction?: Actions[];
    currentStepName: string;
    nextStepSegment: null | string;
    previousStepSegment: null | string;
    nextStepMeta?: Step | null;
    previousStepMeta?: Step | null;
    step: number;
    part: number;
  };
  handle?: WizardHandler;
  setMeta: <TMetaValue extends keyof State['meta']>(
    key: TMetaValue,
    value: State['meta'][TMetaValue],
  ) => void;
  setHandler: (handler: WizardHandler) => void;
  /**
   * Pass url that matches the step `segment` or a name that matches the `name` in the steps
   */
  setNewStep: (step: string) => void;
  setAllowedAction: (action: Actions) => void;
  goto: (
    direction: 'NEXT' | 'PREV',
    withBasePath?: boolean,
  ) => Promise<string | null>;
};

type WizardNavigationProps = {
  steps: StepShape;
  mapToURL?: boolean;
  initialStep?: { step: number; part: number };
  children: React.ReactNode;
};

export const { Provider, useStore: useWizardStore } =
  createContext<StoreApi<State>>();

type IsTuple<T extends ReadonlyArray<any>> = number extends T['length']
  ? false
  : true;
type ArrayKey = number;

type PathImpl<K extends string | number, V> = V extends Step
  ? `${V['name']}`
  : `${Path<V>}`;

type TupleKeys<T extends ReadonlyArray<any>> = Exclude<keyof T, keyof any[]>;

declare type Path<T> = T extends ReadonlyArray<infer V>
  ? IsTuple<T> extends true
    ? {
        [K in TupleKeys<T>]-?: PathImpl<K & string, T[K]>;
      }[TupleKeys<T>]
    : PathImpl<ArrayKey, V>
  : {
      [K in keyof T]-?: PathImpl<K & string, T[K]>;
    }[keyof T];

declare const $NestedValue: unique symbol;

type NestedValue<TValue extends object = object> = {
  [$NestedValue]: never;
} & TValue;

type UnpackNestedValue<T> = T extends NestedValue<infer U>
  ? U
  : T extends Date | FileList | File | Blob
  ? T
  : T extends object
  ? {
      [K in keyof T]: UnpackNestedValue<T[K]>;
    }
  : T;

export function createWizard<TSteps extends StepShape>(args: {
  steps: TSteps;
}): {
  WizardNavigation: typeof WizardNavigation;
  Part: (props: PartProps<'div', Path<TSteps>>) => JSX.Element;
} {
  return {
    WizardNavigation: (props) => (
      <WizardNavigation {...props} steps={args.steps as StepShape} />
    ),
    Part,
  };
}

const BASE_SEGMENT = '/book-appointment';
export default function WizardNavigation({
  steps,
  children,
  initialStep = { step: 0, part: 0 },
  mapToURL = true,
}: WizardNavigationProps) {
  return (
    <Provider
      createStore={() =>
        create<State>()(
          devtools(
            (set, get) => ({
              steps,
              meta: {
                fallbackURL: BASE_SEGMENT,
                basePath: BASE_SEGMENT,
                allowedAction: [Actions.PREV, Actions.NEXT],
                currentStepName:
                  steps
                    .find((s, index) => index === initialStep.step)
                    ?.find((s, index) => index === initialStep.part)?.name ??
                  '',
                nextStepSegment: null,
                previousStepSegment: null,
                step: initialStep.step,
                part: initialStep.part,
              },
              setMeta: (key, value) => {
                set((state) => ({
                  meta: { ...state.meta, [key]: value },
                }));
              },
              setAllowedAction: (action) => {
                const isAdded = get().meta.allowedAction?.find(
                  (item) => item === action,
                );
                set((state) => ({
                  meta: {
                    ...state.meta,
                    allowedAction: isAdded
                      ? state.meta.allowedAction
                      : [...(state.meta?.allowedAction ?? []), action],
                  },
                }));
              },
              goto: async (direction, withBasePath) => {
                const { meta } = get();
                const previousURL = meta.previousStepSegment;
                const nextURL = meta.nextStepSegment;
                const { fallbackURL } = meta;

                const handler = get().handle;
                try {
                  if (handler) {
                    const doYouWannaGo = await handler();
                    if (!doYouWannaGo) {
                      return null;
                    }
                  }
                  if (direction === 'NEXT' && nextURL) {
                    return withBasePath ? BASE_SEGMENT + nextURL : nextURL;
                  }
                  if (direction === 'PREV' && previousURL) {
                    return withBasePath
                      ? BASE_SEGMENT + previousURL
                      : previousURL;
                  }
                  return fallbackURL;
                } catch (error) {
                  console.log('Wizard navigation goto() error :>> ', error);
                  return null;
                }
              },
              setHandler: (handler) => {
                if (handler) {
                  set({ handle: handler });
                }
              },
              setNewStep: (newStep) => {
                const allSteps = get().steps;

                allSteps.forEach((step, stepIndex) => {
                  step.forEach((part, partIndex) => {
                    const stepName = steps[stepIndex][partIndex].name;
                    if (
                      BASE_SEGMENT + part.segment === newStep ||
                      newStep === stepName
                    ) {
                      const { name } = steps[stepIndex][partIndex];
                      const hasNextStep = steps[stepIndex + 1] !== undefined;
                      const hasPreviousStep =
                        steps[stepIndex - 1] !== undefined;
                      const hasNextPart =
                        steps[stepIndex]?.[partIndex + 1] !== undefined;
                      const hasPreviousPart =
                        steps[stepIndex]?.[partIndex - 1] !== undefined;
                      const isLastPart = partIndex === step.length - 1;
                      const isFirstPart = partIndex === 0;
                      const computeNextStep = () =>
                        // eslint-disable-next-line no-nested-ternary
                        isFirstPart && hasNextPart
                          ? steps[stepIndex][partIndex + 1]
                          : // eslint-disable-next-line no-nested-ternary
                          isLastPart && hasNextStep
                          ? steps[stepIndex + 1][0]
                          : !isFirstPart && !isLastPart
                          ? steps[stepIndex][partIndex + 1]
                          : null;
                      const computePreviousStep = () =>
                        // eslint-disable-next-line no-nested-ternary
                        isFirstPart && hasPreviousStep
                          ? steps[stepIndex - 1][
                              steps[stepIndex - 1].length - 1
                            ]
                          : // eslint-disable-next-line no-nested-ternary
                          isLastPart && hasPreviousPart
                          ? steps[stepIndex][partIndex - 1]
                          : !isFirstPart && !isLastPart
                          ? steps[stepIndex][partIndex - 1]
                          : null;
                      set((state) => ({
                        handle: undefined,
                        meta: {
                          ...state.meta,
                          step: stepIndex,
                          part: partIndex,
                          currentStepName: name,
                          nextStepSegment: computeNextStep()?.segment ?? null,
                          previousStepSegment:
                            computePreviousStep()?.segment ?? null,
                          nextStepMeta: computeNextStep(),
                          previousStepMeta: computePreviousStep(),
                        },
                      }));
                    }
                  });
                });
              },
            }),
            {
              name: 'Wizard Store',
              enabled: process.env.NODE_ENV === 'development',
            },
          ),
        )
      }
    >
      {mapToURL ? (
        <RouteMapper>{children}</RouteMapper>
      ) : (
        <NameMapper>{children}</NameMapper>
      )}
    </Provider>
  );
}
function NameMapper({ children }: { children: React.ReactNode }) {
  const { set, meta, steps } = useWizardStore(
    (state) => ({
      set: state.setNewStep,
      meta: state.meta,
      steps: state.steps,
    }),
    shallow,
  );
  const isInitialized = useRef(false);
  useEffect(() => {
    if (!isInitialized.current) {
      set(steps[meta.step][meta.part].name);
      isInitialized.current = true;
    }
  }, [set, steps, meta]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
function RouteMapper({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const setStep = useWizardStore((state) => state.setNewStep);

  useEffect(() => {
    setStep(location.pathname.split('?')[0]);
  }, [location, setStep]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
export function Steps({ children }: { children: React.ReactNode }) {
  const activeStep = useWizardStore((state) => state.meta.step);
  const activeChild = useMemo(() => {
    const reactChildren = React.Children.toArray(children);
    return reactChildren[activeStep];
  }, [activeStep, children]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{activeChild}</>;
}

export function Parts({
  children,
}: {
  children: React.ReactElement[] | React.ReactElement;
}) {
  const currentName = useWizardStore((state) => state.meta.currentStepName);

  const activeChild = useMemo(() => {
    const reactChild = React.Children.map(children, (child) => {
      if (child.props.name === currentName) {
        return child;
      }
      return null;
    }).filter(Boolean);
    return reactChild[0];
  }, [currentName, children]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{activeChild}</>;
}

export type PartBaseProps<TTag extends React.ElementType, TStepsNames> = {
  name: TStepsNames;
  as?: TTag;
  children:
    | ((handler: {
        handler: (handler: WizardHandler) => void;
        nextStep: () => void;
        previouseStep: () => void;
      }) => React.ReactNode)
    | React.ReactNode;
};

type PartProps<T extends React.ElementType, TStepsNames> = PartBaseProps<
  T,
  TStepsNames
> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof PartBaseProps<T, TStepsNames>>;

export function Part<TStepsNames, T extends React.ElementType = any>({
  name,
  as,
  children,
  ...props
}: PartProps<T, TStepsNames>) {
  const Component = as || Fragment;
  const { handler, set, meta } = useWizardStore((state) => ({
    handler: state.setHandler,
    set: state.setNewStep,
    meta: state.meta,
  }));

  return (
    <Component {...props}>
      {typeof children === 'function'
        ? children({
            handler,
            nextStep: () => {
              if (meta.nextStepMeta?.name) {
                set(meta.nextStepMeta.name);
              }
            },
            previouseStep: () => {
              if (meta.previousStepMeta?.name) {
                set(meta.previousStepMeta.name);
              }
            },
          })
        : children}
    </Component>
  );
}
