/* eslint-disable @typescript-eslint/no-explicit-any */
import useMutationHook, { Options, Reset, Status } from 'use-mutation';

type MutationResult<Input, Data, Error> = [
  (
    input: Input,
    config?: Omit<Options<Input, Data, Error>, 'onMutate' | 'useErrorBoundary'>,
  ) => Promise<Data | undefined>,
  {
    status: Status;
    data?: Data;
    error?: Error;
    reset: Reset;
  },
];

export default function useMutation<Input = any, Data = any, Error = any>(
  mutationFn: (input: Input) => Promise<Data>,
  options?: Options<Input, Data, Error>,
): MutationResult<Input, Data, Error> {
  return useMutationHook(mutationFn, options);
}
