/* eslint-disable react/require-default-props */
import { KIND, SIZE } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { StyledCaption } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Skeleton } from 'baseui/skeleton';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { chunk } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { FormControlLabel } from 'components/FormControl';

import { Button, FormController } from 'components';

import { useBookingOptions } from 'services/queries/bookings';

import { BookingOption } from 'types/bookings';

import { useStyletron } from 'theme';

import type { ExpectedGuestFormFields, TBookingForm } from './types';

/**
 * TODO: Handle editable prop when booking is confirmed
 */

export default function ExpectedGuestsForm(
  props: TBookingForm<ExpectedGuestFormFields>,
) {
  const {
    initalValue = {},
    id: formId,
    onSubmit: submitHandler,
    editable,
  } = props;
  const [css, theme] = useStyletron();

  const bookingOptionsQuery = useBookingOptions();

  const [orderedSectors, setOrderedSectors] = useState<BookingOption[][]>([]);

  const { control, handleSubmit, watch } = useForm<ExpectedGuestFormFields>({
    defaultValues: initalValue,
  });
  const values = watch();
  const { append, remove } = useFieldArray({
    control,
    name: 'guests',
  });

  const onSubmit = handleSubmit((data) => {
    submitHandler(data);
  });

  const isInGuestArray = (option: { name: string; id: number }) => {
    if (values.guests && values.guests.length > 0) {
      return values.guests.some((guest) => guest.id === option.id);
    }

    return false;
  };

  const addGuest = (option: { name: string; id: number }) => {
    if (!values.guests.some((guest) => guest.id === option.id)) {
      append(option);
    } else {
      remove(values.guests.findIndex((guest) => guest.id === option.id));
    }
  };

  useEffect(() => {
    if (bookingOptionsQuery.bookingOptions) {
      const choicesLength = Math.round(
        bookingOptionsQuery.bookingOptions.sectors.length / 2,
      );
      const dividedSectors = chunk(
        bookingOptionsQuery.bookingOptions?.sectors,
        choicesLength,
      );
      const otherObject = dividedSectors[1].splice(
        dividedSectors[1].findIndex((sector) => sector.name === 'Other'),
        1,
      )[0];

      setOrderedSectors([
        dividedSectors[0],
        [...dividedSectors[1], otherObject],
      ]);
    }
  }, [bookingOptionsQuery.bookingOptions]);

  if (bookingOptionsQuery.error) {
    return (
      <div
        className={css({
          ...theme.borders.border200,
          display: 'flex',
          alignItems: 'center',
          padding: theme.sizing.scale400,
          justifyContent: 'space-between',
          borderRadius: theme.sizing.scale300,
        })}
      >
        <LabelMedium color={theme.colors.negative}>
          Failed to load Expected Guests Choices
        </LabelMedium>
        <Button
          type="button"
          size={SIZE.mini}
          kind={KIND.tertiary}
          onClick={() => bookingOptionsQuery.mutate()}
        >
          Try again
        </Button>
      </div>
    );
  }

  if (!bookingOptionsQuery?.bookingOptions?.sectors) {
    return (
      <Skeleton
        animation
        height={theme.sizing.scale1200}
        overrides={{
          Root: {
            style: { borderRadius: theme.sizing.scale300 },
          },
        }}
      />
    );
  }

  return (
    <form
      id={formId}
      onSubmit={onSubmit}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.sizing.scale1000,
      })}
    >
      <FormController
        control={control}
        defaultValue={[]}
        name="guests"
        rules={{
          required: 'Select at least one type',
        }}
        overrides={{
          Caption: {
            style: {
              display: 'none',
            },
          },
        }}
        label={
          <>
            <FormControlLabel required>
              Sector of Expected Guests
            </FormControlLabel>
            <LabelSmall
              as="h3"
              $style={{
                fontWeight: 400,
                color: theme.colors.primary,
                marginTop: theme.sizing.scale600,
                marginBottom: theme.sizing.scale600,
              }}
            >
              Select All that Apply
            </LabelSmall>
          </>
        }
      >
        {({ fieldState }) => (
          <>
            {fieldState.error?.message && (
              <StyledCaption
                $error
                $style={{
                  marginTop: 0,
                }}
              >
                {fieldState.error?.message}
              </StyledCaption>
            )}
            <FlexGrid
              flexGridColumnCount={[1, 1, 2]}
              flexGridRowGap={theme.sizing.scale300}
              flexGridColumnGap={theme.sizing.scale600}
            >
              {orderedSectors.map((sector, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <FlexGridItem key={index + 1}>
                  {sector.map((option) => (
                    <Checkbox
                      key={option.id}
                      overrides={{
                        Root: {
                          style: {
                            marginTop: theme.sizing.scale600,
                          },
                        },
                        Label: {
                          style: {
                            fontWeight: '400',
                          },
                        },
                      }}
                      checked={isInGuestArray(option)}
                      onChange={() => addGuest(option)}
                      disabled={!editable}
                    >
                      {option.name}
                    </Checkbox>
                  ))}
                </FlexGridItem>
              ))}
            </FlexGrid>
          </>
        )}
      </FormController>
      {!!values.guests?.some((guest) => guest.name === 'Other') && (
        <FormController
          control={control}
          name="otherSector"
          defaultValue=""
          label="Sector Name"
          rules={{
            required: 'This field is required',
          }}
          disabled={!editable}
        >
          {({ field }) => <Input {...field} id={field.name} />}
        </FormController>
      )}
    </form>
  );
}
