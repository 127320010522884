import { KIND, SIZE, Tag, VARIANT } from 'baseui/tag';
import { bookingStatus } from 'constants/select-options';
import React from 'react';

import { BookingStatus } from 'types/bookings';

export default function StatusBadge({ status }: { status: BookingStatus }) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [, statusValue] = Object.entries(BookingStatus).find((entry) => {
    const [, value] = entry;
    return value === status;
  })!;

  const kind: Record<BookingStatus, keyof typeof KIND> = {
    Confirmed: KIND.positive,
    Cancelled: KIND.negative,
    Done: KIND.positive,
    Draft: KIND.yellow,
    'For Confirmation': KIND.yellow,
    'No Rep': KIND.red,
    Rep: KIND.red,
  };

  return (
    <Tag
      closeable={false}
      kind={kind[statusValue]}
      variant={VARIANT.solid}
      size={SIZE.small}
      overrides={{
        Root: {
          style: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            lineHeight: 1,
          },
        },
        Text: {
          style: {
            fontSize: '12px',
            lineHeight: '12px',
            maxWidth: 'none',
          },
        },
      }}
    >
      {bookingStatus[statusValue].toUpperCase()}
    </Tag>
  );
}
