import { Notifications } from '@mui/icons-material';
import { KIND, SIZE } from 'baseui/button';
import { Delete } from 'baseui/icon';
import { ListItem } from 'baseui/list';
import { StatefulPopover } from 'baseui/popover';
import { PLACEMENT } from 'baseui/snackbar';
import React from 'react';
import { Link } from 'react-router-dom';

import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';

import ProfileDropdown from 'features/UserProfile/ProfileDropdown';

import { useStyletron } from 'theme';

import { getTokens } from 'utils';

import LogoHorizontal from 'assets/images/scheduler-ovp-logo.png';

export default function Header() {
  const [css, theme] = useStyletron();
  const isAdmin = getTokens()?.isAdmin;

  return (
    <header
      className={css({
        padding: theme.sizing.scale500,
        boxShadow: theme.lighting.shadow500,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomWidth: '1px',
        borderBottomColor: theme.colorTokens.gray100,
      })}
    >
      <Box row css={{ alignItems: 'center' }} spaceX="scale600">
        <Link
          to={isAdmin ? '/appointments' : '/bookings'}
          className={css({ height: theme.sizing.scale950, flexShrink: 0 })}
        >
          <img
            src={LogoHorizontal}
            alt="OVP Seal"
            className={css({ height: '100%' })}
          />
        </Link>
      </Box>
      <Box
        as="nav"
        css={{
          justifyContent: 'space-between',
          layout: 'row',
          spaceX: '$scale600',
        }}
      >
        <ProfileDropdown />
        {/* <NotificationDropdown /> */}
      </Box>
    </header>
  );
}

function NotificationDropdown() {
  const [css, theme] = useStyletron();
  return (
    <StatefulPopover
      placement={PLACEMENT.bottomRight}
      // eslint-disable-next-line react/no-unstable-nested-components
      content={({ close }) => (
        <div
          className={css({
            width: '23rem',
            backgroundColor: theme.colors.white,
            borderRadius: theme.sizing.scale400,
          })}
        >
          <header
            className={css({
              display: 'flex',
              paddingLeft: theme.sizing.scale700,
              borderBottom: `1px solid ${theme.colors.contentInverseTertiary}`,
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <Text variant="HeadingXSmall">Notifications</Text>
            <Button onClick={close} size={SIZE.compact} kind={KIND.tertiary}>
              <Delete size={theme.sizing.scale800} />
            </Button>
          </header>
          <ul
            className={css({
              padding: theme.sizing.scale600,
              maxHeight: '40rem',
              overflowY: 'auto',
            })}
          >
            <ListItem>
              <Text>New message in “Meeting with Mr. Tan”</Text>
            </ListItem>
          </ul>
        </div>
      )}
      returnFocus
      autoFocus
    >
      <Button kind={KIND.tertiary} size={SIZE.mini}>
        <Notifications />
      </Button>
    </StatefulPopover>
  );
}
