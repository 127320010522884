/* eslint-disable react/require-default-props */
import { Input, MaskedInput } from 'baseui/input';
import { Textarea } from 'baseui/textarea';
import { HeadingXSmall, LabelMedium } from 'baseui/typography';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Skeleton } from 'baseui/skeleton';

import { FormControlLabel } from 'components/FormControl';
import DressCodeSelect from 'components/Select/DressCodeSelect';
import EventRoleSelect from 'components/Select/EventRoleSelect';
import DropDownSelect  from 'components/Select/DropDownSelect';
import SpaceBetween from 'components/SpaceBetween';

import { FormController } from 'components';

import { useStyletron } from 'theme';

import { useBookingFormStore } from './store';
import { EventInformationFormFields, TBookingForm } from './types';
import { fetchData } from './apiOption';

export interface LocationOption {
  label: string
  value: string
  code?: string
  id: number
}

const DRESS_CODE_MEETING_TYPES = ['Invitation to Events', 'In-person meeting'];

/**
 * TODO: Handle Message Request meeting type
 */
export default function EventInformationForm(
  props: TBookingForm<EventInformationFormFields>,
) {
  const {
    id,
    onSubmit: onSubmitHandler,
    initalValue,
    onChange: onChangeHandler,
    editable,
  } = props;
  const { control, handleSubmit, watch, setValue } = useForm<EventInformationFormFields>({
    defaultValues: initalValue,
  });
  const [css, theme] = useStyletron();
  const onChangeHandlerRef = useRef(onChangeHandler);

  const onSubmit = handleSubmit((data) => {
    onSubmitHandler(data);
  });
  const meetingType = useBookingFormStore((state) => state.type);
  const needsDressCode = DRESS_CODE_MEETING_TYPES.some(
    (item) => item.toLowerCase() === meetingType?.label.toLowerCase() ?? '',
  );

  const isVirtualEvent = meetingType?.label === 'Virtual Meeting';
  const isVideoMessage = meetingType?.label === 'Request for Message Only';
  const formState = useBookingFormStore()
  const [countrySelected, setCountrySelected] = useState(formState.country)
  const isCountryPh = String(countrySelected) === '176'
  const isRegion = formState.region === undefined || formState.region === null
  const isProvince = formState.province === undefined || formState.province === null
  const [isFetchingCountry, setIsFetchingCountry] = useState(false)
  const [isFetchingRegion, setIsFetchingRegion] = useState(false)
  const [isFetchingProvince, setIsFetchingProvince] = useState(false)
  const [isFetchingCity, setIsFetchingCity] = useState(false)
  const [indexKey, setIndexKey] = useState({
    region: 0,
    province: 0,
    city: 0,
    barangay: 0
  })
  useEffect(() => {
    onChangeHandlerRef.current = onChangeHandler;
  }, [onChangeHandler]);

  // TODO: Monitor this if handler closure state is always fresh and stable
  useEffect(() => {
    const subscription = watch((value) => {
      if (onChangeHandlerRef.current) {
        onChangeHandlerRef.current(value as EventInformationFormFields);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!isCountryPh) {
      setValue('region', undefined)
      setValue('province', undefined)
      setValue('city', undefined)
      setIndexKey((prevIndexKey) => ({
        ...prevIndexKey,
        province: prevIndexKey.province + 1,
        region: prevIndexKey.region + 1,
        city: prevIndexKey.city + 1,
        barangay: prevIndexKey.barangay + 1
      }));
    }
  }, [countrySelected]);

  const [countryOptions, setCountryOptions] = useState([
    // { label: 'Philippines', value: 'philippines'}
  ])
  const [regionOptions, setRegionOptions] = useState([
    { label: 'Region 1', value: 'region_1', id: '1'}
  ])
  const [provinceOptions, setProvinceOptions] = useState([
    { label: 'Province', value: 'province', id: '1'}
    
  ])
  const [cityOptions, setCityOptions] = useState([
    { label: 'City', value: 'city', id: '1'}
  ])

  const updateProvinceOption = async (regionId : string) => {
    if (formState.region !== regionId) {
      setValue('province', undefined)
      setValue('city', undefined)
    }
    try {
      setIsFetchingProvince(true)
      const provinces = await fetchData(`province/${regionId}`, 'province')
      setProvinceOptions(provinces.map((province: LocationOption) => ({
        label: province.label,
        value: province.id.toString(),
      })))
    } catch (error) {
      console.log("Error fetching provinces:", error)
    } finally {
      setIsFetchingProvince(false)
    }
    

  }
  const updateCityOptions = async (provinceId: string) => {
  if (formState.province !== provinceId) {
    setValue('city', undefined)
  }
   try {
    setIsFetchingCity(true)
    const cities = await fetchData(`city-municipality/${provinceId}`, 'city')
    setCityOptions(cities.map((city: LocationOption) => ({
      label: city.label,
      value: city.id.toString(),
    })))
   } catch (error) {
    console.log("Error fetching cities:", error)
   } finally {
    setIsFetchingCity(false)
   }
   
  
  }
  const updateRegionOptions = async () => {
   try {
    setIsFetchingRegion(true)
    const regions = await fetchData('region', 'region')
    setRegionOptions(regions.map((region: LocationOption) => ({
      label: region.label,
      value: region.id.toString(),
    })))
   } catch (error) {
    console.log("Error fetching regions:", error)
   } finally {
    setIsFetchingRegion(false)
   }
    
    
  }
  const updateCountriesOptions = async () => {
    try {
      setIsFetchingCountry(true)
      const countries = await fetchData('country', 'country')
      const phIndex = countries.findIndex((country: LocationOption) => country.code === 'PH')
      if (phIndex > -1) {
        const phValue = countries.splice(phIndex, 1)[0]
        countries.unshift(phValue)
      }
      setCountryOptions(countries.map((country: LocationOption) => ({
        label: country.label,
        value: country.id.toString(),
      })))
    } catch (error) {
      console.log("Error fetching countries:", error)
    } finally {
      setIsFetchingCountry(false)
    }
   
  }
  useEffect(()=>{
    updateCountriesOptions()
    updateRegionOptions()
    if (formState.region) {
      updateProvinceOption(formState.region)
    }
    if (formState.province) {
      updateCityOptions(formState.province)
    }
  }, []);

  return (
    <form
      id={id}
      onSubmit={onSubmit}
      className={css({
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <HeadingXSmall
        as="h3"
        $style={{
          fontWeight: 600,
        }}
      >
        Event Information
      </HeadingXSmall>

      <FormController
        control={control}
        name="role"
        rules={{
          required: 'This field is required',
        }}
        label={
          <FormControlLabel required>
            {isVideoMessage ? 'Type Of Message' : 'Role'}
          </FormControlLabel>
        }
        disabled={!editable}
      >
        {({ field }) => (
          <EventRoleSelect
            onChange={({ option }) => {
              field.onChange(option);
            }}
            value={field.value?.value}
            meetingTypeId={meetingType?.value}
            placeholder="Select the Vice President's role for the event."
          />
        )}
      </FormController>
      <ConditionalField show={needsDressCode}>
        <FormController
          control={control}
          name="dressCode"
          rules={{
            required: 'This field is required',
          }}
          label={<FormControlLabel required>Dress Code</FormControlLabel>}
          disabled={!editable}
        >
          {({ field }) => (
            <DressCodeSelect
              onChange={(params) => {
                field.onChange(params.option);
              }}
              value={field.value?.value}
              meetingTypeId={meetingType?.value}
            />
          )}
        </FormController>
      </ConditionalField>
      <ConditionalField show={isVirtualEvent}>
        <FormController
          control={control}
          name="meetingLink"
          label={<FormControlLabel required>Meeting Link</FormControlLabel>}
          rules={{
            required: 'This field is required',
            pattern: {
              value: /https?:\/\/.*/g,
              message: 'Enter a valid url',
            },
          }}
          defaultValue=""
          disabled={!editable}
        >
          {({ field }) => (
            <Input
              {...field}
              placeholder="e.g. Google Meet, Zoom, Other Video Conferencing platform full link"
            />
          )}
        </FormController>
      </ConditionalField>
      <ConditionalField show={isVideoMessage}>
        <FormController
          control={control}
          name="platformPosted"
          defaultValue=""
          label={
            <FormControlLabel
              required
              description="This is where the message will be posted"
            >
              Platform
            </FormControlLabel>
          }
          rules={{ required: 'This field is required' }}
          disabled={!editable}
        >
          {({ field }) => <Input {...field} />}
        </FormController>
      </ConditionalField>
      <ConditionalField show={!isVirtualEvent && !isVideoMessage}>
        <fieldset>
          <LabelMedium as="legend">Event Location</LabelMedium>
          <FormController
            control={control}
            name="location.name"
            rules={{
              required: 'This field is required',
              maxLength: {
                value: 150,
                message: 'Location must not exceed 150 characters',
              },
            }}
            label={
              <FormControlLabel
                required
                description="The venue where the event/meeting will take place."
              >
                Name
              </FormControlLabel>
            }
            counter={{
              maxLength: 100,
            }}
            disabled={!editable}
          >
            {({ field: { value, ref, ...field }, fieldState }) => (
              <Textarea
                {...field}
                inputRef={ref}
                id={field.name}
                value={value ?? ''}
                rows={2}
                error={!!fieldState.error?.message}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="location.address"
            rules={{
              required: 'This field is required',
              maxLength: {
                value: 150,
                message: 'Location must not exceed 150 characters',
              },
            }}
            label={
              <FormControlLabel
                required
                description="The exact location where the event/meeting will take place."
              >
                Complete Address
              </FormControlLabel>
            }
            counter={{
              maxLength: 150,
            }}
            disabled={!editable}
          >
            {({ field: { value, ref, ...field }, fieldState }) => (
              <Textarea
                {...field}
                inputRef={ref}
                id={field.name}
                value={value ?? ''}
                rows={3}
                error={!!fieldState.error?.message}
              />
            )}
          </FormController>
        </fieldset>
      </ConditionalField>
      <FormController
        control={control}
        name="particiapantsCount"
        defaultValue=""
        rules={{
          required: 'This field is required',
          pattern: {
            value: /^[0-9]+$/,
            message: 'This field only accepts numbers',
          },
        }}
        label={
          <FormControlLabel required>Number of Attendees</FormControlLabel>
        }
        disabled={!editable}
      >
        {({ field: { ref, ...field } }) => (
          <MaskedInput {...field} inputRef={ref} id={field.name} />
        )}
      </FormController>
 
      <FormController control={control} name="country" label={
          <FormControlLabel required>Country</FormControlLabel>
        } rules={{
          required: 'This field is required',
     
        }}>
         
   
  {({ field }) => (
    
    <DropDownSelect
      isLoading={isFetchingCountry}
      value={String(formState.country)}
      onChange={(selectedValue: any) => {
        field.onChange(selectedValue.option.value);
        setCountrySelected(selectedValue.option.value)
        setValue('country', selectedValue.option.value)
      }}
      options={countryOptions}
    />
  )}
   
      </FormController>
      <ConditionalField show={isCountryPh}>
      <SpaceBetween
        flexGridColumnCount={[1, 1, 2]}
        x={theme.sizing.scale600}
        isEqual
      >  
      <FormController key={indexKey.region} control={control} name="region" label={
        <FormControlLabel required>Region</FormControlLabel>
      } rules={{
        required: 'This field is required',
      }}>
      {({ field }) => (
      <DropDownSelect
      isLoading={isFetchingRegion}
      value={String(formState.region)}
      onChange={(selectedValue: any) => {
      field.onChange(selectedValue.option.value);
      updateProvinceOption(selectedValue.option.value)
      setValue('region', selectedValue.option.value)
      }}
      options={regionOptions}
      />
      )}
      </FormController>
      <FormController key={indexKey.province} control={control} name='province' label={
        <FormControlLabel required>Province </FormControlLabel>
      } rules={{
        required: 'This field is required',

      }}
      disabled={isRegion}>
      {({ field }) => (
      <DropDownSelect
      isLoading={isFetchingProvince}
      value={String(formState.province)}
      onChange={(selectedValue: any) => {
      field.onChange(selectedValue.option.value);
      updateCityOptions(selectedValue.option.value)
      setValue('province', selectedValue.option.value)
      }}
      options={provinceOptions}
      />
      
      )}
      </FormController>
      <FormController key={indexKey.city} control={control} name="city" label={
        <FormControlLabel required>City</FormControlLabel>
      } rules={{
        required: 'This field is required',

      }}
      disabled={isProvince}
      >

      {({ field }) => (
      <DropDownSelect
      isLoading={isFetchingCity}
      value={String(formState.city)}
      onChange={(selectedValue: any) => {
      field.onChange(selectedValue.option.value);
      setValue('city', selectedValue.option.value)
      }}
      options={cityOptions}
      />
      )}
      </FormController>
      </SpaceBetween>
      </ConditionalField>
    </form>
  );
}

function ConditionalField({
  children,
  show = true,
}: {
  show?: boolean;
  children: React.ReactNode;
}) {
  if (!show) {
    return null;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
