import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/Header';

import { useStyletron } from 'theme';

export default function MobileDashboardLayout() {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Header />
      <div className={css({ padding: theme.sizing.scale600 })}>
        <Outlet />
      </div>
    </div>
  );
}
