import { Options } from 'use-mutation';

import api, { sendForm } from 'services/api';

import { BookingStatus } from 'types/bookings';

import useMutation from 'utils/hooks/use-mutation';

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>;

type BookingPayload = {
  name: string;
  description: string;
  location?: string;
  location_name?: string;
  location_address?: string;
  meeting_link?: string;
  start_datetime: string;
  stop_datetime: string;
  appointment_type_id: number;
  password: string;
  attachments: File[];
  sector_ids: number[];
  audience_ids: number[];
  other_audience?: string;
  role_id: number;
  dress_code_id?: number;
  other_role?: string;
  other_sector?: string;
  no_of_participants: number;
  country_id: string | undefined;
  region_id: string | undefined;
  province_id: string | undefined;
  city_id: string | undefined;
};

type EditBookingPayload = Omit<
  MakeOptional<BookingPayload, 'attachments'>,
  'password'
> & {
  token: string;
};

type BookingAttachmentPayload = {
  token: string;
  files: File[];
};

export function createBooking(payload: BookingPayload) {
  const formData = new FormData();

  Object.entries(payload)
    .filter((entry) => entry[0] !== 'attachments')
    .filter((entry) => !!entry[1])
    .map((entry) => {
      const [key, value] = entry;
      formData.set(
        key,
        typeof value === 'string' ? value : JSON.stringify(value),
      );
      return null;
    });
  payload.attachments?.map((file, index) => {
    formData.append(`attachment${index + 1}`, file);
    return null;
  });

  return sendForm('/bookings', 'POST', formData);
}

export function editBooking(payload: EditBookingPayload) {
  const { token, ...body } = payload;
  const formData = new FormData();

  Object.entries(body)
    .filter((entry) => entry[0] !== 'attachments')
    .filter((entry) => !!entry[1])
    .map((entry) => {
      const [key, value] = entry;
      formData.set(
        key,
        typeof value === 'string' ? value : JSON.stringify(value),
      );
      return null;
    });
  payload.attachments?.map((file, index) => {
    formData.append(`attachment${index + 1}`, file);
    return null;
  });

  return sendForm(`/bookings/${token}`, 'PATCH', formData);
}

export function addBookingAttachment(payload: BookingAttachmentPayload) {
  const formData = new FormData();
  payload.files.map((attachment, index) =>
    formData.append(`attachment${index + 1}`, attachment),
  );
  return sendForm(`/bookings/${payload.token}/attachments`, 'POST', formData);
}

export function deleteBooking(token: string) {
  return api.del(`/bookings/${token}`);
}

export function useNewBooking(options?: Options<BookingPayload, any, any>) {
  return useMutation(createBooking, options);
}

export function useEditBooking(
  options?: Options<EditBookingPayload, any, any>,
) {
  return useMutation(editBooking, options);
}

export function useDeleteBooking(options?: Options<string, any, any>) {
  return useMutation(deleteBooking, options);
}

export function useAddBookingAttachment(
  options?: Options<BookingAttachmentPayload, any, any>,
) {
  return useMutation(addBookingAttachment, options);
}

type ConfirmBookingPayload = {
  token: string | string[];
};

function confirmBooking({ token }: ConfirmBookingPayload) {
  if (token instanceof Array) {
    return Promise.allSettled([
      ...token.map((t) => api.patch(`/bookings/${t}/confirm-booking`, {})),
      // eslint-disable-next-line no-promise-executor-return
      new Promise((r) => setTimeout(r, 800)),
    ]);
  }

  return Promise.allSettled([
    api.patch(`/bookings/${token}/confirm-booking`, {}),
    // eslint-disable-next-line no-promise-executor-return
    new Promise((r) => setTimeout(r, 800)),
  ]);
}

export function useConfirmBooking(
  options?: Options<
    ConfirmBookingPayload,
    [...PromiseSettledResult<unknown>[], PromiseSettledResult<unknown>],
    any
  >,
) {
  return useMutation<
    ConfirmBookingPayload,
    [...PromiseSettledResult<unknown>[], PromiseSettledResult<unknown>],
    any
  >(confirmBooking, options);
}

type RegretBooking =
  | {
      token: string;
      type: BookingStatus.NO_REP | BookingStatus.REP;
    }
  | {
      token: {
        token: string;
        type: BookingStatus.NO_REP | BookingStatus.REP;
      }[];
      type?: never;
    };

function regretBooking({ token, type }: RegretBooking) {
  const resolvedEndpoint: Record<
    BookingStatus.NO_REP | BookingStatus.REP,
    string
  > = {
    'No Rep': 'regret-without-rep-booking',
    Rep: 'regret-with-rep-booking',
  };

  if (Array.isArray(token)) {
    return Promise.allSettled([
      ...token.map((t) =>
        api.patch(`/bookings/${t.token}/${resolvedEndpoint[t.type]}`, {}),
      ),
      // eslint-disable-next-line no-promise-executor-return
      new Promise((r) => setTimeout(r, 800)),
    ]);
  }

  return Promise.allSettled([
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    api.patch(`/bookings/${token}/${resolvedEndpoint[type!]}`, {}),
    // eslint-disable-next-line no-promise-executor-return
    new Promise((r) => setTimeout(r, 800)),
  ]);
}

export function useRegretBooking(
  options?: Options<
    RegretBooking,
    [...PromiseSettledResult<unknown>[], PromiseSettledResult<unknown>],
    any
  >,
) {
  return useMutation<
    RegretBooking,
    [...PromiseSettledResult<unknown>[], PromiseSettledResult<unknown>],
    any
  >(regretBooking, options);
}

type AddNotePayload = {
  content: string;
  token: string;
};

function addBookingNote(payload: AddNotePayload) {
  return Promise.allSettled([
    api.post(`/bookings/${payload.token}/messages`, {
      message: payload.content,
      is_note: true,
    }),
    // eslint-disable-next-line no-promise-executor-return
    new Promise((r) => setTimeout(r, 800)),
  ]);
}

export function useNewBookingNote(
  options?: Options<
    AddNotePayload,
    [...PromiseSettledResult<unknown>[], PromiseSettledResult<unknown>],
    any
  >,
) {
  return useMutation<
    AddNotePayload,
    [...PromiseSettledResult<unknown>[], PromiseSettledResult<unknown>],
    any
  >(addBookingNote, options);
}
