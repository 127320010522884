/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/Header';

import { useStyletron } from 'theme';

import BookingSidebar from './features/BookingSidebar';
import BookingsCalendar from './features/BookingsCalendar';
import Messages from './features/Messages';

export default function BookingDashboard() {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      {/* Top Navigation */}
      <Header />
      <div
        className={css({
          overflow: 'hidden',
          flex: 1,
          display: 'flex',
        })}
      >
        <BookingSidebar />
        <main
          className={css({
            flex: 1,
            padding: theme.sizing.scale1200,
            overflowY: 'auto',
            height: '100%',
          })}
        >
          <Outlet />
        </main>
        <aside
          className={css({
            width: '23rem',
            zIndex: 0,
            display: 'flex',
            overflowY: 'auto',
            height: '100%',
            flexShrink: 0,
            flexDirection: 'column',
            position: 'relative',
          })}
        >
          <div className={css({})}>
            <BookingsCalendar />
          </div>
          <div
            className={css({
              // padding: theme.sizing.scale400,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            })}
          >
            <Messages />
          </div>
        </aside>
      </div>
    </div>
  );
}
