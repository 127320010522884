import { StepShape } from 'components/WizardNavigation';

export const BOOKING_FORM_STEPS: StepShape = [
  [
    {
      name: 'basic-information',
      segment: '/',
    },
  ],
  [
    {
      name: 'event-information',
      segment: '/',
    },
  ],
  [
    {
      name: 'event-guests',
      segment: '/',
    },
  ],
  [
    {
      name: 'event-audience',
      segment: '/',
    },
  ],
  [
    {
      name: 'review-booking-information',
      segment: '/',
    },
  ],
];
