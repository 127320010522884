/* eslint-disable react/require-default-props */
import { KIND } from 'baseui/banner';
import { Button, SHAPE } from 'baseui/button';
import { Alert, Check, Delete } from 'baseui/icon';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  SIZE,
} from 'baseui/modal';
import { Spinner } from 'baseui/spinner';
import {
  HeadingSmall,
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mutate as swrMutate } from 'swr';

import Banner from 'components/Banner';
import ErrorMessage from 'components/ErrorMessage';

// import BookingForm from 'features/BookingForm';
import BookingFormLayout from 'features/BookingForm/BookingFormLayout';
import { useBookingFormStore } from 'features/BookingForm/store';

import { useNewBooking } from 'services/mutations/bookings';

import { useStyletron } from 'theme';

import { getErrorMessage } from 'utils/errors';

import CreateBooking from './CreateBooking';

export default function BookAppointment() {
  const navigate = useNavigate();
  const [css, theme] = useStyletron();
  const [createBooking, createBookingMutation] = useNewBooking();
  const mutations = [createBookingMutation];

  const isSubmitting = mutations.some((m) => m.status === 'running');
  const isError = mutations.some((m) => m.status === 'failure');
  const mutationError = getErrorMessage(createBookingMutation.error);
  const validationErrors =
    mutationError.code === 400
      ? mutationError.errors
          ?.map((error) => error.split(':')?.[1])
          .filter(Boolean)
      : [];

  const resetFormStore = useBookingFormStore((state) => state.resetValues);

  return (
    <BookingFormLayout>
      <BookingReminders />
      <Banner
        title="Failed to create booking"
        show={isError}
        artwork={{
          // eslint-disable-next-line react/no-unstable-nested-components
          icon() {
            return <Alert />;
          },
        }}
        kind={KIND.negative}
      >
        <ErrorMessage
          error={mutationError}
          error401={(error) => error}
          error400={(errors) =>
            errors.map((error) => (
              <div
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: theme.sizing.scale200,
                })}
              >
                <Delete />
                <p className={css({ marginLeft: theme.sizing.scale100 })}>
                  {error}
                </p>
              </div>
            ))
          }
        />
      </Banner>
      <CreateBooking
        onSubmit={(formValues) => {
          if (isSubmitting) return;
          const isRequestForMessage =
            formValues.type.label === 'Request for Message Only';

          createBooking(
            {
              name: formValues.agenda,
              start_datetime: `${format(
                formValues.date,
                'yyyy-MM-dd',
              )} ${format(formValues.timeslot.start, 'HH:mm:ss')}`,
              stop_datetime: `${format(formValues.date, 'yyyy-MM-dd')} ${format(
                formValues.timeslot.end,
                'HH:mm:ss',
              )}`,
              appointment_type_id: formValues.type.value,
              location: formValues?.platformPosted,
              password: formValues.password,
              description: formValues.description,
              attachments: formValues.files,
              sector_ids: formValues.guests.map((i) => i.id).filter(Boolean),
              other_sector: formValues.otherSector ?? '',
              audience_ids: formValues.audienceTypes
                .map((v) => v.id)
                .filter(Boolean),
              other_audience: formValues.otherAudienceType ?? '',
              /**
               * Use location_name for platform posted value
               * just used nullish coealscaling to address use case where location.name
               * is supplied but not the platform for certain event types
               */
              location_name: isRequestForMessage
                ? formValues?.platformPosted
                : formValues?.location?.name,
              location_address: formValues?.location?.address,
              role_id: formValues.role.value,
              other_role: '',
              meeting_link: formValues.meetingLink,
              dress_code_id: formValues?.dressCode?.value,
              no_of_participants: Number(formValues.particiapantsCount),
              country_id: formValues?.country,
              region_id: formValues?.region,
              province_id: formValues?.province,
              city_id: formValues?.city
            },
            {
              onSuccess: async () => {
                resetFormStore();
                swrMutate(`/bookings`, undefined, { revalidate: true });
                navigate(`success`, {
                  replace: true,
                });
              },
            },
          );
        }}
      />
      <Modal closeable={false} isOpen={isSubmitting} size={SIZE.auto}>
        <ModalBody
          $style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingInline: theme.sizing.scale1000,
          }}
        >
          <Spinner />
          <HeadingXSmall
            $style={{
              fontWeight: 600,
            }}
            color={theme.colors.primary}
          >
            Creating Booking
          </HeadingXSmall>
        </ModalBody>
      </Modal>
    </BookingFormLayout>
  );
}

function BookingReminders() {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 250);
  }, []);

  return (
    <Modal closeable={false} isOpen={isOpen} animate>
      <ModalHeader>
        <HeadingSmall>Welcome to Scheduler</HeadingSmall>
      </ModalHeader>
      <ModalBody>
        <LabelLarge>Friendly reminders for all</LabelLarge>
        <ParagraphSmall>
          Please read the reminders before proceeding to create your booking.
        </ParagraphSmall>
        <ul className={css({ marginTop: theme.sizing.scale800 })}>
          {[
            'Remember the email address and password you used to register your account in the Scheduler.',
            'You may check the status of your request through your account. There is no need to follow-up or update your request after booking.',
            'You will also receive an email and an automated text message of the status of your request.',
            'You may message through the Scheduler Messaging System. The system shall also be used to respond and inform you relative to your requests.',
          ].map((item, index) => (
            <li
              key={item}
              className={css({
                display: 'flex',
                alignItems: 'flex-start',
                marginTop: index !== 0 ? theme.sizing.scale600 : 0,
              })}
            >
              <span
                className={css({
                  flexShrink: 0,
                  width: theme.sizing.scale800,
                  height: theme.sizing.scale800,
                  backgroundColor: theme.colors.primary50,
                  borderRadius: '99999px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                })}
              >
                <Check size={16} />
              </span>
              <LabelMedium marginLeft={theme.sizing.scale600}>
                {item}
              </LabelMedium>
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => setIsOpen(false)}
          shape={SHAPE.pill}
          overrides={{
            BaseButton: {
              component: Button,
            },
          }}
        >
          Yes, I understand.
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
