import SendIcon from '@mui/icons-material/Send';
import { Avatar } from 'baseui/avatar';
import { KIND, SIZE } from 'baseui/button';
import { Check, DeleteAlt } from 'baseui/icon';
import { Input } from 'baseui/input';
import { PLACEMENT, SnackbarProvider, useSnackbar } from 'baseui/snackbar';
import {
  HeadingSmall,
  HeadingXSmall,
  LabelSmall,
  LabelXSmall,
  ParagraphSmall,
  ParagraphXSmall,
} from 'baseui/typography';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { mutate } from 'swr';

import { Button, FormControl } from 'components';

import { useSendMessage } from 'services/mutations/messages';
import { useBooking } from 'services/queries/bookings';
import { useMessages } from 'services/queries/messages';
import useCurrentUser from 'services/queries/users';

import { Message } from 'types/messages';

import { deserializeMessage } from 'deserializers/messages';

import { useStyletron } from 'theme';

import { getTokens } from 'utils';

const websocketURL = process.env.REACT_APP_WS_URL;

export default function Messages() {
  const [css, theme] = useStyletron();
  const params = useParams();
  const bookingId = params.bookingId ?? '';

  const { booking } = useBooking(bookingId, {
    isPaused: () => bookingId.length === 0,
  });

  return (
    <SnackbarProvider placement={PLACEMENT.topRight}>
      <div
        className={css({
          paddingTop: theme.sizing.scale600,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          ...theme.borders.border200,
        })}
      >
        <header
          className={css({
            paddingTop: theme.sizing.scale300,
            paddingBottom: theme.sizing.scale300,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
            borderBottom: `1px solid ${theme.colors.borderOpaque}`,
            boxShadow: `rgb(0 0 0 / 3%) 0 10px 18px 0px`,
          })}
        >
          <HeadingXSmall>Messages</HeadingXSmall>
          <div>
            <span className={css({ ...theme.typography.ParagraphXSmall })}>
              Showing messages for:
            </span>
            <LabelXSmall>{booking?.agenda}</LabelXSmall>
          </div>
        </header>

        <MessageList />
        <footer
          className={css({
            padding: theme.sizing.scale600,
            display: 'flex',
          })}
        >
          <MessageField />
        </footer>
      </div>
    </SnackbarProvider>
  );
}

type MessageFormFields = {
  message: string;
};
function MessageField() {
  const params = useParams();
  const [css, theme] = useStyletron();
  const [sendMessage, sendMessageMutation] = useSendMessage();
  const { enqueue } = useSnackbar();
  const { control, handleSubmit, reset } = useForm<MessageFormFields>({
    defaultValues: {
      message: '',
    },
  });
  const onSubmit = handleSubmit((data) => {
    //
    sendMessage(
      {
        message: data.message,
        id: params.bookingId ?? '',
      },
      {
        onSuccess: async () => {
          reset({
            message: '',
          });
          enqueue({
            message: 'Message Sent!',
            // eslint-disable-next-line react/no-unstable-nested-components
            startEnhancer: ({ size }) => (
              <Check size={size} color={theme.colors.positive} />
            ),
          });
          await mutate(`/bookings/${params.bookingId}/messages`);
        },
        onFailure: () => {
          enqueue({
            message: 'Failed to send message, try again.',
            overrides: {
              Message: {
                style: {
                  color: theme.colors.negative,
                },
              },
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            startEnhancer: ({ size }) => (
              <DeleteAlt size={size} color={theme.colors.negative} />
            ),
          });
        },
      },
    );
  });

  return (
    <form action="" className={css({ width: '100%' })} onSubmit={onSubmit}>
      <FormControl
        error={
          sendMessageMutation.status === 'failure'
            ? 'Failed to send message, try again'
            : null
        }
      >
        <Controller
          control={control}
          name="message"
          render={({ field }) => (
            <Input
              placeholder="Enter Message"
              clearOnEscape
              disabled={sendMessageMutation.status === 'running'}
              {...field}
              overrides={{
                Root: {
                  style: {
                    paddingRight: 0,
                    backgroundColor: theme.colors.white,
                    borderRadius: theme.sizing.scale400,
                  },
                },
                Input: {
                  style: {
                    backgroundColor: theme.colors.white,
                  },
                },
                EndEnhancer: {
                  style: {
                    backgroundColor: theme.colors.white,
                  },
                },
              }}
              // eslint-disable-next-line react/no-unstable-nested-components
              endEnhancer={() => (
                <Button
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  isLoading={sendMessageMutation.status === 'running'}
                >
                  <SendIcon htmlColor={theme.colors.primary} />
                </Button>
              )}
            />
          )}
        />
      </FormControl>
    </form>
  );
}

function MessageList() {
  const [css, theme] = useStyletron();
  const params = useParams();
  const listRef = useRef<HTMLUListElement>(null);
  const messageQuery = useMessages(params.bookingId ?? '');

  const { user } = useCurrentUser();
  const tokens = getTokens();
  const bookingId = params.bookingId ?? '';

  const [messages, setMessages] = useState<Message[]>([]);
  const [socketUrl] = useState(`${websocketURL}?token=${tokens?.accessToken}`);
  const { sendMessage, lastMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    if (!messageQuery.isValidating && listRef.current) {
      listRef.current.scrollTo(0, listRef.current.scrollHeight);
    }
  }, [messageQuery]);

  useEffect(() => {
    if (!messageQuery.isLoading) {
      setMessages(messageQuery.messages || []);
    }
  }, [messageQuery.isLoading, messageQuery.messages]);

  useEffect(() => {
    const setIds = {
      type: 'cmd.set_user_event_info',
      user_id: String(user?.id),
      event_token: bookingId,
    };

    sendMessage(JSON.stringify(setIds));
  }, [bookingId, sendMessage, user?.id]);

  useEffect(() => {
    if (lastMessage) {
      const messageRes = JSON.parse(lastMessage.data);
      if (
        lastMessage !== null &&
        messageRes.type === 'event.message' &&
        messageRes.message.event_token === bookingId
      ) {
        const inMessages = messages.some(
          (message) => message.id === messageRes.message.id,
        );

        if (!inMessages) {
          setMessages((prev) =>
            prev.concat(deserializeMessage(messageRes.message)),
          );
        }

        sendMessage(
          JSON.stringify({
            type: 'cmd.delete_msg',
            data: messageRes.message.id,
          }),
        );
      }
    }
  }, [bookingId, lastMessage, messages, sendMessage]);

  if (!messageQuery.messages) {
    return (
      <div
        className={css({
          padding: theme.sizing.scale700,
        })}
      >
        <HeadingSmall>Select a booking</HeadingSmall>
        <ParagraphXSmall color="contentTertiary" marginTop="scale200">
          Select a booking card from your{' '}
          <span
            className={css({ color: theme.colors.primary, fontWeight: 700 })}
          >
            Appointments
          </span>{' '}
          to view its messages.
        </ParagraphXSmall>
      </div>
    );
  }

  if (messageQuery.error) {
    return <div>Error</div>;
  }
  return (
    <div
      className={css({
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        position: 'relative',
      })}
    >
      {messageQuery.isValidating && (
        <LabelSmall
          $style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            backgroundColor: theme.colors.positive300,
            color: theme.colors.white,
            textAlign: 'center',
          }}
        >
          Updating Messages
        </LabelSmall>
      )}
      <ul
        ref={listRef}
        className={css({
          overflowY: 'auto',
          flex: 1,
          padding: theme.sizing.scale700,
        })}
      >
        {messages.map((message) => (
          <li
            key={message.dateSent.toISOString()}
            className={css({
              display: 'flex',
              justifyContent: message.isAuthor ? 'flex-end' : 'flex-start',
            })}
          >
            <div
              className={css({
                display: 'flex',
                alignItems: 'start',
                justifySelf: 'flex-end',
              })}
            >
              {message.isAuthor && (
                <Avatar
                  size="scale800"
                  name={message.authorName}
                  // src="https://avatars.dicebear.com/api/human/yard.svg?width=285&mood=happy"
                />
              )}
              <div className={css({ marginLeft: theme.sizing.scale200 })}>
                <LabelXSmall color="#676767">
                  {message.isAuthor ? message.authorName : 'Administrator'}
                </LabelXSmall>
                <ParagraphSmall
                  as="div"
                  marginTop={theme.sizing.scale100}
                  className={css({
                    borderRadius: theme.sizing.scale300,
                  })}
                  backgroundColor={message.isAuthor ? '#E4E4E4' : '#E3F0FF'}
                  padding={theme.sizing.scale400}
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: message.message }}
                  />
                </ParagraphSmall>
                <LabelXSmall
                  color="#676767"
                  className={css({
                    fontSize: '0.5rem',
                  })}
                >
                  {`${format(message.dateSent, 'MMMM dd, yyyy hh:mm aa')}`}
                </LabelXSmall>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
