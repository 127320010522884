import { KIND } from 'baseui/banner';
import { SIZE as ButtonSize } from 'baseui/button';
import { Alert, Check, Delete } from 'baseui/icon';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { Skeleton } from 'baseui/skeleton';
import { useSnackbar } from 'baseui/snackbar';
import { Spinner } from 'baseui/spinner';
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography';
import { format } from 'date-fns';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate as swrMutate } from 'swr';

import Banner from 'components/Banner';
import ErrorMessage from 'components/ErrorMessage';
import WizardNavigation from 'components/WizardNavigation';

import { Button } from 'components';

import BookingForm from 'features/BookingForm';
import { BookingFormNavigation } from 'features/BookingForm/BookingForm';
import { BOOKING_FORM_STEPS } from 'features/BookingForm/constants';
import {
  BookingFormFields,
  useBookingFormStore,
} from 'features/BookingForm/store';
import { BookingFormAction } from 'features/BookingForm/types';

import { useEditBooking } from 'services/mutations/bookings';
import {
  useBooking,
  useBookingAttachment,
  useBookingOptions,
} from 'services/queries/bookings';

import { Booking, BookingStatus } from 'types/bookings';

import { useStyletron } from 'theme';

import { getErrorMessage } from 'utils/errors';

export default function EditBooking() {
  const navigate = useNavigate();
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const { bookingId = '' } = useParams();
  const { mutate: refreshBookingDetails, booking } = useBooking(bookingId);
  const [mutate, editBookingMutation] = useEditBooking();
  const { mutate: refetchAttachments } = useBookingAttachment(bookingId);

  const mutations = [editBookingMutation];
  const isSubmitting = mutations.some((m) => m.status === 'running');
  const isError = mutations.some((m) => m.status === 'failure');
  const isCancelled = booking?.status === BookingStatus.CANCELLED;
  const mutationError = getErrorMessage(editBookingMutation.error);
  const validationErrors =
    mutationError.code === 400
      ? mutationError.errors
          ?.map((error) => error.split(':')?.[1])
          .filter(Boolean)
      : [];

  const resetFormStore = useBookingFormStore((state) => state.resetValues);
  const setActionType = useBookingFormStore((state) => state.setActionType);

  useEffect(() => {
    if (isCancelled) {
      navigate(-1);
    }
  }, [isCancelled, navigate]);

  useEffect(() => {
    setActionType(BookingFormAction.EDIT);
  }, [setActionType]);

  return (
    <Modal
      onClose={() => navigate('..', { replace: true })}
      closeable={!isSubmitting}
      isOpen
      autoFocus={false}
      overrides={{
        DialogContainer: {
          style: {
            alignItems: 'flex-end',
            [theme.mediaQuery.large]: {
              alignItems: 'center',
            },
          },
        },
        Dialog: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '80vh',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            [theme.mediaQuery.large]: {
              width: '60vw',
              marginLeft: theme.sizing.scale600,
              marginRight: theme.sizing.scale600,
              marginBottom: theme.sizing.scale600,
              borderBottomLeftRadius: theme.sizing.scale600,
              borderBottomRightRadius: theme.sizing.scale600,
            },
          },
        },
      }}
      role={ROLE.dialog}
    >
      <WizardNavigation steps={BOOKING_FORM_STEPS} mapToURL={false}>
        <ModalHeader>Edit Booking</ModalHeader>
        <ModalBody style={{ flex: '1 1 0', overflowY: 'auto' }}>
          <Banner
            title="Failed to modify booking details, please try again."
            show={isError}
            artwork={{
              // eslint-disable-next-line react/no-unstable-nested-components
              icon() {
                return <Alert />;
              },
            }}
            kind={KIND.negative}
          >
            <ErrorMessage
              error={editBookingMutation.error}
              error401={(error) => error}
              error400={(errors) =>
                errors.map((error) => (
                  <div
                    className={css({
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: theme.sizing.scale200,
                    })}
                  >
                    <Delete />
                    <p className={css({ marginLeft: theme.sizing.scale100 })}>
                      {error}
                    </p>
                  </div>
                ))
              }
            />
          </Banner>

          <ErrorBoundary
            // eslint-disable-next-line react/no-unstable-nested-components
            FallbackComponent={({ resetErrorBoundary }) => (
              <div role="alert">
                <HeadingXSmall>Something went wrong</HeadingXSmall>
                <ParagraphSmall color={theme.colors.contentTertiary}>
                  Failed to get booking information. try again
                </ParagraphSmall>
                <Button
                  onClick={resetErrorBoundary}
                  size={ButtonSize.compact}
                  $style={{ marginTop: theme.sizing.scale300 }}
                >
                  Try Again
                </Button>
              </div>
            )}
          >
            <Suspense fallback={<Skeleton rows={5} />}>
              <EditBookingForm
                onSubmit={async (values) => {
                  if (isSubmitting) return;
                  const isRequestForMessage =
                    values.type.label === 'Request for Message Only';

                  mutate(
                    {
                      name: values.agenda,
                      start_datetime: `${format(
                        values.date,
                        'yyyy-MM-dd',
                      )} ${format(values.timeslot.start, 'HH:mm:ss')}`,
                      stop_datetime: `${format(
                        values.date,
                        'yyyy-MM-dd',
                      )} ${format(values.timeslot.end, 'HH:mm:ss')}`,
                      appointment_type_id: values.type.value,
                      location: values.platformPosted,
                      description: values.description,
                      attachments: values.files?.length ? values.files : [],
                      sector_ids: values.guests.map((i) => i.id),
                      other_sector: values.otherSector ?? '',
                      audience_ids: values.audienceTypes.map((v) => v.id),
                      other_audience: values.otherAudienceType ?? '',
                      /**
                       * Use location_name for platform posted value
                       * just used nullish coealscaling to address use case where location.name
                       * is supplied but not the platform for certain event types
                       */
                      location_name: isRequestForMessage
                        ? values?.platformPosted
                        : values?.location?.name,
                      location_address: values?.location?.address,
                      role_id: values.role.value,
                      other_role: '',
                      meeting_link: values.meetingLink,
                      no_of_participants: Number(values.particiapantsCount),
                      dress_code_id: values?.dressCode?.value,
                      token: values.token,
                      country_id: values.country,
                      region_id: values.region,
                      province_id: values.province,
                      city_id: values.city
                    },
                    {
                      onSuccess() {
                        enqueue({
                          message: 'Booking Updated',
                          // eslint-disable-next-line react/no-unstable-nested-components
                          startEnhancer: ({ size }) => <Check size={size} />,
                        });
                        refreshBookingDetails();
                        refetchAttachments();
                        swrMutate('/bookings');
                        resetFormStore();
                        navigate(-1);
                      },
                      onFailure: ({ error }) => {
                        console.log('mutation error', error);
                      },
                    },
                  );
                }}
              />
            </Suspense>
          </ErrorBoundary>
          {isSubmitting && (
            <div
              className={css({
                position: 'absolute',
                inset: 0,
                backgroundColor: theme.colors.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              })}
            >
              <Spinner />
              <HeadingXSmall
                $style={{
                  fontWeight: 600,
                }}
                color={theme.colors.primary}
              >
                Updating Booking Details
              </HeadingXSmall>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <BookingFormNavigation />
        </ModalFooter>
      </WizardNavigation>
    </Modal>
  );
}

type EditBookingFormValues = BookingFormFields & { token: string };
function EditBookingForm({
  onSubmit: handleFormSubmit,
}: {
  // eslint-disable-next-line react/require-default-props
  onSubmit?: (values: EditBookingFormValues) => void;
}) {
  const { bookingId = '' } = useParams();
  const { booking: bookingData } = useBooking(bookingId, { suspense: true });
  const booking = bookingData as Required<Booking>;
  const bookingOptionsQuery = useBookingOptions({ suspense: true });
  const isConfirmed = booking?.status === BookingStatus.CONFIRMED;

  return (
    <BookingForm
      action={BookingFormAction.EDIT}
      onSubmit={(formValues) => {
        if (handleFormSubmit) {
          handleFormSubmit({
            ...formValues,
            token: booking.bookingToken,
          } as EditBookingFormValues);
        }
      }}
      initialValues={{
        agenda: booking.agenda,
        date: booking.date,
        description: booking.details,
        location: {
          name: booking.location.name ?? '',
          address: booking.location.address ?? '',
        },
        timeslot: booking.timeslot,
        type: {
          label:
            bookingOptionsQuery.bookingOptions?.meetingTypes?.find(
              (type) => type.id === booking.meetingType,
            )?.name ?? 'Duumy Label',
          value: booking.meetingType,
        },
        role: {
          label: booking.role.name,
          value: booking.role.id,
        },
        dressCode: booking.dressCode
          ? {
              label: booking.dressCode.name,
              value: booking.dressCode.id,
            }
          : undefined,
        audienceTypes: booking.audience,
        otherAudienceType: booking.otherAudience,
        guests: booking.sectors,
        otherSector: booking.otherSector,
        meetingLink: booking.meetingLink ?? '',
        particiapantsCount: booking.particiapantsCount.toString(),
        platformPosted: booking.messagePlatform,
        country: booking.countryId,
        region: booking.regionId,
        province: booking.provinceId,
        city: booking.cityId,
      }}
      editable={!isConfirmed}
    />
  );
}
