/* eslint-disable react/require-default-props */
import { KIND, SIZE } from 'baseui/button';
import { StatefulSelect, StatefulSelectProps } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import { LabelMedium } from 'baseui/typography';
import React from 'react';

import { useBookingOptions } from 'services/queries/bookings';

import { useStyletron } from 'theme';

import Button from '../../Button';

type OwnSelectProps = {
  value?: number;
  meetingTypeId?: number;
};

export default function EventRoleSelect(
  props: Omit<StatefulSelectProps, 'value'> & OwnSelectProps,
) {
  const [css, theme] = useStyletron();
  const bookingOptionsQuery = useBookingOptions();
  const { value, meetingTypeId, ...selectProps } = props;

  if (bookingOptionsQuery.error) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.sizing.scale400,
          ...theme.borders.border200,
          borderRadius: theme.sizing.scale300,
        })}
      >
        <LabelMedium color={theme.colors.negative}>
          Failed to load meeting types
        </LabelMedium>
        <Button
          type="button"
          size={SIZE.mini}
          kind={KIND.tertiary}
          onClick={() => bookingOptionsQuery.mutate()}
        >
          Try again
        </Button>
      </div>
    );
  }

  if (bookingOptionsQuery?.bookingOptions?.meetingTypes !== undefined) {
    const meetingType = bookingOptionsQuery.bookingOptions.meetingTypes.find(
      (type) => type.id === meetingTypeId,
    );

    if (!meetingType) {
      // TODO: Add cool ui
      return <div>no roles available for this meeting type</div>;
    }

    const types =
      meetingType.roles
        .sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        )
        .map((role) => ({
          label: role.name,
          value: role.id,
        })) ?? [];
    const selected = types.find((role) => role.value === value);

    return (
      <StatefulSelect
        initialState={
          selected
            ? {
                value: [selected],
              }
            : undefined
        }
        clearable={false}
        searchable={false}
        placeholder="Select Role"
        {...selectProps}
        options={types}
        labelKey="label"
        valueKey="value"
        maxDropdownHeight="300px"
      />
    );
  }
  return (
    <Skeleton
      animation
      height={theme.sizing.scale1200}
      overrides={{
        Root: {
          style: { borderRadius: theme.sizing.scale300 },
        },
      }}
    />
  );
}
