import React, { useEffect, useState } from 'react';
import { addHandler, setHeader } from 'services/api';
import fetch2 from 'services/fetch2';
import { getNewToken, getTokens } from 'utils';
import SplashScreen from './SplashScreen';

export default function AuthInitializer({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    // Initialize tokens
    const tokens = getTokens();
    // Logout if no token
    if (!tokens) {
      localStorage.removeItem('token_data');
    } else {
      setHeader('Authorization', `${tokens.tokenType} ${tokens.accessToken}`);
    }

    addHandler(async (response, req, data) => {
      // if (response.status === 401) {
      //   localStorage.removeItem('token_data'); // logout
      // }
      if (response.status === 403) {
        const accessToken = await getNewToken();
        const response2 = await fetch2(req.url, {
          ...req,
          body: ['GET', 'DELETE'].includes(req.method) ? null : data,
          headers: {
            ...req.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return response2;
      }

      return response;
    }, false);
    setIsInitialized(true);
  }, [isInitialized]);

  if (!isInitialized) {
    return <SplashScreen />;
  }
  /**
   *  We can disable this since react has some weird bug on
   * typescript when return ReactNode
   */
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
