import { HeadingLarge, ParagraphMedium } from 'baseui/typography';
import React from 'react';
import { useStyletron } from 'theme';

export default function DashboardHome() {
  const [css, theme] = useStyletron();
  return (
    <div>
      <HeadingLarge color="primary">Select a booking</HeadingLarge>
      <ParagraphMedium color="contentTertiary" marginTop="scale200">
        Select a booking card from your{' '}
        <span className={css({ color: theme.colors.primary, fontWeight: 700 })}>
          Appointments
        </span>{' '}
        to view its information.
      </ParagraphMedium>
    </div>
  );
}
