/* eslint-disable react/require-default-props */
import { Block } from 'baseui/block';
import { KIND, SHAPE, SIZE } from 'baseui/button';
import { ArrowLeft } from 'baseui/icon';
import { HeadingXSmall } from 'baseui/typography';
import React from 'react';

import { LinkButton, VARIANT } from 'components/Button';
import WizardNavigation, { useWizardStore } from 'components/WizardNavigation';

import { Button } from 'components';

import { BOOKING_FORM_STEPS } from 'features/BookingForm/constants';

import { useStyletron } from 'theme';

import Logo from 'assets/images/scheduler-ovp-logo.png';

/**
 * TODO: Rename this file to `BookingCreateLayout` since this is only used for create booking
 */
export default function BookingFormLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [css, theme] = useStyletron();

  return (
    <WizardNavigation steps={BOOKING_FORM_STEPS} mapToURL={false}>
      <div
        className={css({
          height: '100%',
          overflowY: 'auto',
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: theme.sizing.scale850,
            paddingRight: theme.sizing.scale850,
            paddingTop: theme.sizing.scale1400,
            maxWidth: `${Math.fround(1024 / 16)}rem`,
            width: '100%',
            height: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          })}
        >
          <Block
            display="flex"
            alignItems="flex-start"
            flex={1}
            flexDirection={['column', 'column', 'row']}
          >
            <div
              className={css({
                width: '100%',
                [theme.mediaQuery.medium]: {
                  position: 'sticky',
                  top: theme.sizing.scale1400,
                  width: 'auto',
                  paddingRight: theme.sizing.scale2400,
                },
              })}
            >
              <LinkButton
                to="/bookings"
                size={SIZE.compact}
                kind={KIND.tertiary}
                startEnhancer={<ArrowLeft />}
              >
                Back to bookings
              </LinkButton>
              <img
                className={css({
                  height: theme.sizing.scale1200,
                  marginTop: theme.sizing.scale600,
                })}
                src={Logo}
                alt="scheduler"
              />
              <div className={css({ marginTop: theme.sizing.scale1400 })}>
                <HeadingXSmall
                  $style={{
                    fontWeight: 700,
                    marginBottom: theme.sizing.scale600,
                  }}
                >
                  Book an Appointment
                </HeadingXSmall>
                <BookingStepProgress />
              </div>
            </div>
            <div
              className={css({
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                paddingTop: theme.sizing.scale600,
                [theme.mediaQuery.medium]: {
                  paddingTop: theme.sizing.scale1400,
                },
              })}
            >
              <div
                className={css({
                  paddingBottom: theme.sizing.scale1400,
                })}
              >
                {children}
              </div>
            </div>
          </Block>
        </div>
      </div>
    </WizardNavigation>
  );
}

function BookingStepProgress() {
  const [css, theme] = useStyletron();
  const { currentStep, set } = useWizardStore((state) => ({
    currentStep: state.meta.step,
    set: state.setNewStep,
  }));

  return (
    <nav aria-label="Progress">
      <ol
        className={css({
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {BOOKING_FORM_STEPS.map((step, index, steps) => (
          <li
            key={step[0].name}
            className={css({
              position: 'relative',
              flex: 1,
              paddingRight: theme.sizing.scale400,
              [theme.mediaQuery.medium]: {
                paddingRight: theme.sizing.scale900,
              },
            })}
          >
            {steps.length - 1 !== index && (
              <div
                className={css({
                  position: 'absolute',
                  inset: 0,
                  display: 'flex',
                  alignItems: 'center',
                })}
              >
                <div
                  className={css({
                    height: '0.125rem',
                    width: '100%',
                    backgroundColor: theme.colors.primary,
                  })}
                />
              </div>
            )}
            <div className={css({ position: 'relative' })}>
              <Button
                type={currentStep < index ? 'submit' : 'button'}
                form={
                  index !== 0 && currentStep < index
                    ? steps[currentStep][0].name
                    : undefined
                }
                onClick={
                  index < currentStep
                    ? () => {
                      set(steps[index][0].name);
                    }
                    : undefined
                }
                shape={SHAPE.circle}
                size={SIZE.mini}
                variant={currentStep >= index ? VARIANT.solid : VARIANT.outline}
              >
                {index + 1}
              </Button>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
