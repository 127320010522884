/* eslint-disable react/require-default-props */
import { KIND, SIZE } from 'baseui/button';
import { StatefulSelect, StatefulSelectProps } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import { LabelMedium } from 'baseui/typography';
import React from 'react';

import { useBookingOptions } from 'services/queries/bookings';

import { useStyletron } from 'theme';

import Button from '../../Button';

type OwnSelectProps = {
  value?: number;
  meetingTypeId?: number;
};

export default function DressCodeSelect(
  props: Omit<StatefulSelectProps, 'value'> & OwnSelectProps,
) {
  const [css, theme] = useStyletron();
  const bookingOptionsQuery = useBookingOptions();
  const { value, meetingTypeId, ...selectProps } = props;

  if (!meetingTypeId) {
    // TODO: Add cool ui
    return <div>Select Meeting Type</div>;
  }

  if (bookingOptionsQuery.error) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.sizing.scale400,
          ...theme.borders.border200,
          borderRadius: theme.sizing.scale300,
        })}
      >
        <LabelMedium color={theme.colors.negative}>
          Failed to load meeting types
        </LabelMedium>
        <Button
          type="button"
          size={SIZE.mini}
          kind={KIND.tertiary}
          onClick={() => bookingOptionsQuery.mutate()}
        >
          Try again
        </Button>
      </div>
    );
  }

  if (bookingOptionsQuery?.bookingOptions?.dressCode !== undefined) {
    const types =
      bookingOptionsQuery.bookingOptions.dressCode
        .sort((a, b) => a.name.localeCompare(
          b.name,
          undefined,
          {
            numeric: true,
            sensitivity: 'base',
          },
        ))
        .map((code) => ({
          label: code.name,
          value: code.id,
        })) ?? [];
    const selected = types.find((code) => code.value === value);

    return (
      <StatefulSelect
        initialState={
          selected
            ? {
              value: [selected],
            }
            : undefined
        }
        clearable={false}
        searchable={false}
        {...selectProps}
        options={types}
        labelKey="label"
        valueKey="value"
        placeholder="Select Dress Code"
        maxDropdownHeight="300px"
      />
    );
  }
  return (
    <Skeleton
      animation
      height={theme.sizing.scale1200}
      overrides={{
        Root: {
          style: { borderRadius: theme.sizing.scale300 },
        },
      }}
    />
  );
}
