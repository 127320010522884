/* eslint-disable react/require-default-props */
import React from 'react';

import Box from 'components/Box';

import theme, { parseToUtils, stitchesConfig } from 'theme';

const { styled, config } = stitchesConfig;
const { fontWeights } = config.theme;
/**
 * TODO:
 * - Add variant to default tag mapper
 */
type OwnProps = {
  as?: React.ElementType<any>;
};

const typographyObject = Object.keys(theme.typography).reduce(
  (all, current) => ({
    ...all,
    [current]:
      theme.typography[current as keyof typeof theme.typography].fontSize,
  }),
  {} as Record<keyof typeof theme.typography, string>,
);

const textVariants = parseToUtils(
  typographyObject,
  (key: keyof typeof theme.typography) => {
    const { fontWeight, ...fontStyle } = theme.typography[key];
    return {
      ...fontStyle,
    };
  },
);

const textWeights = parseToUtils(fontWeights, (key) => ({
  fontWeight: fontWeights[key],
}));

const StyledText = styled(Box, {
  variants: {
    variant: { ...textVariants },
    weight: { ...textWeights },
  },
  defaultVariants: {
    variant: 'LabelMedium',
    weight: 'normal',
  },
});

export type TextProps = Omit<
  React.ComponentProps<typeof StyledText>,
  keyof OwnProps
> &
  OwnProps;

export default function Text(props: TextProps) {
  return <StyledText {...props} />;
}
