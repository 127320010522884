import React, { useCallback, useEffect } from 'react';

import { Alert } from 'baseui/icon';
import { Spinner } from 'baseui/spinner';
import { LabelMedium } from 'baseui/typography';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useVerify } from 'api/auth';
import { useStyletron } from 'theme';
import { LinkButton } from 'components/Button';

export default function Verify() {
  const navigate = useNavigate();
  const [css, theme] = useStyletron();
  const [searchParams] = useSearchParams();

  const [mutate, verifyEmailMutation] = useVerify();

  const token = searchParams.get('token');

  const verifyEmail = useCallback(async () => {
    await mutate(token as string, {
      onFailure: ({ error }) => {
        console.log('mutation error', error);
      },
    });
  }, [mutate, token]);

  useEffect(() => {
    if (token) {
      verifyEmail();
    } else {
      navigate('/');
    }
  }, [navigate, token, verifyEmail]);

  return (
    <div
      className={css({
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.sizing.scale600,
      })}
    >
      <LabelMedium
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {verifyEmailMutation.status === 'failure' && (
          <div
            className={css({
              color: theme.colors.negative,
              display: 'flex',
              alignItems: 'center',
              padding: theme.sizing.scale500,
              marginBottom: theme.sizing.scale500,
            })}
          >
            <Alert size={theme.sizing.scale800} />
            <LabelMedium marginLeft={theme.sizing.scale100}>
              Failed to verify user email, please try again.
            </LabelMedium>
          </div>
        )}
        {verifyEmailMutation.status === 'running' && (
          <>
            Verifying...
            <Spinner
              $style={() => ({ marginLeft: '1rem' })}
            />
          </>
        )}
        {verifyEmailMutation.status === 'success' && (
          `${verifyEmailMutation.data.detail}. You may now log in.`
        )}
      </LabelMedium>
      {verifyEmailMutation.status === 'success' && (
        <LinkButton
          to="/"
          $style={{
            marginTop: theme.sizing.scale600,
          }}
        >
          Log in
        </LinkButton>
      )}
    </div>
  );
}
