import useSWR, { SWRConfiguration } from 'swr';

import { get } from 'services/api';

import { User } from 'types/auth';

import { deserializeUser } from 'deserializers/users';

export async function getCurrentUser(endpoint: string): Promise<User> {
  const response = await get(endpoint);

  return deserializeUser(response);
}

export default function useCurrentUser(config?: SWRConfiguration) {
  const { data, error, isValidating, mutate } = useSWR<User>(
    '/users/myprofile',
    getCurrentUser,
    {
      ...(config ?? {}),
      dedupingInterval: 2000,
      focusThrottleInterval: 30 * 1000,
    },
  );

  return {
    user: data,
    isLoading: !error && data === undefined,
    isError: error,
    isValidating,
    error,
    mutate,
  };
}
