import create from 'zustand';

import {
  AudienceTypesFormFields,
  BasicInformationFormFields,
  BookingFormAction,
  BookingReviewFormFields,
  EventInformationFormFields,
  ExpectedGuestFormFields,
} from './types';

type SetValueStore<T> = Partial<T> & {
  setValue: <K extends keyof T>(key: K, value?: T[K]) => void;
};

export type BookingFormFields = BasicInformationFormFields &
  EventInformationFormFields &
  BookingReviewFormFields &
  ExpectedGuestFormFields &
  AudienceTypesFormFields;

type State = Partial<BookingFormFields> &
  SetValueStore<BookingFormFields> & {
    resetValues: () => void;
    actionType: BookingFormAction;
    setActionType: (value: BookingFormAction) => void;
  };

export const useBookingFormStore = create<State>((set) => ({
  actionType: BookingFormAction.CREATE,
  setActionType: (type) => {
    set({ actionType: type });
  },
  setValue(key, value) {
    set((state) => ({ ...state, [key]: value }));
  },
  resetValues() {
    set(
      ({ actionType, setValue, resetValues, setActionType }) => ({
        actionType,
        setValue,
        resetValues,
        setActionType,
      }),
      true,
    );
  },
}));
