import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { checkTokens } from 'utils';
import { deleteHeader } from 'services/api';

export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    if (checkTokens()) {
      localStorage.removeItem('token_data');
      deleteHeader('Authorization');
    }
    navigate('/');
  }, [navigate]);

  return null;
}
