import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Select } from 'baseui/select';
import { bookingStatus } from 'constants/select-options';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import FormControl from 'components/FormControl';

import { useStyletron } from 'theme';

const statusOptions = Object.entries(bookingStatus).map((entry) => {
  const [key, value] = entry;
  return {
    label: value.toString(),
    id: key.toString().toLowerCase(),
  };
});

export default function BookingFilterNav() {
  const [css, theme] = useStyletron();
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <FlexGrid
      as="nav"
      flexGridColumnCount={2}
      flexGridColumnGap={theme.sizing.scale600}
    >
      <FlexGridItem>
        <FormControl label="Status">
          <Select
            value={
              searchParams.has('status')
                ? statusOptions.filter(
                    (opt) =>
                      opt.id === searchParams.get('status')?.replace('_', ' '),
                  )
                : undefined
            }
            options={statusOptions}
            valueKey="id"
            onChange={(value) => {
              if (value.option?.id) {
                searchParams.set(
                  'status',
                  `${value.option?.id.toString().replace(' ', '_')}`,
                );
              } else {
                searchParams.delete('status');
              }
              setSearchParams(searchParams, { replace: true });
            }}
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl label="Date">
          <Select
            value={
              searchParams.has('order')
                ? [
                    {
                      label: searchParams.get('order') ?? '',
                      id: searchParams.get('order') ?? '',
                    },
                  ]
                : undefined
            }
            options={[
              {
                label: 'Nearest First',
                id: 'asc',
              },
              {
                label: 'Furthest First',
                id: 'desc',
              },
            ]}
            onChange={(value) => {
              if (value.option?.id) {
                searchParams.set('order', value.option?.id.toString());
              } else {
                searchParams.delete('order');
              }
              setSearchParams(searchParams, { replace: true });
            }}
          />
        </FormControl>
      </FlexGridItem>
    </FlexGrid>
  );
}
