import { useStyletron } from 'baseui';
import { Check } from 'baseui/icon';
import {
  HeadingSmall,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
} from 'baseui/typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components';

import seal from 'assets/images/ovp-seal.png';
import vectorDark from 'assets/images/scheduler-vector-black.png';

export default function BookSuccess() {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();

  return (
    <div
      className={css({
        maxWidth: `${1024 / 16}rem`,
        marginInline: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.sizing.scale700,
      })}
    >
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: theme.sizing.scale800,
        })}
      >
        <div
          className={css({
            height: theme.sizing.scale3200,
          })}
        >
          <img
            src={seal}
            className={css({ height: '100%' })}
            alt="Office of the Vice President"
          />
        </div>
        <img src={vectorDark} width={230} height={40} alt="scheduler vector" />
        <HeadingSmall $style={{ textAlign: 'center', fontWeight: 400 }}>
          FOR THE OFFICE OF THE VICE PRESIDENT
        </HeadingSmall>
      </div>
      <HeadingXSmall>Booking Created</HeadingXSmall>
      <ParagraphMedium
        className={css({
          maxWidth: `${768 / 16}rem`,
          textAlign: 'center',
        })}
        marginBottom="scale800"
      >
        Thank you for booking your appointment with the Office of the Vice
        President. Please read the reminders before proceeding.
      </ParagraphMedium>
      <ul
        className={css({
          marginTop: theme.sizing.scale800,
          maxWidth: `${768 / 16}rem`,
        })}
      >
        {[
          'Remember the email address and password you used to register your account in the Scheduler.',
          'You may check the status of your request through your account. There is no need to follow-up or update your request after booking.',
          'You will also receive an email and an automated text message of the status of your request.',
          'You may message through the Scheduler Messaging System. The system shall also be used to respond and inform you relative to your requests.',
        ].map((item, index) => (
          <li
            key={item}
            className={css({
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: index !== 0 ? theme.sizing.scale700 : 0,
            })}
          >
            <span
              className={css({
                flexShrink: 0,
                width: theme.sizing.scale800,
                height: theme.sizing.scale800,
                backgroundColor: theme.colors.primary50,
                borderRadius: '99999px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              })}
            >
              <Check size={16} />
            </span>
            <LabelMedium marginLeft={theme.sizing.scale600}>{item}</LabelMedium>
          </li>
        ))}
      </ul>
      <Button
        onClick={() => navigate('/bookings', { replace: true })}
        $style={{ marginTop: theme.sizing.scale1200 }}
      >
        View my Bookings
      </Button>
    </div>
  );
}
