import { mergeOverrides, withStyle } from 'baseui';
import {
  Button as BWButton,
  ButtonProps as BWButtonProps,
  KIND as BW_KIND,
  ButtonOverrides,
  KIND,
  SHAPE,
  SIZE,
  StyledBaseButton,
} from 'baseui/button';
import { Overrides } from 'baseui/overrides';
import React from 'react';
import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';
import { StyleObject, StyletronComponentInjectedProps } from 'styletron-react';

import SchedulerTheme, { useStyletron } from 'theme';

/**
 * FIXME: Use styled BaseButton to fix overrides not being applied
 */

const borders: StyleObject = {
  borderBottomStyle: 'solid',
  borderTopStyle: 'solid',
  borderLeftStyle: 'solid',
  borderRightStyle: 'solid',
  borderBottomWidth: '1px',
  borderTopWidth: '1px',
  borderLeftWidth: '1px',
  borderRightWidth: '1px',
};

export enum VARIANT {
  solid = 'solid',
  outline = 'outline',
}

export enum INTENT {
  'negative',
  'positive',
  'warning',
}

/**
 * @link (https://github.com/uber/baseweb/blob/master/src/button/types.js)
 */
type SharedStyleProps = {
  $colors?: { backgroundColor: string; color: string };
  $kind?: keyof typeof BW_KIND;
  $isSelected?: boolean;
  $shape?: keyof typeof SHAPE;
  $size?: keyof typeof SIZE;
  $isLoading?: boolean;
  $disabled?: boolean;
  $isFocusVisible: boolean;
};

const VARIANT_STYLES: Record<
  keyof typeof BW_KIND,
  Record<VARIANT, StyleObject>
> = {
  primary: {
    solid: {
      color: SchedulerTheme.colors.white,
      backgroundColor: SchedulerTheme.colors.primary,
      ...borders,
      borderColor: SchedulerTheme.colors.primary,
      ':hover': {
        backgroundColor: SchedulerTheme.colors.white,
        color: SchedulerTheme.colors.primary,
      },
    },
    outline: {
      color: SchedulerTheme.colors.primary,
      backgroundColor: SchedulerTheme.colors.white,
      ...borders,
      borderColor: SchedulerTheme.colors.primary,
      ':hover': {
        backgroundColor: SchedulerTheme.colors.primary,
        color: SchedulerTheme.colors.white,
      },
    },
  },
  secondary: {
    outline: {
      color: SchedulerTheme.colors.secondary,
      backgroundColor: SchedulerTheme.colors.white,
      ...borders,
      borderColor: SchedulerTheme.colors.secondary,
      ':hover': {
        backgroundColor: SchedulerTheme.colors.secondary,
        color: SchedulerTheme.colors.white,
      },
    },
    solid: {},
  },
  tertiary: {
    outline: {},
    solid: {},
  },
};

// eslint-disable-next-line react/require-default-props
type OwnButtonProps = { variant?: VARIANT; intent?: INTENT };
type NativeElementProps = Omit<
  React.ComponentPropsWithoutRef<'button'>,
  keyof OwnButtonProps
>;

export type ButtonProps = BWButtonProps &
  OwnButtonProps &
  NativeElementProps &
  StyletronComponentInjectedProps<ButtonProps>;

const ButtonComponentOverride = withStyle<
  typeof StyledBaseButton,
  SharedStyleProps & { $variant: VARIANT; $intent: INTENT }
>(StyledBaseButton, ({ $theme, ...sharedProps }) => ({
  ...getVariantStyles({
    kind: sharedProps.$kind,
    variant: sharedProps.$variant,
  }),
  ...getIntentStyles(sharedProps.$intent),
  ...getDisabledStyles(sharedProps.$disabled),
}));

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const [css, theme] = useStyletron();
    const { overrides, ...propsWithoutOverrides } = props;
    const style = props.$style;
    const styleFn =
      typeof style === 'function'
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style({ $theme: theme })
        : {};

    return (
      <BWButton
        ref={ref}
        shape={SHAPE.pill}
        {...propsWithoutOverrides}
        overrides={mergeOverrides(
          {
            BaseButton: {
              component: ButtonComponentOverride,
              props: {
                $intent: propsWithoutOverrides.intent,
                $variant: propsWithoutOverrides.variant ?? VARIANT.solid,
              },
              style: {
                ...props.$style,
                ...styleFn,
              },
            },
          },
          overrides as Overrides<ButtonOverrides>,
        )}
      />
    );
  },
);
export default Button;

function getVariantStyles({
  kind,
  variant,
}: {
  kind?: keyof typeof KIND;
  variant: VARIANT;
}) {
  const kindValue = kind ?? 'primary';
  const variantValue = variant ?? VARIANT.solid;
  return VARIANT_STYLES[kindValue][variantValue];
}
function getIntentStyles(intent?: INTENT): StyleObject | undefined {
  if (intent === undefined) return Object.freeze({});
  switch (intent) {
    case INTENT.negative:
      return {
        borderColor: SchedulerTheme.colors.negative,
        backgroundColor: SchedulerTheme.colors.negative,
        ':hover': {
          backgroundColor: SchedulerTheme.colors.negative500,
        },
      };
    case INTENT.positive:
      return {
        borderColor: SchedulerTheme.colors.positive,
        backgroundColor: SchedulerTheme.colors.positive,
        ':hover': {
          backgroundColor: SchedulerTheme.colors.positive600,
        },
      };

    default:
      return Object.freeze({});
  }
}

function getDisabledStyles(disabled?: boolean) {
  if (disabled === undefined) return Object.freeze({});

  switch (disabled) {
    case false:
      return Object.freeze({});
    case true:
      return {
        ':disabled': {
          borderColor: SchedulerTheme.colors.buttonDisabledText,
        },
        ':hover': {
          backgroundColor: SchedulerTheme.colors.buttonDisabledFill,
        },
      };
    default:
      return Object.freeze({});
  }
}

export const LinkButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & LinkProps
>((props, ref) => {
  const {
    to,
    reloadDocument,
    replace = false,
    state,
    target,
    onClick,
    ...baseButtonProps
  } = props;

  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  });

  return (
    <Button
      {...baseButtonProps}
      $as="a"
      href={href}
      onClick={(clickEvent) => {
        const event = clickEvent as unknown as React.MouseEvent<
          HTMLAnchorElement,
          MouseEvent
        >;
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event);
        }
      }}
      target={target}
      ref={ref}
    />
  );
});
