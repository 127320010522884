/* eslint-disable react/require-default-props */
import { KIND, SIZE } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { StyledCaption } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Skeleton } from 'baseui/skeleton';
import { LabelMedium } from 'baseui/typography';
import { chunk } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { FormControlLabel } from 'components/FormControl';

import { Button, FormController } from 'components';

import { useBookingOptions } from 'services/queries/bookings';

import { BookingOption } from 'types/bookings';

import { useStyletron } from 'theme';

import {
  AudienceTypesFormFields,
  BookingFormAction,
  TBookingForm,
} from './types';

/**
 * TODO: Handle editable prop when booking is confirmed
 *- Use fieldset in checkbox groups
 */

export default function AudienceTypesForm(
  props: TBookingForm<AudienceTypesFormFields>,
) {
  const {
    initalValue = {},
    id: formId,
    onSubmit: submitHandler,
    editable,
  } = props;
  const [css, theme] = useStyletron();

  const bookingOptionsQuery = useBookingOptions();

  const [orderedTypes, setOrderedTypes] = useState<BookingOption[][]>([]);

  const { control, handleSubmit, watch } = useForm<AudienceTypesFormFields>({
    defaultValues: initalValue,
  });
  const values = watch();
  const { append, remove } = useFieldArray({
    control,
    name: 'audienceTypes',
  });

  const onSubmit = handleSubmit((data) => {
    submitHandler(data);
  });

  const isInAudienceArray = (option: { name: string; id: number }) => {
    if (values.audienceTypes && values.audienceTypes.length > 0) {
      return values.audienceTypes.some((type) => type.id === option.id);
    }

    return false;
  };

  const addAudienceType = (option: { name: string; id: number }) => {
    if (!values.audienceTypes.some((type) => type.id === option.id)) {
      append(option);
    } else {
      remove(values.audienceTypes.findIndex((type) => type.id === option.id));
    }
  };

  useEffect(() => {
    if (bookingOptionsQuery.bookingOptions) {
      const choicesLength = Math.round(
        bookingOptionsQuery.bookingOptions.audience.length / 2,
      );
      const dividedAudienceTypes = chunk(
        bookingOptionsQuery.bookingOptions.audience,
        choicesLength,
      );

      setOrderedTypes([
        dividedAudienceTypes[0],
        [...dividedAudienceTypes[1], { id: 0, name: 'Other' }],
      ]);
    }
  }, [bookingOptionsQuery.bookingOptions]);

  if (bookingOptionsQuery.error) {
    return (
      <div
        className={css({
          ...theme.borders.border200,
          display: 'flex',
          alignItems: 'center',
          padding: theme.sizing.scale400,
          justifyContent: 'space-between',
          borderRadius: theme.sizing.scale300,
        })}
      >
        <LabelMedium color={theme.colors.negative}>
          Failed to load Expected Guests Choices
        </LabelMedium>
        <Button
          type="button"
          size={SIZE.mini}
          kind={KIND.tertiary}
          onClick={() => bookingOptionsQuery.mutate()}
        >
          Try again
        </Button>
      </div>
    );
  }

  if (!bookingOptionsQuery?.bookingOptions?.audience) {
    return (
      <Skeleton
        animation
        height={theme.sizing.scale1200}
        overrides={{
          Root: {
            style: { borderRadius: theme.sizing.scale300 },
          },
        }}
      />
    );
  }

  return (
    <form
      id={formId}
      onSubmit={onSubmit}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.sizing.scale1000,
      })}
    >
      <FormController
        control={control}
        defaultValue={[]}
        name="audienceTypes"
        rules={{
          required: 'Select at least one type',
        }}
        overrides={{
          Caption: {
            style: {
              display: 'none',
            },
          },
        }}
        label={<FormControlLabel required>Type of Audience</FormControlLabel>}
      >
        {({ fieldState: err }) => (
          <>
            <StyledCaption
              $error
              $style={{
                marginTop: 0,
              }}
            >
              {err.error?.message}
            </StyledCaption>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridRowGap={theme.sizing.scale300}
              flexGridColumnGap={theme.sizing.scale600}
            >
              {orderedTypes.map((audienceTypes) => (
                <FlexGridItem>
                  {audienceTypes.map((option) => (
                    <Checkbox
                      overrides={{
                        Root: {
                          style: {
                            marginTop: theme.sizing.scale600,
                          },
                        },
                        Label: {
                          style: {
                            fontWeight: '400',
                          },
                        },
                      }}
                      checked={isInAudienceArray(option)}
                      onChange={() => addAudienceType(option)}
                      disabled={!editable}
                    >
                      {option.name}
                    </Checkbox>
                  ))}
                </FlexGridItem>
              ))}
            </FlexGrid>
          </>
        )}
      </FormController>
      {!!values.audienceTypes?.some((guest) => guest.name === 'Other') && (
        <FormController
          control={control}
          name="otherAudienceType"
          defaultValue=""
          label="Audience Type Name"
          rules={{
            required: 'This field is required',
          }}
          disabled={!editable}
        >
          {({ field }) => <Input {...field} id={field.name} />}
        </FormController>
      )}
    </form>
  );
}
