import React, { useCallback, useEffect } from 'react';

import { RegisterOptions, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Input } from 'baseui/input';
import { Block } from 'baseui/block';
import { Spinner } from 'baseui/spinner';
import { KIND, SHAPE, SIZE } from 'baseui/button';
import { StyledCaption } from 'baseui/form-control';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { HeadingMedium, HeadingXSmall, LabelSmall } from 'baseui/typography';

import { useStyletron } from 'theme';
import { LinkButton } from 'components/Button';
import { Button, FormControl } from 'components';
import { getErrorMessage } from 'utils/errors';
import ApiError from '../../api/errors';
import { useConfirmPassword, useVerifyResetToken } from '../../api/auth';

import Logo from '../../assets/images/scheduler-ovp-logo.png';

type FormFields = {
  password: string;
  confirmPassword: string;
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [css, theme] = useStyletron();

  const token = searchParams.get('token');

  const { register, handleSubmit, formState, watch } = useForm<FormFields>();
  const values = watch();

  const [verifyToken, verifyTokenMutation] = useVerifyResetToken();

  const [confirmPassword, confirmPasswordMutation] = useConfirmPassword();
  const confirmPasswordErrorMessage = () => {
    const apiError = getErrorMessage(confirmPasswordMutation.error);
    const errors: string[] = [];

    if (apiError instanceof ApiError) {
      if (apiError.code === 400) {
        apiError.errors?.forEach((responseError: { error: string }) => {
          const key = responseError.error.split(':').filter(Boolean)[0];
          const message = responseError.error.split(':').filter(Boolean)[1];
          if (key === 'password') {
            errors.push(message);
          }
        });
      }
    }

    return errors[0] ? errors[0].split(',') : ['Invalid Password, please try again.'];
  };

  const { errors } = formState;
  const onSubmit = handleSubmit(async (data) => {
    await confirmPassword(
      {
        token: token as string,
        password: data.password
      },
      {
        onFailure: ({ error }) => {
          console.log('mutation error', error);
        },
      }
    );
  });

  const registerBaseWeb = (
    fieldName: keyof FormFields,
    options?: RegisterOptions<any, keyof FormFields>,
  ) => {
    const { onChange, onBlur, name, ref, disabled } = register(fieldName, {
      ...options,
    });
    return { onChange, onBlur, name, inputRef: ref, disabled };
  };

  const verifyResetToken = useCallback(async () => {
    await verifyToken(token as string, {
      onFailure: ({ error }) => {
        console.log('mutation error', error);
      },
    });
  }, [token, verifyToken]);

  useEffect(() => {
    if (token) {
      verifyResetToken();
    } else {
      navigate('/');
    }
  }, [navigate, token, verifyResetToken]);

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.sizing.scale300,
        paddingRight: theme.sizing.scale300,
        width: '100%',
        height: '100%',
        maxWidth: `${1024 - 28 * 2}px`,
        marginLeft: 'auto',
        marginRight: 'auto',
      })}
    >
      <div
        className={css({
          width: '100%',
        })}
      >
        <Block
          as="img"
          src={Logo}
          paddingLeft={[0, 0, theme.sizing.scale1600]}
          paddingRight={[0, 0, theme.sizing.scale1600]}
        />
        <Block
          flex={1}
          display="flex"
          marginTop={theme.sizing.scale1200}
          flexDirection={['column', 'column', 'row']}
          paddingLeft={[0, 0, theme.sizing.scale1600]}
          paddingRight={[0, 0, theme.sizing.scale1600]}
        >
          <FlexGrid
            flexGridColumnCount={1}
            overrides={{
              Block: {
                style: {
                  flex: 1,
                  height: '0',
                }
              }
            }}
            marginBottom={[theme.sizing.scale1200, theme.sizing.scale1200, 0, 0]}
          >
            <HeadingMedium
              className={css({
                fontWeight: 700
              })}
              as={FlexGridItem}
            >
              Create a new password
            </HeadingMedium>
            <HeadingXSmall
              marginTop={theme.sizing.scale550}
              className={css({ fontWeight: 300 })}
              as={FlexGridItem}
            >
              Create a new and secure password.
            </HeadingXSmall>
          </FlexGrid>
          {verifyTokenMutation.status === 'running' && (
            <>
              Verifying...
              <Spinner
                $style={() => ({
                  marginTop: theme.sizing.scale400,
                  marginLeft: theme.sizing.scale400,
                })}
              />
            </>
          )}
          {verifyTokenMutation.status === 'success' && (
            <div
              className={css({
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              })}
            >
              {confirmPasswordMutation.status !== 'success' && (
                <form
                  action=""
                  onSubmit={onSubmit}
                  className={css({
                    display: 'flex',
                    flexDirection: 'column',
                  })}
                >
                  <FormControl
                    label="New Password"
                    error={errors.password?.message}
                  >
                    <Input
                      type="password"
                      {...registerBaseWeb('password', { required: 'Enter password' })}
                    />
                  </FormControl>
                  {confirmPasswordMutation.status === 'failure' && (
                    confirmPasswordErrorMessage().map((errorMessage: string) => (
                      <StyledCaption
                        $style={{
                          color: theme.colors.negative,
                          marginTop: theme.sizing.scale300,
                          paddingLeft: theme.sizing.scale300,
                        }}
                      >
                        {`* ${errorMessage}`}
                      </StyledCaption>
                    ))
                  )}
                  <FormControl label="Confirm New Password" error={errors.confirmPassword?.message}>
                    <Input
                      type="password"
                      {...registerBaseWeb('confirmPassword',
                        {
                          required: 'Confirm password',
                          validate: value =>
                            value === values.password || "The passwords do not match"
                        }
                      )}
                    />
                  </FormControl>
                  <Button
                    isLoading={
                      confirmPasswordMutation.status === 'running' || formState.isSubmitting
                    }
                    type="submit"
                    shape={SHAPE.pill}
                    $style={{
                      width: '100%',
                      maxWidth: '12rem',
                      alignSelf: 'center',
                      marginTop: theme.sizing.scale400,
                    }}
                  >
                    Save new password
                  </Button>
                </form>
              )}
              {confirmPasswordMutation.status === 'success' && (
                <FlexGrid
                  flexGridColumnCount={1}
                  flexGridRowGap={theme.sizing.scale600}
                >
                  <HeadingXSmall
                    marginTop={theme.sizing.scale550}
                    className={css({
                      fontWeight: 300,
                      color: theme.colors.primary,
                    })}
                    as={FlexGridItem}
                  >
                    Your password has been successfully changed!
                  </HeadingXSmall>
                  <LinkButton
                    to="/"
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    $style={{
                      marginTop: theme.sizing.scale300,
                      marginLeft: `-${theme.sizing.scale300}`,
                    }}
                  >
                    Return to home
                  </LinkButton>
                </FlexGrid>
              )}
            </div>
          )}
          {verifyTokenMutation.status === 'failure' && (
            <FlexGrid
              flexGridColumnCount={1}
              flexGridRowGap={theme.sizing.scale600}
            >
              <HeadingXSmall
                className={css({ fontWeight: 300 })}
                as={FlexGridItem}
              >
                Unable to verify token. Please try again.
              </HeadingXSmall>
              <LinkButton
                to="/"
                size={SIZE.mini}
                kind={KIND.tertiary}
                $style={{
                  marginTop: theme.sizing.scale300,
                  marginLeft: `-${theme.sizing.scale300}`,
                }}
              >
                <LabelSmall
                  color="primary"
                  className={css({ textAlign: 'center' })}
                >
                  Return to home
                </LabelSmall>
              </LinkButton>
            </FlexGrid>
          )}
        </Block >
      </div>
    </div>
  );
}
