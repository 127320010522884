import React, { useState } from 'react';

import { RegisterOptions, useForm } from 'react-hook-form';
import { Input } from 'baseui/input';
import { Block } from 'baseui/block';
import { colors } from 'baseui/tokens';
import { KIND, SHAPE, SIZE } from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { HeadingSmall, HeadingXSmall, LabelMedium, LabelXSmall } from 'baseui/typography';

import { useStyletron } from 'theme';
import { LinkButton } from 'components/Button';
import { Button, FormControl } from 'components';
import ApiError from '../../api/errors';
import { useResetPassword } from '../../api/auth';
import Logo from '../../assets/images/scheduler-ovp-logo.png';

type FormFields = {
  email: string;
};

export default function ForgotPassword() {
  const [css, theme] = useStyletron();

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const { register, handleSubmit, formState, setError } = useForm<FormFields>();

  const [resetPassword, resetPasswordMutation] = useResetPassword();
  const errorMessage = () => {
    const apiError = resetPasswordMutation.error as ApiError;
    const errors: string[] = [];

    if (apiError.errors) {
      apiError.errors.forEach((responseError: { error: string }) => {
        const key = responseError.error.split(':').filter(Boolean)[0];
        const message = responseError.error.split(':').filter(Boolean)[1];
        if (key === 'email') {
          errors.push(message);
        }
      });
      console.error(`${apiError.code} ${apiError.message}`, errors.join('\n'));
    }

    return errors[0] ?? 'Login failed,try again';
  };

  const { errors } = formState;
  const onSubmit = handleSubmit(async (data) => {
    await resetPassword(data.email,
      {
        onSuccess: () => {
          setEmailSent(true);
        },
        onFailure: ({ error }) => {
          if (error instanceof ApiError) {
            if (error.code === 401) {
              setError('email', { message: 'Invalid Email' });
            }
          }
          console.log('mutation error', error);
        },
      }
    );
  });

  const registerBaseWeb = (
    fieldName: keyof FormFields,
    options?: RegisterOptions<any, keyof FormFields>,
  ) => {
    const { onChange, onBlur, name, ref, disabled } = register(fieldName, {
      ...options,
    });
    return { onChange, onBlur, name, inputRef: ref, disabled };
  };

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.sizing.scale300,
        paddingRight: theme.sizing.scale300,
        width: '100%',
        height: '100%',
        maxWidth: `${1024 - 28 * 2}px`,
        marginLeft: 'auto',
        marginRight: 'auto',
      })}
    >
      <div
        className={css({
          width: '100%',
        })}
      >
        <img
          className={css({
            height: theme.sizing.scale1200,
          })}
          src={Logo}
          alt="scheduler"
        />
        <Block
          marginTop={theme.sizing.scale1200}
          display="flex"
          flex={1}
          flexDirection={['column', 'column', 'row']}
        >
          <FlexGrid
            className={css({ flex: 1 })}
            flexGridColumnCount={1}
            paddingRight={[0, 0, theme.sizing.scale1200, theme.sizing.scale1200]}
            marginBottom={[theme.sizing.scale1200, theme.sizing.scale1200, 0, 0]}
          >
            <HeadingSmall
              className={css({ fontWeight: 700 })}
              as={FlexGridItem}
            >
              Forgot password
            </HeadingSmall>
            <HeadingXSmall
              as={FlexGridItem}
              marginTop={theme.sizing.scale550}
            >
              Reset your password with the email address connected to your Scheduler account
            </HeadingXSmall>
          </FlexGrid>
          <div
            className={css({
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <form
              action=""
              onSubmit={onSubmit}
              className={css({
                display: 'flex',
                flexDirection: 'column',
              })}
            >
              <FormControl
                label="Email Address"
                error={errors.email?.message}
                overrides={{
                  Label: {
                    style: {
                      color: colors.gray400
                    }
                  }
                }}
              >
                <Input
                  type="email"
                  {...registerBaseWeb('email', {
                    required: 'Enter email',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Enter valid email',
                    },
                  })}
                />
              </FormControl>
              {emailSent && (
                <FlexGrid
                  flexGridColumnCount={1}
                  flexGridRowGap={theme.sizing.scale600}
                >
                  <LabelXSmall
                    className={css({
                      color: theme.colors.primary,
                    })}
                    as={FlexGridItem}
                  >
                    We sent you a Password Reset Link on your email. Please check your inbox and click on the link.
                  </LabelXSmall>
                  <LinkButton
                    to="/"
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    $style={{
                      marginTop: theme.sizing.scale300,
                      marginLeft: `-${theme.sizing.scale300}`,
                    }}
                  >
                    Return to home
                  </LinkButton>
                </FlexGrid>
              )}
              <Button
                isLoading={
                  resetPasswordMutation.status === 'running' || formState.isSubmitting
                }
                type="submit"
                shape={SHAPE.pill}
                $style={{
                  width: '100%',
                  maxWidth: '12rem',
                  alignSelf: 'flex-end',
                  marginTop: theme.sizing.scale400,
                }}
              >
                Reset Password
              </Button>
              {resetPasswordMutation.status === 'failure' && (
                <LabelMedium
                  className={css({
                    marginTop: theme.sizing.scale300,
                    textAlign: 'center',
                    color: theme.colors.negative,
                  })}
                >
                  {errorMessage()}
                </LabelMedium>
              )}
            </form>
          </div>
        </Block>
      </div>
    </div >
  );
}
