/* eslint-disable react/require-default-props */
import { Card } from 'baseui/card';
import { Check } from 'baseui/icon';
import { Input } from 'baseui/input';
import { HeadingSmall, LabelMedium } from 'baseui/typography';
import { format } from 'date-fns';
import { chunk } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FormControlLabel } from 'components/FormControl';

import { FormController } from 'components';

import { useStyletron } from 'theme';

import { BookingFormFields, useBookingFormStore } from './store';
import {
  BookingFormAction,
  BookingReviewFormFields,
  TBookingForm,
} from './types';
import { fetchData } from './apiOption';
import { LocationOption } from './EventInformationForm';

export default function BookingCreate(props: TBookingForm<BookingFormFields>) {
  const { onSubmit: onSubmitHandler, id: formId, action } = props;
  const [css, theme] = useStyletron();
  const { control, handleSubmit } = useForm<BookingReviewFormFields>();
  const formValues = useBookingFormStore((state) => {
    const { setValue: set, ...values } = state;
    return values;
  }) as BookingFormFields;

  const onSubmit = handleSubmit(({ password }) => {
    onSubmitHandler({ ...formValues, password });
  });

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      })}
    >
      <div>
        <HeadingSmall as="h2" $style={{ marginBottom: theme.sizing.scale400 }}>
          Review booking information
        </HeadingSmall>
        <Card>
          <BookingOverview />
        </Card>
      </div>
      <form
        id={formId}
        onSubmit={onSubmit}
        className={css({ marginTop: theme.sizing.scale600 })}
      >
        {action !== BookingFormAction.EDIT && (
          <FormController
            control={control}
            name="password"
            label={
              <FormControlLabel required>Account Password</FormControlLabel>
            }
            rules={{
              required: 'This field is required',
            }}
            defaultValue=""
          >
            {({ field }) => (
              <Input {...field} id={field.name} type="password" />
            )}
          </FormController>
        )}
      </form>
    </div>
  );
}

function BookingOverview() {
  const [css, theme] = useStyletron();
  const values = useBookingFormStore();
  const isCountryPH = values.country === '176'
  const [countryName, setCountryName] = useState('Philippines')
  const [regionName, setRegionName] = useState('')
  const [provinceName, setProvinceName] = useState('')
  const [cityName, setCityName] = useState('')

  const getLocationValues = async () => {
    if (isCountryPH) {
      if (values.region) {
        const regions = await fetchData('region', 'region')
        setRegionName(regions.find((region: LocationOption) => region.id === Number(values.region))?.label)
      }
      if (values.region) {
        const provinces = await fetchData(`province/${values.region}`, 'province')
        setProvinceName(provinces.find((province: LocationOption) => province.id === Number(values.province))?.label)
      }
      if (values.city) {
        const cities = await fetchData(`city-municipality/${values.province}`, 'city')
        setCityName(cities.find((city: LocationOption) => city.id === Number(values.city))?.label)
      }
    } else {
      const countries = await fetchData('country', 'country')
      setCountryName(countries.find((country: LocationOption) => country.id === Number(values.country))?.label)
    }
  }

  useEffect(() => {
    getLocationValues()
  }, [values])

  return (
    <dl
      className={css({
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        [theme.mediaQuery.medium]: {
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        },
        columnGap: theme.sizing.scale700,
        rowGap: theme.sizing.scale700,
      })}
    >
      <LabelMedium $style={{ gridColumn: '1/-1', fontWeight: 600 }}>
        Basic Information
      </LabelMedium>
      <DetailItem
        title="Event Type"
        className={css({ gridColumn: '1/3' })}
        description={values.type?.label}
      />
      <DetailItem
        title="Date"
        description={values?.date && format(values.date, 'MMMM dd, yyyy')}
      />

      <DetailItem
        title="Time"
        className={css({ gridColumn: '1/3' })}
        description={
          values.timeslot?.start &&
          `${format(values.timeslot.start, 'hh:mm aa')} - ${format(
            values.timeslot.end,
            'hh:mm aa',
          )}`
        }
      />
      <DetailItem
        title="Event Title"
        className={css({ gridColumn: '1/-1' })}
        description={values.agenda}
      />
      <DetailItem
        className={css({ gridColumn: '1/-1' })}
        title="Event Description"
        description={values.description}
      />

      <DetailItem
        className={css({ gridColumn: '1/-1' })}
        title="Files"
        description={
          <ul>
            {Array.from(values?.files ?? []).map((file) => (
              <li>{file.name}</li>
            ))}
          </ul>
        }
      />
      <LabelMedium $style={{ gridColumn: '1/-1', fontWeight: 700 }}>
        Event Information
      </LabelMedium>
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Role"
        description={values.role && values.role.label}
      />
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Dress Code"
        isShown={!!values.dressCode}
        description={values?.dressCode?.label}
      />
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Meeting Link"
        isShown={!!values.meetingLink}
        description={values?.meetingLink}
      />
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Location Name"
        isShown={!!values.location}
        description={values.location && values.location.name}
      />
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Location Address"
        isShown={!!values.location}
        description={values.location && values.location.address}
      />
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Country"
        isShown={!!countryName}
        description={countryName}
      />
      <DetailItem
        title="Region"
        isShown={!!regionName}
        description={regionName}
      />
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Province"
        isShown={!!provinceName}
        description={provinceName}
      />
      <DetailItem
        title="City"
        isShown={!!cityName}
        description={cityName}
      />
      <DetailItem
        className={css({ gridColumn: '1/3' })}
        title="Number of Participants"
        description={values.particiapantsCount}
      />
      <DetailItem
        className={css({
          gridColumn: '1/-1',
        })}
        title="Expected Guests"
        description={
          <ul
            className={css({
              display: 'grid',
              columnGap: theme.sizing.scale700,
              rowGap: theme.sizing.scale700,
              [theme.mediaQuery.large]: {
                gridTemplateColumns: `repeat(2,1fr)`,
              },
            })}
          >
            {chunk(
              values.guests,
              Math.round(values.guests?.length ? values.guests.length / 2 : 0),
            )?.map((guestGroup) => (
              <li>
                <ul
                  className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.sizing.scale600,
                  })}
                >
                  {guestGroup
                    .filter((guest) => guest.id !== 0)
                    .map((guest) => (
                      <li className={css({ display: 'flex' })}>
                        <span
                          className={css({
                            alignSelf: 'flex-start',
                            paddingRight: theme.sizing.scale200,
                          })}
                        >
                          <Check />
                        </span>
                        <span>
                          {guest.name === 'Other'
                            ? values.otherSector
                            : guest.name}
                        </span>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        }
      />
      <DetailItem
        className={css({
          gridColumn: '1/-1',
        })}
        title="Type of Audience"
        description={
          <ul
            className={css({
              display: 'grid',
              columnGap: theme.sizing.scale300,
              rowGap: theme.sizing.scale600,
              [theme.mediaQuery.large]: {
                gridTemplateColumns: `repeat(2,1fr)`,
              },
            })}
          >
            {values.audienceTypes
              ?.filter((type) => type.id !== 0)
              .map((type) => (
                <li className={css({ display: 'flex' })}>
                  <span
                    className={css({
                      alignSelf: 'flex-start',
                      paddingRight: theme.sizing.scale200,
                    })}
                  >
                    <Check />
                  </span>
                  <span>
                    {type.name === 'Other'
                      ? values.otherAudienceType
                      : type.name}
                  </span>
                </li>
              ))}
          </ul>
        }
      />
    </dl>
  );
}

type DetailItemProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  className?: string;
  isShown?: boolean;
};

function DetailItem({
  title,
  description,
  className = '',
  isShown = true,
}: DetailItemProps) {
  const [css, theme] = useStyletron();
  if (!isShown) return null;
  return (
    <div className={className}>
      <dt
        className={css({
          ...theme.typography.LabelSmall,
          color: theme.colorTokens.gray500,
        })}
      >
        {title}
      </dt>
      <dd
        className={css({
          ...theme.typography.LabelMedium,
          marginTop: theme.sizing.scale200,
        })}
      >
        {description}
      </dd>
    </div>
  );
}

// FIXME: Made for demo only
function ClampedText({ children }: { children: React.ReactNode }) {
  const [css, theme] = useStyletron();
  return (
    <div
      style={{
        overflow: 'hidden',
        display: '-webkit-box',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
      }}
    >
      {children}
    </div>
  );
}
