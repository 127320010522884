/* eslint-disable react/require-default-props */
import { KIND, SIZE } from 'baseui/button';
import { StatefulSelect, StatefulSelectProps } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import { LabelMedium } from 'baseui/typography';
import React from 'react';

import Button from '../../Button';

type OwnSelectProps = {
  value?: string;
};

type Option = {
  label: string;
  value: string;
};


type DropDownSelectProps = Omit<StatefulSelectProps, 'value'> & OwnSelectProps & {
  options?: Option[] | null | undefined;
  onChange?: (value: string | null | undefined) => void; // Add onChange prop
};

export default function DropDownSelect(props: DropDownSelectProps) {
  const { value, options, isLoading, onChange, ...selectProps } = props;


  if (isLoading) {
    return <div
    style={{
      borderRadius: '8px',
      overflow: 'hidden',
      height: '48px',
    }}>
            <Skeleton width="100%" height="48px" animation />
          </div>
  }

  if (!options || options.length === 0) {
    return <div>No options available</div>;
  }

  const selected = options.find((item) => item.value === value) ?? null;

  return (
    <StatefulSelect
      initialState={
        selected
          ? {
              value: [selected],
            }
          : undefined
      }
      clearable={false}
      searchable={false}
      placeholder="Select Option"
      {...selectProps}
      options={options}
      labelKey="label"
      valueKey="value"
      maxDropdownHeight="300px"
      onChange={(selectedOptions) => {
        const selectedValue = selectedOptions;
        if (onChange) {
          onChange(selectedValue);
        }
      }}
    />
  );
}

