/* eslint-disable react/no-unused-prop-types */

/* eslint-disable react/require-default-props */
import { mergeOverrides } from 'baseui';
import {
  FormControl as BWFormControl,
  FormControlProps as BWFormControlProps,
  FormControlOverrides,
  StyledLabel,
} from 'baseui/form-control';
import { Overrides } from 'baseui/overrides';
import React from 'react';

import { useStyletron } from 'theme';

export type FormControlProps = BWFormControlProps;
export default function FormControl(props: FormControlProps) {
  const [css, theme] = useStyletron();
  const { overrides, ...propsWithoutOverrides } = props;
  return (
    <BWFormControl
      overrides={mergeOverrides(
        {
          Caption: {
            style: {
              marginBottom: theme.sizing.scale100,
            },
          },
          ControlContainer: {
            style: {
              marginBottom: theme.sizing.scale900,
            },
          },
        },
        overrides as Overrides<FormControlOverrides>,
      )}
      {...propsWithoutOverrides}
    />
  );
}

type FormControlLabelProps = {
  required?: boolean;
  description?: React.ReactNode;
  children: React.ReactNode;
};

export function FormControlLabel(props: FormControlLabelProps) {
  const { required, children, description } = props;
  const [css, theme] = useStyletron();
  return (
    <>
      <StyledLabel $as="span" $style={{ display: 'inline' }}>
        {children}
        {required && (
          <span className={css({ color: theme.colors.negative })}>*</span>
        )}
      </StyledLabel>
      {description && (
        <StyledLabel
          $as="span"
          $style={{
            color: theme.colorTokens.gray500,
            ...theme.typography.ParagraphXSmall,
          }}
        >
          {description}
        </StyledLabel>
      )}
    </>
  );
}
