import React from 'react';

import { Skeleton } from 'baseui/skeleton';
import { KIND, SIZE } from 'baseui/button';
import { LabelMedium } from 'baseui/typography';
import { StatefulSelect, StatefulSelectProps } from 'baseui/select';

import { useBookingOptions } from 'services/queries/bookings';

import { useStyletron } from 'theme';

import Button from '../../Button';

type OwnSelectProps = {
  // eslint-disable-next-line react/require-default-props
  value?: number;
};

export default function MeeetingTypeSelect(
  props: Omit<StatefulSelectProps, 'value'> & OwnSelectProps,
) {
  const [css, theme] = useStyletron();
  const bookingOptionsQuery = useBookingOptions();
  const { value, ...selectProps } = props;

  if (bookingOptionsQuery.error) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.sizing.scale400,
          ...theme.borders.border200,
          borderRadius: theme.sizing.scale300,
        })}
      >
        <LabelMedium color={theme.colors.negative}>
          Failed to load meeting types
        </LabelMedium>
        <Button
          type="button"
          size={SIZE.mini}
          kind={KIND.tertiary}
          onClick={() => bookingOptionsQuery.mutate()}
        >
          Try again
        </Button>
      </div>
    );
  }

  if (!bookingOptionsQuery?.bookingOptions?.meetingTypes) {
    return (
      <Skeleton
        animation
        height={theme.sizing.scale1200}
        overrides={{
          Root: {
            style: { borderRadius: theme.sizing.scale300 },
          },
        }}
      />
    );
  }
  const types =
    bookingOptionsQuery.bookingOptions.meetingTypes
      .sort((a, b) => a.name.localeCompare(
        b.name,
        undefined,
        {
          numeric: true,
          sensitivity: 'base',
        },
      ))
      .map((meetingType) => ({
        label: meetingType.name,
        value: meetingType.id,
      })).sort() ?? [];
  const selectedType = types.find((meetingType) => meetingType.value === value);

  return (
    <StatefulSelect
      initialState={
        selectedType
          ? {
            value: [selectedType],
          }
          : undefined
      }
      clearable={false}
      searchable={false}
      {...selectProps}
      options={types}
      labelKey="label"
      valueKey="value"
      placeholder="Select type"
      maxDropdownHeight="300px"
    />
  );
}
