import { Options } from 'use-mutation';

import { getTokens } from 'utils';
import useMutation from 'utils/hooks/use-mutation';

import { get, post, sendForm } from '../services/api';
import {
  LoginCreds,
  PasswordCreds,
  SignupCreds,
  UserTokens,
} from '../types/auth';

interface LoginResponse {
  scope: string;
  expires_in: number;
  token_type: string;
  access_token: string;
  refresh_token: string;
  user: {
    id: number;
    name: string;
    last_name: string;
    first_name: string;
    appointment_admin: boolean;
  };
}

const clientId = process.env.REACT_APP_API_CLIENT_ID as string;
const clientSecret = process.env.REACT_APP_API_CLIENT_SECRET as string;

export async function register(credentials: SignupCreds): Promise<void> {
  await post('/signup', {
    first_name: credentials.firstName,
    last_name: credentials.lastName,
    username: credentials.email,
    email: credentials.email,
    password: credentials.password,
    primary_number: credentials.contactNumber,
    request_organization: credentials.requestingOrg,
  });
}

export function useRegister(options?: Options<SignupCreds, any, any>) {
  return useMutation(register, { ...options });
}
export async function login(
  credentials: LoginCreds,
): Promise<UserTokens & { isAdmin: boolean }> {
  const payload = new FormData();
  payload.append('username', credentials.email);
  payload.append('password', credentials.password);
  payload.append('grant_type', 'password');
  payload.append('client_id', clientId);
  payload.append('client_secret', clientSecret);

  const {
    user: userDetails,
    token_type: tokenType,
    access_token: accessToken,
    refresh_token: refreshToken,
  } = await sendForm<LoginResponse>('/token/', 'POST', payload);

  return {
    tokenType,
    accessToken,
    refreshToken,
    isAdmin: userDetails.appointment_admin,
  };
}

export function useLogin(options?: Options<LoginCreds, any, any>) {
  return useMutation(login, { ...options });
}

export async function refresh(refreshToken: string): Promise<UserTokens> {
  const payload = new FormData();
  const grantType = 'refresh_token';
  const tokens = getTokens();
  payload.append(grantType, refreshToken);
  payload.append('grant_type', grantType);
  payload.append('client_id', clientId);
  payload.append('client_secret', clientSecret);

  const {
    token_type: tokenType,
    access_token: accessToken,
    refresh_token: resRefreshToken,
  } = await sendForm<LoginResponse>('/token/', 'POST', payload);

  return {
    tokenType,
    accessToken,
    refreshToken: resRefreshToken,
    isAdmin: tokens?.isAdmin || false,
  };
}

export async function verifyEmail(token: string): Promise<{ detail: string }> {
  const response = await get(`/signup/verify-email?token=${token}`);

  return {
    detail: response.detail,
  };
}

export function useVerify(options?: Options<string, any, any>) {
  return useMutation(verifyEmail, { ...options });
}

export async function resetPassword(email: string): Promise<void> {
  return post('/password-reset', { email });
}

export function useResetPassword(options?: Options<string, any, any>) {
  return useMutation(resetPassword, { ...options });
}

export async function verifyResetToken(token: string): Promise<void> {
  return post('/password-reset/validate_token', { token });
}

export function useVerifyResetToken(options?: Options<string, any, any>) {
  return useMutation(verifyResetToken, { ...options });
}

export async function confirmNewPassword(
  passwordCreds: PasswordCreds,
): Promise<void> {
  return post('/password-reset/confirm', passwordCreds);
}

export function useConfirmPassword(options?: Options<PasswordCreds, any, any>) {
  return useMutation(confirmNewPassword, { ...options });
}
