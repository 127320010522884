import { HourglassBottom, Schedule, ViewAgenda } from '@mui/icons-material';
import { useStyletron } from 'baseui';
import { KIND as ButtonKind, SHAPE } from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Alert } from 'baseui/icon';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from 'baseui/modal';
import { Skeleton } from 'baseui/skeleton';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { Button } from 'components';
import { addHours, format, formatDistanceStrict } from 'date-fns';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeleteBooking } from 'services/mutations/bookings';
import { useBooking } from 'services/queries/bookings';

export default function CancelBooking() {
  const navigate = useNavigate();
  const { bookingId = '' } = useParams();
  const [css, theme] = useStyletron();

  const bookingQuery = useBooking(bookingId);
  const [mutate, deleteBookingMutation] = useDeleteBooking();

  const onDelete = async () => {
    if (bookingQuery.booking) {
      await mutate(bookingQuery.booking.bookingToken, {
        onSuccess: async () => {
          await bookingQuery.mutate();
          navigate('/bookings');
        },
        onFailure: ({ error }) => {
          console.log('mutation error', error);
        },
      });
    }
  };

  return (
    <Modal
      onClose={() => navigate('..')}
      closeable={!(deleteBookingMutation.status === 'running')}
      isOpen
      animate
      autoFocus
      overrides={{
        DialogContainer: {
          style: {
            alignItems: 'flex-end',
            [theme.mediaQuery.large]: {
              alignItems: 'center',
            },
          },
        },
        Dialog: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            [theme.mediaQuery.large]: {
              borderBottomLeftRadius: theme.sizing.scale600,
              borderBottomRightRadius: theme.sizing.scale600,
            },
          },
        },
      }}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Are you sure you want to cancel this booking?</ModalHeader>
      <ModalBody>
        {deleteBookingMutation.status === 'failure' && (
          <div
            className={css({
              color: theme.colors.negative,
              display: 'flex',
              alignItems: 'center',
              ...theme.borders.border200,
              padding: theme.sizing.scale500,
              borderColor: theme.colors.negative,
              borderRadius: theme.sizing.scale300,
              marginBottom: theme.sizing.scale500,
            })}
          >
            <Alert size={theme.sizing.scale800} />
            <LabelMedium marginLeft={theme.sizing.scale100}>
              Failed to cancel booking, please try again.
            </LabelMedium>
          </div>
        )}
        {bookingQuery.booking ? (
          <>
            <LabelLarge
              color="primary"
              className={css({
                fontWeight: 'bold',
              })}
            >
              {format(bookingQuery.booking.date, 'MMMM dd, yyyy')}
            </LabelLarge>
            <FlexGrid as="dl" flexGridRowGap="scale200" marginTop="scale600">
              <FlexGridItem display="flex" alignItems="start">
                <dt>
                  {/* <Block sx={visuallyHidden} aria-hidden>Agenda</Block> */}
                  <ViewAgenda sx={{ fontSize: 16, color: '#676767' }} />
                </dt>
                <LabelMedium
                  color="contentTertiary"
                  as="dd"
                  flex="1"
                  marginLeft="scale200"
                >
                  {bookingQuery.booking.agenda}
                </LabelMedium>
              </FlexGridItem>

              <FlexGridItem display="flex" alignItems="center">
                <dt>
                  {/* <Block sx={visuallyHidden} aria-hidden>Duration</Block> */}
                  <HourglassBottom sx={{ fontSize: 16, color: '#676767' }} />
                </dt>
                <LabelMedium
                  color="contentTertiary"
                  as="dd"
                  flex="1"
                  marginLeft="scale200"
                >
                  {formatDistanceStrict(
                    bookingQuery.booking.date,
                    addHours(
                      bookingQuery.booking.date,
                      bookingQuery.booking.duration,
                    ),
                  )}
                </LabelMedium>
              </FlexGridItem>
              <FlexGridItem display="flex" alignItems="center">
                <dt>
                  {/* <Block sx={visuallyHidden} aria-hidden>Time</Block> */}
                  <Schedule sx={{ fontSize: 16, color: '#676767' }} />
                </dt>
                <LabelMedium
                  color="contentTertiary"
                  as="dd"
                  flex="1"
                  marginLeft="scale200"
                >
                  {format(bookingQuery.booking.date, 'hh:mm aa')}
                </LabelMedium>
              </FlexGridItem>
            </FlexGrid>
          </>
        ) : (
          <Skeleton rows={5} />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalButton
          shape={SHAPE.pill}
          kind={ButtonKind.tertiary}
          onClick={() => navigate(`/bookings/${bookingId}`)}
          disabled={deleteBookingMutation.status === 'running'}
          overrides={{
            BaseButton: {
              component: Button,
            },
          }}
        >
          No
        </ModalButton>
        <ModalButton
          shape={SHAPE.pill}
          onClick={onDelete}
          disabled={bookingQuery.isLoading}
          isLoading={deleteBookingMutation.status === 'running'}
          overrides={{
            BaseButton: {
              component: Button,
              style: {
                width: '7rem',
              },
            },
          }}
        >
          Yes
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
