/* eslint-disable react/no-unstable-nested-components */
import React, { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Skeleton } from 'baseui/skeleton';
import { KIND, SIZE } from 'baseui/button';
import { HeadingSmall, HeadingXSmall, ParagraphSmall } from 'baseui/typography';

import { Button } from 'components';
import { useStyletron } from 'theme';
import { LinkButton } from 'components/Button';
import useCurrentUser from 'services/queries/users';
import BookingFilterNav from 'features/BookingFilterNav';
import BookingList from './BookingList';

export default function BookingSidebar() {
  const [css, theme] = useStyletron();
  const { user } = useCurrentUser();

  return (
    <aside
      className={css({
        width: '22rem',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        flexShrink: 0,
        boxShadow: theme.lighting.shadow500,
        paddingTop: theme.sizing.scale200,
        paddingBottom: theme.sizing.scale200,
      })}
    >
      <HeadingSmall
        paddingLeft="scale700"
        paddingRight="scale700"
        paddingTop="scale800"
        paddingBottom="scale300"
      >
        Appointments
      </HeadingSmall>
      <div
        className={css({
          paddingLeft: theme.sizing.scale700,
          paddingRight: theme.sizing.scale700,
        })}
      >
        <BookingFilterNav />
      </div>
      <ErrorBoundary
        FallbackComponent={({ resetErrorBoundary }) => (
          <div
            role="alert"
            className={css({
              padding: theme.sizing.scale700,
            })}
          >
            <HeadingXSmall>Something went wrong</HeadingXSmall>
            <ParagraphSmall color={theme.colors.contentTertiary}>
              We can&apos;t get your bookings right now.
            </ParagraphSmall>
            <ParagraphSmall color={theme.colors.contentTertiary}>
              {!user?.status && 'Your account is not yet fully verified.'}
            </ParagraphSmall>
            <Button
              onClick={resetErrorBoundary}
              size={SIZE.compact}
              $style={{ marginTop: theme.sizing.scale300 }}
            >
              Try Again
            </Button>
          </div>
        )}
      >
        <Suspense
          fallback={
            <div
              className={css({
                padding: theme.sizing.scale700,
              })}
            >
              <Skeleton
                rows={4}
                overrides={{
                  Row: {
                    style: {
                      height: '150px',
                      marginBottom: '15px',
                      borderRadius: theme.sizing.scale400,
                    },
                  },
                }}
                animation
              />
            </div>
          }
        >
          <BookingList />
        </Suspense>
      </ErrorBoundary>
      {user?.status && (
        <div
          className={css({
            paddingTop: theme.sizing.scale500,
            paddingBottom: theme.sizing.scale500,
            paddingLeft: theme.sizing.scale700,
            paddingRight: theme.sizing.scale700,
          })}
        >
          <LinkButton
            $style={{ width: '100%' }}
            to="/book-appointment"
            size={SIZE.large}
            kind={KIND.primary}
          >
            Create a Booking
          </LinkButton>
        </div>
      )}
    </aside>
  );
}
