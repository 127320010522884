import React, { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Plus } from 'baseui/icon';
import { Skeleton } from 'baseui/skeleton';
import { SHAPE, SIZE } from 'baseui/button';
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography';


import { useStyletron } from 'theme';
import { Button } from 'components';
import useCurrentUser from 'services/queries/users';
import { LinkButton } from 'components/Button';
import BookingFilterNav from 'features/BookingFilterNav';
import BookingList from './features/BookingList';
import BookingsCalendar from './features/BookingsCalendar';

export default function MobileDashboardHome() {
  const [css, theme] = useStyletron();
  const { user } = useCurrentUser();

  return (
    <div
      className={css({
        zIndex: 0,
        position: 'relative',
      })}
    >
      <BookingsCalendar />
      <section className={css({ marginTop: theme.sizing.scale900 })}>
        <div
          className={css({
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.colors.white,
            paddingTop: theme.sizing.scale500,
          })}
        >
          <HeadingXSmall as="h1">Appointments</HeadingXSmall>
          <BookingFilterNav />
        </div>
        <ErrorBoundary
          // eslint-disable-next-line react/no-unstable-nested-components
          FallbackComponent={({ resetErrorBoundary }) => (
            <div
              role="alert"
              className={css({
                padding: theme.sizing.scale700,
              })}
            >
              <HeadingXSmall>Something went wrong</HeadingXSmall>
              <ParagraphSmall color={theme.colors.contentTertiary}>
                We can&apos;t get your bookings right now
              </ParagraphSmall>
              <ParagraphSmall color={theme.colors.contentTertiary}>
                {!user?.status && 'Your account is not yet fully verified.'}
              </ParagraphSmall>
              <Button
                onClick={resetErrorBoundary}
                size={SIZE.compact}
                $style={{ marginTop: theme.sizing.scale300 }}
              >
                Try Again
              </Button>
            </div>
          )}
        >
          <Suspense
            fallback={
              <div
                className={css({
                  padding: theme.sizing.scale700,
                })}
              >
                <Skeleton
                  rows={4}
                  overrides={{
                    Row: {
                      style: {
                        height: '150px',
                        marginBottom: '15px',
                        borderRadius: theme.sizing.scale400,
                      },
                    },
                  }}
                  animation
                />
              </div>
            }
          >
            <div className={css({ marginTop: theme.sizing.scale600 })}>
              <BookingList
                overrides={{
                  style: {
                    padding: 0,
                  },
                }}
              />
            </div>
          </Suspense>
        </ErrorBoundary>
      </section>
      <div
        className={css({
          right: 0,
          position: 'fixed',
          bottom: theme.sizing.scale1200,
          paddingRight: theme.sizing.scale700,
        })}
      >
        <LinkButton
          to="/book-appointment"
          shape={SHAPE.circle}
        >
          <Plus />
        </LinkButton>
      </div>
    </div>
  );
}
