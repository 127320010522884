/* eslint @typescript-eslint/no-explicit-any: 0 */

export type Errors = { error: string }[];

export interface FetchResponse {
  ok: boolean;
  status: number;
  data: any;
  errors?: Errors;
}

export default async function fetch2(
  info: RequestInfo,
  init?: RequestInit,
): Promise<FetchResponse> {
  const response = await fetch(info, init);
  let json;

  try {
    json = await response.json();
  } catch (err) {
    throw new Error('Failed to process server response as JSON');
  }

  return {
    ok: response.ok,
    status: response.status,
    data: json,
  };
}
