import { BaseProvider } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { SWRConfig } from 'swr';

import AuthInitializer from 'components/AuthInitializer';
import Footer from 'components/Footer';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './reset.css';
import theme from './theme';

const engine = new Styletron();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider
        theme={theme}
        overrides={{
          AppContainer: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              overflow: 'hidden',
            },
          },
        }}
      >
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            errorRetryCount: 2,
          }}
        >
          <AuthInitializer>
            <Block flex={1} overflow={['auto', 'auto', 'auto']}>
              <App />
            </Block>
            <Footer />
          </AuthInitializer>
        </SWRConfig>
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
