import { getTokens } from 'utils';

export const fetchData = async (params: string, options: string) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const TEST_URL = 'http://localhost:8000/api/v1';
  const token = getTokens();

  if (!token) {
    console.error('Access token is missing');
    throw new Error('Access token is missing');
  }

  try {
    const response = await fetch(`${API_URL}/${params}`, {
      method: 'GET',
      headers: {
        ...(token && {
          Authorization: `${token.tokenType} ${token.accessToken}`,
        }),
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();

    switch (options) {
      case 'country':
        return Array.isArray(result.countries)
          ? result.countries.map((country: any) => ({
              label: country.name,
              value: country.name,
              code: country.code,
              id: country.id,
            }))
          : [];
      case 'region':
        return Array.isArray(result.regions)
          ? result.regions.map((region: any) => ({
              label: region.name,
              value: region.id,
              id: region.id,
            }))
          : [];
      case 'province':
        return Array.isArray(result.provinces)
          ? result.provinces.map((province: any) => ({
              label: province.name,
              value: province.id,
              id: province.id,
            }))
          : [];
      case 'city':
        return Array.isArray(result.municipalities)
          ? result.municipalities.map((city: any) => ({
              label: city.name,
              value: city.id,
              id: city.id,
            }))
          : [];
      default:
        console.error('Invalid options');
        return [];
    }
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error to be handled by the calling code
  }
};
