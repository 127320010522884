/* eslint @typescript-eslint/no-explicit-any: 0 */

import omit from 'lodash/omit';
import entries from 'lodash/entries';

import ApiError from '../api/errors';
import fetch2, { Errors, FetchResponse } from './fetch2';

type Handler = (
  response: FetchResponse,
  request: Request,
  formData?: FormData,
) => FetchResponse | Promise<FetchResponse>;

const globalHeaders: Record<string, string> = { Accept: 'application/json' };

const API_URL = process.env.REACT_APP_API_URL;

const globalHandlers: Handler[] = [
  // Error handler for non 2xx responses
  (response) => {
    if (!response.ok) {
      const errors: Errors = [];
      entries(response.data).forEach(([key, value]: any) => {
        if (key === 'messages') {
          const { message } = value;
          errors.push({ error: `${key}: ${message}` });
        } else if (typeof value[0] === 'string') {
          errors.push({ error: `${key}: ${value}` });
        } else {
          const keyErrors: string[] = [];
          entries(value).forEach(([_, valueInfo]) => {
            entries(valueInfo as any).forEach(([innerKey, innerVal]) => {
              keyErrors.push(`\t\t${innerKey}: ${innerVal}`);
            });
          });
          errors.push({ error: `${key}: \n${keyErrors.join('\n')}` });
        }
      });

      return {
        ...response,
        errors,
      };
    }

    return response;
  },
];

/**
 * Sets global header values that will be sent to all requests.
 * Will overwrite headers with the same name.
 *
 * @param name header name
 * @param content header content
 */
export function setHeader(name: string, content: string): void {
  globalHeaders[name] = content;
}

/**
 * Deletes a global header with the give name.
 *
 * @param name header name
 */
export function deleteHeader(name: string): void {
  delete globalHeaders[name];
}

/**
 * Adds a global response handler.
 *
 * @param handler handler function
 * @param append will the function be appended to the list of handlers
 */
export function addHandler(handler: Handler, append = true): void {
  if (append) {
    globalHandlers.push(handler);
  } else {
    globalHandlers.unshift(handler);
  }
}

/**
 * A wrapper for `fetch()`. Sends a request to a remote resource,
 * setting common headers and performing any post-processing.
 *
 * Specific request functions `get`, `post`, `patch`, `put` or `delete` are
 * available and are preferred to be used instead of `request`.
 *
 * USAGE:
 * ```js
 *   import { requestWithRetry } from './services/request';
 *   await requestWithRetry('http://www.google.com');
 *
 *   const req = requestWithRetry('http://www.yahoo.com');
 *   req.abort(); // Cancels yahoo request
 * ```
 */
export async function request<T = any>(
  endpoint: string,
  opts: RequestInit = {},
): Promise<T> {
  const url = endpoint.match(/^https?:\/\//) ? endpoint : `${API_URL}${endpoint}`;

  // Process headers if they are present.
  const headers = {
    ...globalHeaders,
    ...opts.headers,
  };

  const apiRequest = new Request(url, {
    headers,
    credentials: 'same-origin',
    ...omit(opts, 'headers'),
  });
  let response = await fetch2(apiRequest);
  let formData;
  entries(headers).forEach(([key, value]) => {
    if (key === 'Content-type' && value === 'multipart/form-data') {
      formData = opts.body as FormData;
    }
  });

  // Pass response to global handlers
  if (!response.ok) {
    // eslint-disable-next-line no-restricted-syntax
    for (const handle of globalHandlers) {
      // eslint-disable-next-line no-await-in-loop
      response = await handle(response, apiRequest, formData);
    }

    if (response.errors) {
      throw new ApiError(
        response.status,
        'API Error',
        response.errors || undefined,
      );
    }
  }
  return response.data;
}

/**
 * Generic function for sending data to a remote resource.
 *
 * Specific request functions `post`, `patch`, `put` are
 * available and are preferred to be used instead of `send`.
 *
 * @param endpoint API location
 * @param method request method
 * @param data request body
 * @param opts optional request options
 * @see post
 * @see patch
 * @see put
 */
export function send<T = any>(
  endpoint: string,
  method: 'POST' | 'PUT' | 'PATCH',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  opts: RequestInit = {},
): Promise<T> {
  return request(endpoint, {
    ...opts,
    method,
    body: JSON.stringify(data),
    headers: { 'Content-type': 'application/json' },
  });
}

/**
 * Sends a POST request to a remote resource.
 *
 * @param endpoint API location
 * @param data data to be POST'ed
 * @param opts optional request options
 */
export async function sendForm<T = any>(
  endpoint: string,
  method: 'POST' | 'PUT' | 'PATCH',
  data: FormData,
  opts: RequestInit = {},
): Promise<T> {
  return request(endpoint, {
    ...opts,
    method,
    body: data,
    // headers: { 'Content-type': 'multipart/form-data' },
  });
}

/**
 * Retrieves a remote resource.
 *
 * @param endpoint API location
 * @param opts optional request options
 */
export function get<T = any>(
  endpoint: string,
  opts: RequestInit = {},
): Promise<T> {
  return request(endpoint, {
    ...opts,
    method: 'GET',
  });
}

/**
 * Sends a POST request to a remote resource.
 *
 * @param endpoint API location
 * @param data data to be POST'ed
 * @param opts optional request options
 */
export async function post<T = any>(
  endpoint: string,
  data: Record<string, any>,
  opts: RequestInit = {},
): Promise<T> {
  /* For www-form-urlencoded requests
     const params = new URLSearchParams();
     entries(data).forEach(([key, value]) => {
       params.set(key, value);
     });

     return request(endpoint, {
       ...opts,
       method: 'POST',
       body: params.toString(),
       headers: { 'Content-type': 'application/x-www-form-urlencoded' },
     });
  */
  return send(endpoint, 'POST', data, opts);
}

/**
 * Sends a PATCH request to a remote resource.
 *
 * @param endpoint API location
 * @param data data to be PATCH'ed
 * @param opts optional request options
 */
export function patch<T = any>(
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  opts: RequestInit = {},
): Promise<T> {
  return send(endpoint, 'PATCH', data, opts);
}

/**
 * Sends a PUT request to a remote resource.
 *
 * @param endpoint API location
 * @param data data to be PUT
 * @param opts optional request options
 */
export function put<T = any>(
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  opts: RequestInit = {},
): Promise<T> {
  return send(endpoint, 'PUT', data, opts);
}

/**
 * Sends a DELETE request to a remote resource.
 *
 * @param endpoint API location
 * @param opts optional request options
 */
export function del<T = any>(
  endpoint: string,
  opts: RequestInit = {},
): Promise<T> {
  return request(endpoint, {
    ...opts,
    method: 'DELETE',
  });
}

export default {
  get,
  post,
  patch,
  put,
  del,
};
