import { Block } from 'baseui/block';
import { LabelXSmall } from 'baseui/typography';
import React from 'react';

import { useStyletron } from 'theme';

import ingenuityLogo from '../assets/images/ingenuity.png';

export default function Footer() {
  const [css, theme] = useStyletron();

  return (
    <footer
      className={css({
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'start',
        position: 'relative',
        paddingTop: theme.sizing.scale300,
        paddingBottom: theme.sizing.scale300,
        paddingRight: theme.sizing.scale400,
        paddingLeft: theme.sizing.scale400,
        borderTop: `1px solid #E2E2E2`,
      })}
    >
      <LabelXSmall marginRight={theme.sizing.scale200}>Powered by</LabelXSmall>
      <a
        href="https://www.ingenuity.ph/?utm_source=db&utm_medium=website"
        target="_blank"
        rel="noreferrer"
      >
        <img style={{ height: '20px' }} src={ingenuityLogo} alt="ingenuity" />
      </a>
    </footer>
  );
}
