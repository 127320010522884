import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';
import {
  createTheme,
  createThemedStyled,
  createThemedUseStyletron,
  createThemedWithStyle,
} from 'baseui';
import type {
  Borders,
  Breakpoints,
  Colors,
  Theme,
  ThemePrimitives,
} from 'baseui/theme';
import { TokenColors, colors } from 'baseui/tokens';
import { useCallback } from 'react';

interface SECONDARY_COLORS {
  secondary: string;
  secondary50: string;
  secondary100: string;
  secondary200: string;
  secondary300: string;
  secondary400: string;
  secondary500: string;
  secondary600: string;
  secondary700: string;
}

const secondaryColors: SECONDARY_COLORS = {
  secondary: '#FF0000',
  secondary50: '#ffe1e1',
  secondary100: '#ffb1b1',
  secondary200: '#ff7f7f',
  secondary300: '#ff4c4c',
  secondary400: '#ff1a1a',
  secondary500: '#e60000',
  secondary600: '#b40000',
  secondary700: '#810000',
};

type CustomTheme = Theme & {
  colors: Theme['colors'] & Record<keyof SECONDARY_COLORS, string>;
  colorTokens: TokenColors;
};

const primitives: Partial<ThemePrimitives> = {
  primary: '#284788',
  primary50: '#e6f0ff',
  primary100: '#c2d1f2',
  primary200: '#9bb3e3',
  primary300: '#7595d6',
  primary400: '#4f76ca',
  primary500: '#365db0',
  primary600: '#29488a',
  primary700: '#1c3364',
  primaryFontFamily: 'Inter, sans-serif',
};

const customThemeWithPrimitives = createTheme(primitives);

const borderOverrides: Partial<Borders> = {
  inputBorderRadius: '8px',
  useRoundedCorners: true,
};

const breakpointsOverrides: Partial<Breakpoints> = {
  large: 1024,
  medium: 768,
  small: 640,
};

const colorOverrides: Partial<Colors> = {
  inputFill: customThemeWithPrimitives.colors.white,
  inputFillActive: customThemeWithPrimitives.colors.white,
  buttonSecondaryFill: secondaryColors.secondary,
  buttonSecondaryText: customThemeWithPrimitives.colors.white,
  buttonSecondaryHover: secondaryColors.secondary300,
  buttonSecondaryActive: secondaryColors.secondary300,
  buttonSecondarySelectedText: customThemeWithPrimitives.colors.white,
  buttonSecondarySelectedFill: secondaryColors.secondary,
  buttonSecondarySpinnerForeground: secondaryColors.secondary,
  buttonSecondarySpinnerBackground: customThemeWithPrimitives.colors.white,
  buttonDisabledFill: colors.gray100,
  buttonDisabledText: colors.gray300,
  buttonDisabledSpinnerBackground: colors.gray100,
  buttonDisabledSpinnerForeground: colors.gray300,
};

const theme = createTheme(primitives, {
  colors: { ...colorOverrides },
  borders: { ...borderOverrides },
  breakPoints: { ...breakpointsOverrides },
});

const SchedulerTheme: CustomTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    ...secondaryColors,
  },
  colorTokens: colors,
};

export const styled = createThemedStyled<CustomTheme>();
export const withStyle = createThemedWithStyle<CustomTheme>();
export const useStyletron = createThemedUseStyletron<CustomTheme>();

export const stitchesConfig = createStitches({
  media: {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1280px)',
    '2xl': '(min-width: 1536px)',
  },
  theme: {
    colors: { ...theme.colors },
    borderWidths: { 0: '0px', 1: '1px', 2: '2px', 3: '3px', 4: '4px' },
    space: {
      ...theme.sizing,
    },
    sizes: {
      ...theme.sizing,
    },
    radii: {
      none: '0px',
      sm: '0.125rem' /* 2px */,
      border: '0.25rem' /* 4px */,
      md: '0.375rem' /* 6px */,
      lg: '0.5rem' /* 8px */,
      xl: '0.75rem' /* 12px */,
      '2xl': '1rem;' /* 16px */,
      '3xl': '1.5rem' /* 24px */,
      /* 100%px */
      full: '9999px',
    },
    fontWeights: {
      light: 400,
      normal: 500,
      semibold: 600,
      bold: 700,
      black: 800,
    },
  },
  utils: {
    // Abbreviated margin properties
    m: (value: Stitches.ScaleValue<'space'>) => ({
      margin: value,
    }),
    mt: (value: Stitches.ScaleValue<'space'>) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.ScaleValue<'space'>) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.ScaleValue<'space'>) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.ScaleValue<'space'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.ScaleValue<'space'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    p: (value: Stitches.ScaleValue<'space'>) => ({
      padding: value,
    }),
    pt: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.ScaleValue<'space'>) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.ScaleValue<'space'>) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    spaceY: (value: Stitches.ScaleValue<'space'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        marginTop: `${value}`,
      },
    }),
    spaceX: (value: Stitches.ScaleValue<'space'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        marginLeft: `${value}`,
      },
    }),
    padY: (value: Stitches.ScaleValue<'space'>) => ({
      '& > :not([hidden])': {
        paddingTop: value,
        paddingBottom: value,
      },
    }),
    padX: (value: Stitches.ScaleValue<'space'>) => ({
      '& > :not([hidden])': {
        paddingLeft: value,
        paddinRight: value,
      },
    }),
    divideX: (value: Stitches.ScaleValue<'borderWidths'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        borderLeftWidth: `${value} `,
        borderStyle: 'solid',
      },
    }),
    divideY: (value: Stitches.ScaleValue<'borderWidths'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        borderTopWidth: `${value}`,
        borderStyle: 'solid',
      },
    }),
    // TODO:Add sensible values for options
    transitionType: (value?: boolean) => ({
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      transitionDuration: '150ms',
    }),
    layout: (value: 'row' | 'stack') => {
      if (value === 'row') {
        return {
          display: 'flex',
          flexDirection: 'row',
        };
      }
      return {
        display: 'flex',
        flexDirection: 'column',
      };
    },
  },
});

export const useStitches = () => {
  const css = useCallback(
    (style: Stitches.CSS<typeof stitchesConfig.config>) =>
      stitchesConfig.css(style)(),
    [],
  );
  return { css, theme: SchedulerTheme };
};

export function parseToUtils<
  T extends Record<string, string | number | boolean>,
>(property: T, css: (key: keyof typeof property) => Stitches.CSS) {
  return Object.keys(property).reduce(
    (acc, cv) => ({
      ...acc,
      [cv]: css(cv),
    }),
    {},
  ) as { [key in keyof typeof property]: Stitches.CSS };
}

export default SchedulerTheme;
