import { KIND } from 'baseui/banner';
import { KIND as ButtonKind, SIZE as ButtonSize, SHAPE } from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Alert } from 'baseui/icon';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { Spinner } from 'baseui/spinner';
import { HeadingXSmall } from 'baseui/typography';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Banner from 'components/Banner';
import { FormControlLabel } from 'components/FormControl';

import { Button, FormController } from 'components';

import { useEditUserProfile } from 'services/mutations/users';
import useCurrentUser from 'services/queries/users';

import { useStyletron } from 'theme';

import { UserProfileFormFields } from './types';

interface Props {
  show: boolean;
  setShow: () => void;
  refreshUser: () => void;
}

export default function ProfileForm(props: Props) {
  const { show, setShow, refreshUser } = props;
  const [css, theme] = useStyletron();
  const userQuery = useCurrentUser();
  const userData = userQuery.user;
  const [isEditing, setEditing] = useState<boolean>(false);

  const { control, handleSubmit } = useForm<UserProfileFormFields>({
    defaultValues: {
      lastName: userData?.lastName,
      firstName: userData?.firstName,
      requestingOrganization: userData?.requestingOrganization,
    },
  });

  const [editProfile, editProfileMutation] = useEditUserProfile();
  const isSubmitting = editProfileMutation.status === 'running';

  const onEditProfile = () => {
    if (!isEditing) {
      setEditing(true);
      return;
    }

    onSubmitCallback();
  };

  const onSubmitCallback = handleSubmit((data) => {
    setEditing(true);

    editProfile(
      {
        ...data,
      },
      {
        onSuccess: () => {
          refreshUser();
        },
        onSettled: () => {
          setEditing(false);
        },
      },
    );
  });

  return (
    <Modal
      isOpen={show}
      onClose={setShow}
      autoFocus={false}
      role={ROLE.dialog}
      closeable={!isSubmitting}
    >
      <ModalHeader>User Profile</ModalHeader>
      <ModalBody style={{ flex: '1 1 0', overflowY: 'auto' }}>
        <Banner
          title="Failed to modify user profile details, please try again."
          show={editProfileMutation.status === 'failure'}
          artwork={{
            // eslint-disable-next-line react/no-unstable-nested-components
            icon() {
              return <Alert />;
            },
          }}
          kind={KIND.negative}
        >
          {' '}
        </Banner>

        {!isEditing ? (
          <FlexGrid as="dl" flexGridColumnCount={1} flexGridRowGap="scale700">
            <FlexGridItem>
              <InformationItem title="Full Name" content={userData?.name} />
            </FlexGridItem>
            <FlexGridItem>
              <InformationItem title="Email" content={userData?.email} />
            </FlexGridItem>
            <FlexGridItem>
              <InformationItem
                title="Organization/Office/Agency"
                content={userData?.requestingOrganization}
              />
            </FlexGridItem>
            <FlexGridItem>
              <InformationItem
                title="Verification Status"
                content={
                  userData?.status ? 'Fully Verified' : 'Partially Verified'
                }
              />
            </FlexGridItem>
          </FlexGrid>
        ) : (
          <form
            id="edit-profile-form"
            onSubmit={onSubmitCallback}
            className={css({
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <FormController
              control={control}
              name="firstName"
              defaultValue=""
              overrides={{
                ControlContainer: {
                  style: {
                    marginBottom: theme.sizing.scale400,
                  },
                },
              }}
              label={
                <FormControlLabel required description="Enter your first name">
                  First Name
                </FormControlLabel>
              }
              rules={{ required: 'This field is required' }}
              disabled={isSubmitting}
            >
              {({ field }) => <Input {...field} />}
            </FormController>
            <FormController
              control={control}
              name="lastName"
              defaultValue=""
              overrides={{
                ControlContainer: {
                  style: {
                    marginBottom: theme.sizing.scale400,
                  },
                },
              }}
              label={
                <FormControlLabel required description="Enter your last name">
                  Last Name
                </FormControlLabel>
              }
              rules={{ required: 'This field is required' }}
              disabled={isSubmitting}
            >
              {({ field }) => <Input {...field} />}
            </FormController>
            <FormController
              control={control}
              name="requestingOrganization"
              defaultValue=""
              label={
                <FormControlLabel
                  required
                  description="Enter your organization/office/agency name"
                >
                  Organization/Office/Agency
                </FormControlLabel>
              }
              rules={{ required: 'This field is required' }}
              disabled={isSubmitting}
            >
              {({ field }) => <Input {...field} />}
            </FormController>
          </form>
        )}
        {isSubmitting && (
          <div
            className={css({
              position: 'absolute',
              inset: 0,
              backgroundColor: theme.colors.white,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            <Spinner />
            <HeadingXSmall
              $style={{
                fontWeight: 600,
              }}
              color={theme.colors.primary}
            >
              Updating User Details
            </HeadingXSmall>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {isEditing && (
          <Button
            $as="div"
            size={ButtonSize.large}
            shape={SHAPE.pill}
            kind={ButtonKind.tertiary}
            // $style={{
            //   right: 0,
            //   position: 'relative',
            // }}
            onClick={() => setEditing(false)}
          >
            Back
          </Button>
        )}
        {!isSubmitting && (
          <Button
            $as="div"
            size={ButtonSize.large}
            shape={SHAPE.pill}
            $style={{
              right: 0,
              position: 'relative',
            }}
            onClick={onEditProfile}
          >
            Edit Profile
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

function InformationItem({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode;
}) {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        flexDirection: 'column',
        [theme.mediaQuery.large]: {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        },
      })}
    >
      <dt
        className={css({
          color: theme.colorTokens.black,
          [theme.mediaQuery.large]: {
            width: '45%',
          },
        })}
      >
        {title}
      </dt>
      <dd
        className={css({
          color: theme.colors.contentTertiary,
          [theme.mediaQuery.large]: {
            width: '55%',
          },
        })}
      >
        {content || '-'}
      </dd>
    </div>
  );
}
