import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldValues,
  UnpackNestedValue,
  UseFormTrigger,
} from 'react-hook-form';

export type TBookingForm<T extends FieldValues> = {
  id: string;
  initalValue?: Partial<T>;
  onInit?: (props: {
    trigger: UseFormTrigger<T>;
    handleSubmit: (
      onValid: (values: UnpackNestedValue<T>) => void,
      onError?: (errors: DeepMap<DeepPartial<T>, FieldError>) => void,
    ) => Promise<boolean>;
  }) => void;
  onSubmit: (formValues: T) => void;
  onChange?: (formValues: Partial<T>) => void;
  editable?: boolean;
  action?: BookingFormAction;
};

export enum BookingFormAction {
  CREATE,
  EDIT,
}

export type BookingOldFormFields = {
  type: {
    label: string;
    value: number;
  };
  date: Date;
  timeslot: {
    start: Date;
    end: Date;
  };
  description: string;
  location: string;
  agenda: string;
  files?: File[];
};

type SelectField = { label: string; value: number };

export type BasicInformationFormFields = {
  type: SelectField;
  date: Date;
  timeslot: {
    start: Date;
    end: Date;
  };
  files: File[];
  agenda: string;
  description: string;
};

export type EventInformationFormFields = {
  role: SelectField;
  dressCode?: SelectField;
  location?: {
    name?: string;
    address?: string;
  };
  platformPosted?: string;
  particiapantsCount: string;
  meetingLink?: string;
  country: string | undefined;
  city: string | undefined;
  province: string | undefined;
  region: string | undefined;
  barangay: string | undefined;
  streetAddress: string;
};

export type BookingReviewFormFields = {
  password: string;
};

export type ExpectedGuestFormFields = {
  guests: {
    id: number;
    name: string;
  }[];
  otherSector?: string;
};

export type AudienceTypesFormFields = {
  audienceTypes: {
    id: number;
    name: string;
  }[];
  otherAudienceType?: string;
};
