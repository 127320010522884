import React from 'react';

import { useBookingOptions } from 'services/queries/bookings';

import { useStitches } from 'theme';

import Box from './Box';
import Text from './Text';

export function TypeBadge({ type }: { type: number }) {
  const { css, theme } = useStitches();
  const optionsQuery = useBookingOptions();
  const meetingType = optionsQuery.bookingOptions?.meetingTypes?.find(
    (meeting) => meeting.id === type,
  );

  return (
    <Box layout="row" spaceX="scale200" css={{ alignItems: 'center' }}>
      <span
        className={css({
          width: theme.sizing.scale300,
          height: theme.sizing.scale300,
          display: 'block',
          borderRadius: '999px',
          backgroundColor: theme.colors.primary,
          flexShrink: 0,
          lineHeight: 0,
        })}
      />
      <Text variant="LabelSmall" css={{ textTransform: 'uppercase' }}>
        {meetingType?.name}
      </Text>
    </Box>
  );
}
