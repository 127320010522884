/* eslint-disable react/require-default-props */
import { HourglassBottom, Schedule, ViewAgenda } from '@mui/icons-material';
import { Card } from 'baseui/card';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Override } from 'baseui/overrides';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { formatDuration, intervalToDuration } from 'date-fns';
import { format } from 'date-fns-tz';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useBookings } from 'services/queries/bookings';

import { Booking } from 'types/bookings';

import { styled, useStyletron } from 'theme';

// eslint-disable-next-line import/extensions
import { getOverrides } from 'utils/overrides';

const List = styled('ul', ({ $theme }) => ({
  overflowY: 'auto',
  padding: $theme.sizing.scale700,
}));

export default function BookingList({
  overrides,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overrides?: Override<any>;
}) {
  /**
   * FIXME: Unstable no typings do not use yet for DEMO only
   */
  const [ListContainer, ListContainerProps] = getOverrides(
    overrides && overrides,
    List,
  );
  const [css, theme] = useStyletron();
  const [searchParams] = useSearchParams();
  const bookingsQuery = useBookings({
    filters: {
      date_order: searchParams.get('order') ?? 'asc',
      appointment_status: searchParams.get('status') ?? undefined,
    },
    config: {
      suspense: true,
      revalidateOnFocus: false,
    },
  });
  const bookings = bookingsQuery.bookings as Required<Booking[]>;

  if (bookings.length === 0) {
    return (
      <div
        className={css({
          // padding: theme.sizing.scale700,
          textAlign: 'center',
        })}
      >
        <LabelMedium>No Bookings found.</LabelMedium>
      </div>
    );
  }

  return (
    <ListContainer {...ListContainerProps}>
      {bookings.map((booking, key) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          className={css({
            marginTop: key !== 0 ? theme.sizing.scale600 : 0,
          })}
        >
          <AppointmentCard booking={booking} />
        </li>
      ))}
    </ListContainer>
  );
}
function AppointmentCard({ booking }: { booking: Booking }) {
  const [css, theme] = useStyletron();
  const [searchParams] = useSearchParams();
  const duration = formatDuration(
    intervalToDuration({
      start: booking.timeslot.start,
      end: booking.timeslot.end,
    }),
  );

  return (
    <Card
      overrides={{
        Root: {
          style: {
            position: 'relative',
            ':hover': {
              boxShadow: theme.lighting.shadow400,
              borderColor: theme.colors.primary,
              transitionTimingFunction: theme.animation.easeInCurve,
            },
          },
        },
      }}
    >
      <Link
        to={`${booking.bookingToken}${
          Array.from(searchParams.entries()).length !== 0
            ? `?${searchParams.toString()}`
            : ''
        }`}
      >
        <LabelMedium
          color="primary"
          className={css({
            fontWeight: 'bold',
            ':hover': {
              textDecorationLine: 'underline',
            },
          })}
        >
          <span
            aria-hidden="true"
            className={css({ position: 'absolute', inset: 0 })}
          />
          {format(booking.date, 'MMMM dd, yyyy', {
            timeZone: booking.timezone,
          })}
        </LabelMedium>
        <FlexGrid as="dl" flexGridRowGap="scale200" marginTop="scale200">
          <FlexGridItem display="flex" alignItems="start">
            <dt className={css({ lineHeight: 0 })}>
              {/* <Block sx={visuallyHidden} aria-hidden>Agenda</Block> */}
              <ViewAgenda sx={{ fontSize: 16, color: '#676767' }} />
            </dt>
            <LabelSmall
              color="contentTertiary"
              as="dd"
              flex="1"
              marginLeft="scale200"
            >
              {booking.agenda}
            </LabelSmall>
          </FlexGridItem>

          <FlexGridItem display="flex" alignItems="start">
            <dt className={css({ lineHeight: 0 })}>
              <HourglassBottom sx={{ fontSize: 16, color: '#676767' }} />
            </dt>
            <LabelSmall
              color="contentTertiary"
              as="dd"
              flex="1"
              marginLeft="scale200"
            >
              {duration}
            </LabelSmall>
          </FlexGridItem>
          <FlexGridItem display="flex" alignItems="start">
            <dt className={css({ lineHeight: 0 })}>
              <Schedule sx={{ fontSize: 16, color: '#676767' }} />
            </dt>
            <LabelSmall
              color="contentTertiary"
              as="dd"
              flex="1"
              marginLeft="scale200"
            >
              {format(booking.timeslot.start, 'hh:mm aa')}-
              {format(booking.timeslot.end, 'hh:mm aa')}
            </LabelSmall>
          </FlexGridItem>
        </FlexGrid>
      </Link>
    </Card>
  );
}
