/* eslint-disable react/no-unused-prop-types */

/* eslint-disable react/require-default-props */
import { Skeleton } from 'baseui/skeleton';
import React, { useEffect, useRef } from 'react';
import shallow from 'zustand/shallow';

import { VARIANT } from 'components/Button';
import {
  Part,
  Parts,
  Steps,
  useWizardStore,
} from 'components/WizardNavigation';

import { Button } from 'components';

import { useStyletron } from 'theme';

import AudienceTypesForm from './AudienceTypesForm';
import BasicInformationForm from './BasicInformationForm';
import EventInformationForm from './EventInformationForm';
import ExpectedGuestsForm from './ExpectedGuestsForm';
import ReviewBookingForm from './ReviewBookingForm';
import { BOOKING_FORM_STEPS } from './constants';
import { useBookingFormStore } from './store';
import type { BookingFormFields } from './store';
import { BookingFormAction } from './types';

export default function BookingForm({
  initialValues,
  action = BookingFormAction.CREATE,
  onSubmit: onFormSubmit,
  editable = true,
}: {
  initialValues?: Partial<BookingFormFields>;
  action?: BookingFormAction;
  editable?: boolean;
  onSubmit?: (fromValues: BookingFormFields) => void;
}) {
  const { setValue } = useBookingFormStore(
    (state) => ({
      setValue: state.setValue,
    }),
    shallow,
  );

  const formValues = useBookingFormStore((state) => {
    const { setValue: set, ...values } = state;
    return values;
  });

  const isInitialized = useRef<boolean>(false);
  useEffect(() => {
    if (!initialValues) {
      isInitialized.current = true;
      return;
    }
    if (isInitialized.current) return;
    Object.keys(initialValues).map((key) =>
      setValue(
        key as keyof BookingFormFields,
        initialValues[key as keyof BookingFormFields],
      ),
    );
    isInitialized.current = true;
  }, [setValue, initialValues]);

  if (action === BookingFormAction.EDIT && !isInitialized.current) {
    return <Skeleton rows={5} />;
  }

  return (
    <Steps>
      <Parts>
        <Part name="basic-information">
          {({ nextStep }) => (
            <BasicInformationForm
              action={action}
              id={BOOKING_FORM_STEPS[0][0].name}
              initalValue={formValues}
              onSubmit={(data) => {
                const hasChangedMeetingType =
                  data.type.value !== initialValues?.type?.value;
                const hasSelectedInitialType =
                  data.type.value === initialValues?.type?.value;

                Object.entries(data).forEach((entry) => {
                  const [key, value] = entry;
                  setValue(key as keyof BookingFormFields, value);
                });

                if (
                  action === BookingFormAction.EDIT &&
                  hasChangedMeetingType
                ) {
                  setValue('role', undefined);
                } else if (
                  action === BookingFormAction.EDIT &&
                  hasSelectedInitialType
                ) {
                  setValue('role', initialValues.role);
                }
                nextStep();
              }}
              editable={editable}
            />
          )}
        </Part>
      </Parts>
      <Parts>
        <Part name={BOOKING_FORM_STEPS[1][0].name}>
          {({ nextStep }) => (
            <EventInformationForm
              id={BOOKING_FORM_STEPS[1][0].name}
              initalValue={formValues}
              onSubmit={(data) => {
                Object.entries(data).forEach((entry) => {
                  const [key, value] = entry;
                  setValue(key as keyof BookingFormFields, value);
                });
                nextStep();
              }}
              onChange={(values) => {
                Object.entries(values).forEach((entry) => {
                  const [key, value] = entry;
                  setValue(key as keyof BookingFormFields, value);
                });
              }}
              editable={editable}
            />
          )}
        </Part>
      </Parts>
      <Parts>
        <Part name={BOOKING_FORM_STEPS[2][0].name}>
          {({ nextStep }) => (
            <ExpectedGuestsForm
              id={BOOKING_FORM_STEPS[2][0].name}
              initalValue={formValues}
              onSubmit={(data) => {
                Object.entries(data).forEach((entry) => {
                  const [key, value] = entry;
                  setValue(key as keyof BookingFormFields, value);
                });
                nextStep();
              }}
              editable={editable}
            />
          )}
        </Part>
      </Parts>
      <Parts>
        <Part name={BOOKING_FORM_STEPS[3][0].name}>
          {({ nextStep }) => (
            <AudienceTypesForm
              id={BOOKING_FORM_STEPS[3][0].name}
              initalValue={formValues}
              onSubmit={(data) => {
                Object.entries(data).forEach((entry) => {
                  const [key, value] = entry;
                  setValue(key as keyof BookingFormFields, value);
                });
                nextStep();
              }}
              editable={editable}
            />
          )}
        </Part>
      </Parts>
      <Parts>
        <Part name={BOOKING_FORM_STEPS[4][0].name}>
          <div>
            <ReviewBookingForm
              action={action}
              id={BOOKING_FORM_STEPS[4][0].name}
              onSubmit={(values) => {
                if (onFormSubmit) {
                  onFormSubmit(values);
                }
              }}
            />
          </div>
        </Part>
      </Parts>
    </Steps>
  );
}

const END_BUTTON_CONTENT: Record<BookingFormAction, string> = {
  '0': 'Create Booking',
  '1': 'Edit Booking',
};

export function BookingFormNavigation() {
  const [css] = useStyletron();
  const { nextStep, previousStep, set, currentStepName, currentStep } =
    useWizardStore(
      (state) => ({
        nextStep: state.meta.nextStepMeta,
        previousStep: state.meta.previousStepMeta,
        currentStepName: state.meta.currentStepName,
        currentStep: state.meta.step,
        set: state.setNewStep,
      }),
      shallow,
    );
  const action = useBookingFormStore((state) => state.actionType);

  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: previousStep ? 'space-between' : 'flex-end',
        alignItems: 'center',
      })}
    >
      {!!previousStep && (
        <Button
          onClick={() => set(previousStep.name)}
          variant={VARIANT.outline}
        >
          Previous
        </Button>
      )}
      <Button
        form={
          currentStep !== BOOKING_FORM_STEPS.length
            ? currentStepName
            : undefined
        }
      >
        {nextStep ? 'Next' : END_BUTTON_CONTENT[action]}
      </Button>
    </div>
  );
}
