/* eslint-disable react/no-unused-prop-types */

/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useRef } from 'react';

import { getErrorMessage } from 'utils/errors';

const DEFAULT_MESSAGE = 'Error encountered.';
const DEFAULT_401_MESSAGE = 'Unauthorized';
const DEFAULT_500_MESSAGE = 'Server Error';

export type ErrorMessageProps = {
  error: unknown;
  defaultMessage?: string;
  error500?: ((message: string) => React.ReactNode) | React.ReactNode;
  error400?: ((errors: Array<string>) => React.ReactNode) | React.ReactNode;
  error401?: ((message: string) => React.ReactNode) | React.ReactNode;
  error404?: ((message: string) => React.ReactNode) | React.ReactNode;
};

export default function ErrorMessage(props: ErrorMessageProps) {
  const {
    error,
    defaultMessage = DEFAULT_MESSAGE,
    error400,
    error401,
    error404,
    error500,
  } = props;
  const apiError = getErrorMessage(error);
  const error500Ref = useRef<typeof error500>(error500);
  const error401Ref = useRef<typeof error401>(error401);
  const error400Ref = useRef<typeof error400>(error400);
  const error404Ref = useRef<typeof error404>(error404);

  useEffect(() => {
    error500Ref.current = error500;
    error401Ref.current = error401;
    error400Ref.current = error400;
    error404Ref.current = error404;
  }, [error400, error401, error500, error404]);

  const message = useMemo(() => {
    const isAPIError = !!apiError.code;
    if (isAPIError) {
      const { code } = apiError;
      if (code === 400 && !!error400Ref.current) {
        const validationErrors =
          apiError?.errors?.map((err) => err.split(':')?.[1]).filter(Boolean) ??
          [];
        return typeof error400Ref.current === 'function' ? (
          <>{error400Ref.current(validationErrors)}</>
        ) : (
          error400Ref.current
        );
      }
      if (code === 401 && error401Ref.current) {
        return typeof error401Ref.current === 'function' ? (
          <>
            {error401Ref.current(apiError?.errors?.[0] ?? DEFAULT_401_MESSAGE)}
          </>
        ) : (
          error401Ref.current
        );
      }
      if (code === 404 && error404Ref.current) {
        return typeof error404Ref.current === 'function' ? (
          <>
            {error404Ref.current(apiError?.errors?.[0] ?? DEFAULT_401_MESSAGE)}
          </>
        ) : (
          error404Ref.current
        );
      }
      if (code === 500 && error500Ref.current) {
        return typeof error500Ref.current === 'function' ? (
          <>{error500Ref.current(apiError?.message ?? DEFAULT_500_MESSAGE)}</>
        ) : (
          error500Ref.current
        );
      }

      return defaultMessage;
    }

    return defaultMessage;
  }, [apiError, defaultMessage]);

  return <div>{message}</div>;
}
