import useSWR, { SWRConfiguration } from 'swr';

import { get } from 'services/api';

import { Message } from 'types/messages';

import { deserializeMessage } from 'deserializers/messages';

export async function getMessages(endpoint: string) {
  const response = await get(endpoint, {});
  return response.messages
    .reverse()
    .map((item: any) => deserializeMessage(item));
}

export function useMessages(id: string, config?: SWRConfiguration) {
  const { data, error, isValidating, mutate } = useSWR<Message[]>(
    id ? `/bookings/${id}/messages` : null,
    getMessages,
    {
      ...(config ?? {}),
    },
  );

  return {
    messages: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    error,
    mutate,
  };
}
