import { Message } from 'types/messages';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deserializeMessage(data: any): Message {
  return {
    id: data.id,
    authorName: data.author_name,
    dateSent: data.datetime_send.includes('T') ? new Date(data.datetime_send) : new Date(data.datetime_send.replace(/-/g, '/')),
    isAuthor: data.is_author,
    isDiscussion: data.is_discussion,
    isNote: data.is_note,
    isNotification: data.is_notification,
    message: data.message,
    message_type: data.message_type,
    event_token: data.event_token || '',
  };
}
