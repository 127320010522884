import ApiError from 'api/errors';

type ErrorWithMessage = {
  message: string;
  code?: number;
  errors?: string[];
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function isAPIError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isAPIError(maybeError)) {
    return {
      message: maybeError.name,
      code: maybeError.code,
      errors: maybeError.errors?.reduce(
        (all, err) => [...all, err.error],
        [] as string[],
      ),
    };
  }
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error);
}
