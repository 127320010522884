import { Spinner } from 'baseui/icon';
import { SnackbarProvider } from 'baseui/snackbar';
import React, { Suspense, lazy, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import Authentication from 'views/Authentication';
import BookAppointment, { BookSuccess } from 'views/BookAppointment';
import BookingInformation, {
  CancelBooking,
  EditBooking,
} from 'views/BookingInformation';
import DashboardLayout, {
  DashboardHome,
  MobileDashboardHome,
} from 'views/Dashboard';
import ForgotPassword from 'views/ForgotPassword';
import Login from 'views/Login';
import Logout from 'views/Logout';
import ResetPassword from 'views/ResetPassword';
import Signup from 'views/Signup';
// VP Pages
import Verify, { VerifyEmail } from 'views/Verify';

import MobileDashboardLayout from 'layouts/MobileDashboardLayout';

import SplashScreen from 'components/SplashScreen';

import useCurrentUser from 'services/queries/users';

import { useStitches } from 'theme';

import { checkTokens, getTokens } from 'utils';

const VPRoutes = lazy(() => import('routes/VPRoutes'));

function SplittedRoute({ element }: { element: JSX.Element }) {
  const { css } = useStitches();

  return (
    <Suspense
      fallback={
        <div
          className={css({
            width: '100%',
            height: '100%',
            layout: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <Spinner />
        </div>
      }
    >
      {element}
    </Suspense>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  searchParams.set('redirect', location.pathname);

  if (!checkTokens()) {
    return (
      <Navigate
        to={`/?${searchParams.toString()}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

function CheckAuth({
  children,
  redirect = '/bookings',
}: {
  // eslint-disable-next-line react/require-default-props
  redirect?: string;
  children: JSX.Element;
}) {
  const isLoggedIn = checkTokens();
  const tokens = getTokens();
  const isAdmin = tokens?.isAdmin;

  if (isLoggedIn) {
    return <Navigate to={isAdmin ? '/appointments' : redirect} />;
  }

  return children;
}

function AuthorizeRole({
  children,
  useRole = 'CLIENT',
}: {
  // eslint-disable-next-line react/require-default-props
  useRole?: 'CLIENT' | 'VP';
  children: JSX.Element;
}) {
  const tokens = getTokens();
  const [showSplash, setShowSplash] = useState(false);
  const userQuery = useCurrentUser({
    loadingTimeout: 4000,
    onLoadingSlow() {
      setShowSplash(true);
    },
  });
  const isAdmin = tokens?.isAdmin;
  const { user } = userQuery;

  if (showSplash) {
    return <SplashScreen />;
  }

  if (!user) {
    return null;
  }

  const isAuthorized = () => {
    switch (useRole) {
      case 'CLIENT': {
        return !isAdmin && !user.isAdmin;
      }
      case 'VP': {
        return isAdmin && user.isAdmin;
      }

      default:
        return false;
    }
  };

  if (!isAuthorized()) {
    return <Navigate to="/logout" />;
  }

  return children;
}

function AuthRoutes() {
  return (
    <Routes>
      <Route element={<Authentication />}>
        <Route path="/" element={<Login />} />

        <Route path="signup" element={<Outlet />}>
          <Route path="" element={<Signup />} />
          <Route path="verify" element={<Verify />} />
        </Route>
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="password-reset" element={<ResetPassword />} />
    </Routes>
  );
}

function BookingRoutes() {
  return (
    <Routes>
      <Route path="" element={<DashboardLayout />}>
        <Route index element={<DashboardHome />} />
        <Route path=":bookingId" element={<BookingInformation />}>
          <Route path="cancel" element={<CancelBooking />} />
          <Route path="edit" element={<EditBooking />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

function MobileBookingRoutes() {
  return (
    <Routes>
      <Route path="" element={<MobileDashboardLayout />}>
        <Route index element={<MobileDashboardHome />} />
        <Route path=":bookingId" element={<BookingInformation />}>
          <Route path="cancel" element={<CancelBooking />} />
          <Route path="edit" element={<EditBooking />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

function App() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <SnackbarProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <CheckAuth>
                <AuthRoutes />
              </CheckAuth>
            }
          />
          <Route path="/logout" element={<Logout />} />

          <Route
            path="/book-appointment"
            element={
              <RequireAuth>
                <AuthorizeRole useRole="CLIENT">
                  <Outlet />
                </AuthorizeRole>
              </RequireAuth>
            }
          >
            <Route index element={<BookAppointment />} />
            <Route path="success" element={<BookSuccess />} />
          </Route>

          {isTabletOrMobile ? (
            <Route
              path="/bookings/*"
              element={
                <RequireAuth>
                  <MobileBookingRoutes />
                </RequireAuth>
              }
            />
          ) : (
            <Route
              path="/bookings/*"
              element={
                <RequireAuth>
                  <AuthorizeRole useRole="CLIENT">
                    <BookingRoutes />
                  </AuthorizeRole>
                </RequireAuth>
              }
            />
          )}
          {/* TODO:Add Authorization Route Helpers  */}
          <Route
            path="/appointments/*"
            element={
              <SplittedRoute
                element={
                  <RequireAuth>
                    <AuthorizeRole useRole="VP">
                      <VPRoutes />
                    </AuthorizeRole>
                  </RequireAuth>
                }
              />
            }
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
