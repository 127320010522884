import { BookingStatus } from 'types/bookings';

export const bookingStatus: Record<BookingStatus, string> = {
  Confirmed: 'Confirmed',
  Cancelled: 'Cancelled',
  Done: 'Done',
  'For Confirmation': 'For Confirmation',
  Draft: 'For Verification',
  Rep: 'Regrets with representative',
  'No Rep': 'Regrets with no representative',
};
