import React, { useRef } from 'react';

import { Block } from 'baseui/block';
import { format } from 'date-fns';
import { StatefulTooltip } from 'baseui/tooltip';
import {
  DayPicker,
  DayProps,
  useActiveModifiers,
  useDayRender,
} from 'react-day-picker';
import { Link } from 'react-router-dom';

import { useBookings } from 'services/queries/bookings';

import { useStyletron } from 'theme';

export default function BookingsCalendar() {
  return (
    <DayPicker
      mode="single"
      className="rdp-large"
      components={{
        Day: DayWithBooking,
      }}
    />
  );
}
function DayWithBooking({ date, displayMonth }: DayProps) {
  const [css, theme] = useStyletron();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);
  const { today } = useActiveModifiers(date, displayMonth);

  const { bookings: bookingsData } = useBookings();
  const bookings = bookingsData ?? [];
  const activeBookings = bookings?.filter(
    (booking) =>
      format(booking.date, 'MMMM dd yyyy') === format(date, 'MMMM dd yyyy'),
  );
  const hasBooking = activeBookings.length !== 0;

  return (
    <div
      className={css({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: `var(--rdp-cell-size)`,
        height: `var(--rdp-cell-size)`,
        ...theme.borders.border200,
        padding: theme.sizing.scale100,
      })}
    >
      <button
        type="button"
        {...dayRender.buttonProps}
        ref={buttonRef}
        className={css({
          ...theme.typography.LabelXSmall,
          display: 'inline-block',
          textAlign: 'right',

          ...(today
            ? {
              color: theme.colors.primary,
              fontWeight: 800,
            }
            : { color: theme.colors.contentTertiary }),
        })}
      >
        {format(date, 'd')}
      </button>
      {hasBooking && (
        <span
          title="Peek bookings"
          className={css({
            justifySelf: 'flex-start',
            flexShrink: 0,
            display: 'block',
            backgroundColor: theme.colors.primary,
            width: theme.sizing.scale200,
            height: theme.sizing.scale200,
            borderRadius: '999px',
          })}
        />
      )}
      {/* 
      TODO:Fix performance issue, in the meantime disable */}
      {/* {hasBooking && (
        <StatefulTooltip content={<BookingInfo date={date} />} returnFocus>
          <div
            className={css({
              cursor: 'pointer',
            })}
          >
            <span
              title="Peek bookings"
              className={css({
                justifySelf: 'flex-start',
                flexShrink: 0,
                display: 'block',
                backgroundColor: theme.colors.primary,
                width: theme.sizing.scale200,
                height: theme.sizing.scale200,
                borderRadius: '999px',
              })}
            />
          </div>
        </StatefulTooltip>
      )} */}
    </div>
  );
}

function BookingInfo({ date }: { date: Date }) {
  const [css, theme] = useStyletron();
  const { bookings: bookingsData } = useBookings({
    config: {
      dedupingInterval: 6000,
      refreshWhenHidden: false,
    },
  });
  const bookings = bookingsData ?? [];
  const activeBookings = bookings?.filter(
    (booking) =>
      format(booking.date, 'MMMM dd yyyy') === format(date, 'MMMM dd yyyy'),
  );

  return (
    <Block padding={theme.sizing.scale200}>
      You have {activeBookings.length} bookings for{' '}
      {format(date, 'MMMM dd, yyyy')}
      <ul className={css({ marginTop: theme.sizing.scale500 })}>
        {activeBookings.map((booking, index) => (
          <li
            key={booking.id}
            className={css({
              marginTop: index !== 0 ? theme.sizing.scale200 : 0,
            })}
          >
            <Link to={`${booking.id}`} title="Go to meeting">
              {booking.agenda}
            </Link>
          </li>
        ))}
      </ul>
    </Block>
  );
}
