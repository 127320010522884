import { MessageSharp } from '@mui/icons-material';
import { Block } from 'baseui/block';
import { KIND, SHAPE, SIZE } from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ChevronLeft } from 'baseui/icon';
import { Modal, SIZE as ModalSize } from 'baseui/modal';
import { Skeleton } from 'baseui/skeleton';
import {
  HeadingSmall,
  HeadingXSmall,
  LabelMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { format } from 'date-fns';
import 'day-picker.css';
import React, { Suspense, lazy, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import Button, { LinkButton, VARIANT } from 'components/Button';
import { TypeBadge } from 'components/TypeBadge';

import { Box, StatusBadge } from 'components';

import { BookingAttachments } from 'features/BookingAttachments';

import { useBooking } from 'services/queries/bookings';
import useCurrentUser from 'services/queries/users';

import { Booking, BookingStatus } from 'types/bookings';

import { useStyletron } from 'theme';

const Messages = lazy(() => import('../Dashboard/features/Messages'));

export default function BookingDetails() {
  const [css, theme] = useStyletron();
  const params = useParams();
  const { bookingId } = params;
  const { user } = useCurrentUser();

  return (
    <div className={css({ position: 'relative' })}>
      <Block display={['block', 'block', 'none']}>
        <LinkButton
          size={SIZE.compact}
          kind={KIND.tertiary}
          startEnhancer={<ChevronLeft size={24} />}
          to=".."
          overrides={{
            BaseButton: {
              style: {
                paddingLeft: 0,
                padding: 0,
                marginBottom: theme.sizing.scale600,
              },
            },
          }}
        >
          <span>Back to bookings</span>
        </LinkButton>
      </Block>
      <ErrorBoundary
        // eslint-disable-next-line react/no-unstable-nested-components
        FallbackComponent={({ resetErrorBoundary }) => (
          <div role="alert">
            <HeadingXSmall>Something went wrong</HeadingXSmall>
            <ParagraphSmall color={theme.colors.contentTertiary}>
              We can&apos;t get your bookings right now.
            </ParagraphSmall>
            <ParagraphSmall color={theme.colors.contentTertiary}>
              {!user?.status && 'Your account is not yet fully verified.'}
            </ParagraphSmall>
            <Button
              onClick={resetErrorBoundary}
              size={SIZE.compact}
              $style={{ marginTop: theme.sizing.scale300 }}
            >
              Try Again
            </Button>
          </div>
        )}
      >
        <Suspense
          fallback={
            <div
              className={css({
                padding: theme.sizing.scale700,
              })}
            >
              <Skeleton rows={4} animation />
            </div>
          }
        >
          <BookingAppointmentDetails id={bookingId ?? ''} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

function BookingAppointmentDetails({ id }: { id: string }) {
  const [css, theme] = useStyletron();
  const { bookingId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useCurrentUser();
  const { booking } = useBooking(id, { suspense: true });
  const bookingData = booking as Required<Booking>;
  const isCancelled = booking?.status === BookingStatus.CANCELLED;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <>
      <Outlet />
      <div
        className={css({
          maxWidth: `48rem`,
          marginLeft: 'auto',
          marginRight: 'auto',
          [theme.mediaQuery.large]: {
            marginLeft: 'unset',
            marginRight: 'unset',
            maxWidth: 'unset',
          },
        })}
      >
        <header>
          <div
            className={css({
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column-reverse',
              alignItems: 'flex-start',
              [theme.mediaQuery.large]: {
                alignItems: 'center',
                flexDirection: 'unset',
              },
            })}
          >
            <HeadingSmall>{bookingData.agenda}</HeadingSmall>
            <StatusBadge status={bookingData.status} />
          </div>
          <div className={css({ marginTop: theme.sizing.scale650 })}>
            <TypeBadge type={bookingData.meetingType} />
          </div>
        </header>

        <FlexGrid
          as="dl"
          flexGridColumnCount={1}
          marginTop="scale800"
          flexGridRowGap="scale700"
        >
          <FlexGridItem>
            <InformationItem
              title="Date/Time:"
              content={`${format(
                bookingData.timeslot.start,
                'MMMM dd, yyyy hh:mm aa',
              )}`}
            />
          </FlexGridItem>
          <FlexGridItem>
            <InformationItem
              title="Location"
              content={
                bookingData.location?.address && bookingData.location.name
                  ? `${bookingData.location.name},${bookingData.location.address}`
                  : 'No location set'
              }
            />
          </FlexGridItem>
          <FlexGridItem>
            <InformationItem title="Person in charge" content={user?.name} />
          </FlexGridItem>
          {/* <FlexGridItem>
            <InformationItem
              title="Attendees"
              content={
                <ul>
                  {bookingData.attendees.map((attendee) => (
                    <LabelMedium as="li" key={attendee.id}>
                      {attendee.name}
                    </LabelMedium>
                  ))}
                </ul>
              }
            />
          </FlexGridItem> */}
          <FlexGridItem>
            <InformationItem
              title="Details"
              content={
                bookingData.details ? (
                  <p>{bookingData.details}</p>
                ) : (
                  <div>
                    <LabelMedium>No details added</LabelMedium>
                    <LinkButton
                      to={`edit${
                        Array.from(searchParams.entries()).length !== 0
                          ? `?${searchParams.toString()}`
                          : ''
                      }`}
                      size={SIZE.mini}
                      kind={KIND.tertiary}
                      $style={{
                        marginTop: theme.sizing.scale300,
                        marginLeft: `-${theme.sizing.scale300}`,
                      }}
                    >
                      Add Details
                    </LinkButton>
                  </div>
                )
              }
            />
          </FlexGridItem>
          <FlexGridItem>
            <InformationItem
              title="Files"
              content={<BookingAttachments id={bookingId} />}
            />
          </FlexGridItem>
        </FlexGrid>

        {!isCancelled && (
          <Block marginTop={theme.sizing.scale1600}>
            <Box
              as="nav"
              layout={{
                '@initial': 'stack',
                '@xl': 'row',
              }}
              spaceY={{
                '@initial': 'scale500',
                '@xl': 'scale0',
              }}
              spaceX={{
                '@xl': 'scale500',
              }}
              className={css({
                position: 'relative',
              })}
            >
              <LinkButton
                to={`edit${
                  Array.from(searchParams.entries()).length !== 0
                    ? `?${searchParams.toString()}`
                    : ''
                }`}
                $style={{
                  width: '10rem',
                  [theme.mediaQuery.large]: {
                    width: '12rem',
                  },
                }}
                variant={VARIANT.outline}
                kind={KIND.primary}
              >
                Edit Booking
              </LinkButton>
              <LinkButton
                to="cancel"
                variant={VARIANT.outline}
                kind={KIND.secondary}
                $style={{
                  width: '10rem',
                  [theme.mediaQuery.large]: {
                    width: '12rem',
                  },
                }}
              >
                Cancel
              </LinkButton>
            </Box>
          </Block>
        )}
        {isTabletOrMobile && <MobileMessagesModal />}
      </div>
    </>
  );
}

function InformationItem({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode;
}) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        flexDirection: 'column',
        [theme.mediaQuery.large]: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        },
      })}
    >
      <dt
        className={css({
          width: '10rem',
          flexShrink: 0,
          color: theme.colors.contentTertiary,
        })}
      >
        {title}
      </dt>
      <dd>{content}</dd>
    </div>
  );
}

function MobileMessagesModal() {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={css({
          position: 'fixed',
          bottom: theme.sizing.scale1200,
          right: 0,
          paddingRight: theme.sizing.scale700,
        })}
      >
        <Button onClick={() => setIsOpen(true)} shape={SHAPE.circle}>
          <MessageSharp />
        </Button>
      </div>
      <Modal
        onClose={() => setIsOpen(false)}
        size={ModalSize.default}
        overrides={{
          DialogContainer: {
            style: {
              alignItems: 'flex-end',
            },
          },
          Dialog: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '80vh',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              [theme.mediaQuery.large]: {
                width: '60vw',
                borderBottomLeftRadius: theme.sizing.scale600,
                borderBottomRightRadius: theme.sizing.scale600,
              },
            },
          },
        }}
        closeable
        isOpen={isOpen}
      >
        <Suspense fallback={<div>Loading</div>}>
          <Messages />
        </Suspense>
      </Modal>
    </>
  );
}
