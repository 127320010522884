import { Spinner } from 'baseui/spinner';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import BookingForm from 'features/BookingForm';
import { BookingFormNavigation } from 'features/BookingForm/BookingForm';
import {
  BookingFormFields,
  useBookingFormStore,
} from 'features/BookingForm/store';
import { BookingFormAction } from 'features/BookingForm/types';

import { useStyletron } from 'theme';

export default function CreateBooking({
  onSubmit: onSubmitHandler,
}: {
  onSubmit: (data: BookingFormFields) => void;
}) {
  const [css, theme] = useStyletron();
  const setValue = useBookingFormStore((state) => state.setActionType);
  const resetFormStore = useBookingFormStore((state) => state.resetValues);
  const [isInit, setIsInit] = useState(false);

  useLayoutEffect(() => {
    if (isInit) return;
    resetFormStore();
    setIsInit(true);
  }, [resetFormStore, isInit]);

  useEffect(() => {
    setValue(BookingFormAction.CREATE);
  }, [setValue]);

  if (!isInit) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <BookingForm onSubmit={onSubmitHandler} />
      <BookingFormNavigation />
    </>
  );
}
