import { Block } from 'baseui/block';
import { SHAPE } from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Input } from 'baseui/input';
import { HeadingXSmall, LabelMedium, LabelSmall } from 'baseui/typography';
import { CAPTCHA_TOKEN } from 'constants/tokens';
import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RegisterOptions, useForm } from 'react-hook-form';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Button, FormControl } from 'components';

import { useLogin } from 'api/auth';
import ApiError from 'api/errors';

import { styled, useStyletron } from 'theme';

import { storeTokens } from 'utils';

type FormFields = {
  email: string;
  password: string;
  // captchaVerfied: boolean;
};

const Centered = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [css, theme] = useStyletron();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { register, handleSubmit, formState } = useForm<FormFields>();
  const [login, loginMutation] = useLogin({
    onMutate: async () => {
      if (process.env.NODE_ENV === 'production') {
        await recaptchaRef.current?.executeAsync();
      }
    },
  });
  const errorMessage = () => {
    const apiError = loginMutation.error as ApiError;
    const errors: string[] = [];
    if (apiError.errors) {
      apiError.errors.forEach((responseError: { error: string }) => {
        const key = responseError.error.split(':').filter(Boolean)[0];
        const message = responseError.error.split(':').filter(Boolean)[1];
        if (key === 'error_description' || key === 'error') {
          errors.push(message);
        }
      });
      console.error(`${apiError.code} ${apiError.message}`, errors.join('\n'));
    }

    return errors[0] ?? 'Login failed,try again';
  };

  const { errors } = formState;
  const onSubmit = handleSubmit(async (data) => {
    login(
      {
        email: data.email,
        password: data.password,
      },
      {
        onSuccess: ({ data: response }) => {
          storeTokens({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            tokenType: response.tokenType,
            isAdmin: response.isAdmin,
          });
          const basePage = response.isAdmin ? '/appointments' : '/bookings';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const fromPath = location.state?.from?.pathname as string;
          const from = fromPath || basePage;
          const redirect = searchParams.get('redirect');
          if (redirect) {
            navigate(redirect, { replace: true });
          } else {
            navigate(from, { replace: true });
          }
        },
      },
    );
  });

  const registerBaseWeb = (
    fieldName: keyof FormFields,
    options?: RegisterOptions<any, keyof FormFields>,
  ) => {
    const { onChange, onBlur, name, ref, disabled } = register(fieldName, {
      ...options,
    });
    return { onChange, onBlur, name, inputRef: ref, disabled };
  };

  useEffect(() => {
    recaptchaRef.current?.reset();
  }, []);

  return (
    <Block
      paddingLeft={[
        0,
        theme.sizing.scale900,
        theme.sizing.scale1600,
        theme.sizing.scale3200,
      ]}
      paddingRight={[
        0,
        theme.sizing.scale900,
        theme.sizing.scale1600,
        theme.sizing.scale3200,
      ]}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      })}
    >
      <FlexGrid flexGridColumnCount={1} flexGridRowGap={theme.sizing.scale1400}>
        <HeadingXSmall as={FlexGridItem} className={css({ fontWeight: 700 })}>
          Sign in
        </HeadingXSmall>
        <FlexGridItem>
          <form
            action=""
            onSubmit={onSubmit}
            className={css({
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <FormControl label="Email" error={errors.email?.message}>
              <Input
                type="email"
                {...registerBaseWeb('email', {
                  required: 'Enter email',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Enter valid email',
                  },
                })}
              />
            </FormControl>
            <FormControl label="Password" error={errors.password?.message}>
              <Input
                type="password"
                {...registerBaseWeb('password', { required: 'Enter password' })}
              />
            </FormControl>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              style={{
                opacity: 0,
                bottom: '48px',
              }}
              // asyncScriptOnLoad={() => {
              //   const el: HTMLElement | null =
              //     document.querySelector('.grecaptcha-badge');
              //   if (el && el.style) {
              //     el.style.bottom = '48px';
              //   }
              // }}
              sitekey={CAPTCHA_TOKEN}
            />
            <Button
              isLoading={
                loginMutation.status === 'running' || formState.isSubmitting
              }
              type="submit"
              shape={SHAPE.pill}
              $style={{
                width: '100%',
                alignSelf: 'center',
                maxWidth: '12rem',
              }}
            >
              Log in
            </Button>
            {loginMutation.status === 'failure' && (
              <LabelMedium
                className={css({
                  marginTop: theme.sizing.scale300,
                  textAlign: 'center',
                  color: theme.colors.negative,
                })}
              >
                {errorMessage()}
              </LabelMedium>
            )}
          </form>
        </FlexGridItem>
      </FlexGrid>

      <FlexGrid
        flexGridColumnCount={1}
        flexGridRowGap={theme.sizing.scale650}
        alignItems="center"
        className={css({ marginTop: theme.sizing.scale1400 })}
      >
        <Centered $as={FlexGridItem}>
          <Link to="signup">
            <LabelSmall
              color="primary"
              className={css({ textAlign: 'center' })}
            >
              No account? Sign up now
            </LabelSmall>
          </Link>
        </Centered>
        <Centered $as={FlexGridItem}>
          <Link to="forgot-password">
            <LabelSmall
              color="primary"
              className={css({ textAlign: 'center' })}
            >
              Forgot your password?
            </LabelSmall>
          </Link>
        </Centered>
        {/* <Centered
          $as={FlexGridItem}
          $style={{
            ':after': {
              content: '""',
              width: theme.sizing.scale4800,
              marginLeft: theme.sizing.scale200,
              ...theme.borders.border100,
            },
            ':before': {
              content: '""',
              width: theme.sizing.scale4800,
              marginRight: theme.sizing.scale200,
              ...theme.borders.border100,
            },
          }}
        >
          or
        </Centered> */}
        {/* <Centered $as={FlexGridItem}>
          <Button
            startEnhancer={<GoogleIcon />}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  color: colors.gray500,
                  maxWidth: '16rem',
                  backgroundColor: theme.colors.white,
                  ...theme.borders.border400,
                  ':hover': {
                    backgroundColor: theme.colors.white,
                    color: theme.colors.primary,
                  },
                  ':active': {
                    backgroundColor: theme.colors.white,
                    color: theme.colors.primary,
                  },
                },
              },
            }}
          >
            Continue with Google
          </Button>
        </Centered> */}
      </FlexGrid>
    </Block>
  );
}
