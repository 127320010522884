import React from 'react';

import { Outlet } from 'react-router-dom';

import { useStyletron } from 'baseui';
import { HeadingSmall } from 'baseui/typography';
import styles from './Authentication.module.css';
import seal from '../../assets/images/ovp-seal.png';
import vector from '../../assets/images/scheduler-vector.png';
import vectorDark from '../../assets/images/scheduler-vector-black.png';

export default function Authentication() {
  const [css, theme] = useStyletron();

  // TO FIX STYLES
  return (
    <div
      className={css({
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        [theme.mediaQuery.medium]: {
          flexDirection: 'row',
        },
      })}
    >
      <div
        className={css({
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: theme.sizing.scale800,
          [theme.mediaQuery.medium]: {
            display: 'none',
          },
        })}
      >
        <img
          className={styles.seal}
          src={seal}
          width={120}
          height={120}
          alt="ovp seal"
        />
        <img
          className={styles.vector}
          src={vectorDark}
          width={230}
          height={40}
          alt="scheduler vector"
        />
        <HeadingSmall $style={{ textAlign: 'center', fontWeight: 400 }}>
          FOR THE OFFICE OF THE VICE PRESIDENT
        </HeadingSmall>
      </div>
      <div
        className={css({
          display: 'none',
          [theme.mediaQuery.medium]: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.primary,
          },
        })}
      >
        <img
          className={styles.seal}
          src={seal}
          width={200}
          height={200}
          alt="ovp seal"
        />
        <img
          className={styles.vector}
          src={vector}
          width={230}
          height={40}
          alt="scheduler vector"
        />
        <HeadingSmall
          color={theme.colors.white}
          $style={{ textAlign: 'center', fontWeight: 400 }}
        >
          FOR THE OFFICE OF THE VICE PRESIDENT
        </HeadingSmall>
      </div>
      <div className={css({ flex: 1 })}>
        <Outlet />
      </div>
    </div>
  );
}
