/* eslint-disable react/require-default-props */
import { KIND } from 'baseui/button';
import { FileUploader, FileUploaderProps } from 'baseui/file-uploader';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { SIZE } from 'baseui/input';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/Button';

import { styled, useStyletron } from 'theme';

export type FileInputProps = {
  defaultValue?: File[];
  onChange?: (value: File[]) => void;
} & FileUploaderProps;

const StyledRoot = styled<any, { $hasError: boolean }>(
  'div',
  ({ $theme, $hasError }) => ({
    ...$theme.borders.border400,
    borderRadius: $theme.sizing.scale400,
    ...($hasError
      ? {
          backgroundColor: $theme.colors.negative50,
          borderColor: $theme.colors.negative,
        }
      : { backgroundColor: $theme.colors.fileUploaderBackgroundColor }),
  }),
);

export default function FileInput({
  defaultValue = [],
  onChange,
  accept = [`application/pdf`], // default accept type for FileInput is PDF
  ...BaseFileUploadProps
}: FileInputProps) {
  const [css, theme] = useStyletron();
  const [files, setFiles] = useState<File[]>(defaultValue);
  const onChangeRef = useRef<typeof onChange | null>(null);
  const { errorMessage, ...PropsWithoutError } = BaseFileUploadProps;
  const hasError = !!errorMessage;

  const blockPadding = {
    paddingLeft: theme.sizing.scale500,
    paddingRight: theme.sizing.scale500,
    paddingTop: theme.sizing.scale200,
    paddingBottom: theme.sizing.scale200,
  };

  useEffect(() => {
    if (onChangeRef.current) {
      onChangeRef.current(files);
    }
  }, [files]);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  return (
    <StyledRoot $hasError={hasError}>
      <FileUploader
        {...PropsWithoutError}
        accept={accept}
        onDrop={(acceptedFiles, rejectedFiles) => {
          // handle file upload...
          setFiles((prev) =>
            [...prev, ...acceptedFiles].reduce((allfiles, file) => {
              const isAdded = allfiles.some(
                (addedFile) => addedFile.name === file.name,
              );
              if (isAdded) {
                return allfiles;
              }
              return [...allfiles, file];
            }, [] as File[]),
          );
        }}
        overrides={{
          ButtonComponent: {
            props: {
              kind: KIND.tertiary,
              overrides: {
                BaseButton: {
                  style: {
                    marginTop: 0,
                  },
                },
              },
            },
          },
          FileDragAndDrop: {
            style: {
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              border: 0,
              backgroundColor: 'transparent',
              ...blockPadding,
            },
          },
          ContentMessage: {
            component: () => `Drop PDF files here to upload...`,
          },
        }}
      />
      {files.length !== 0 && (
        <section
          className={css({ marginTop: theme.sizing.scale300, ...blockPadding })}
        >
          <LabelMedium>Added Files</LabelMedium>
          <FlexGrid
            as="ul"
            flexGridRowGap={theme.sizing.scale300}
            marginTop={theme.sizing.scale200}
          >
            {files.map((file) => (
              <FlexGridItem
                key={file.name.split(' ').join('-')}
                as="li"
                className={css({ display: 'flex', alignItems: 'center' })}
              >
                <LabelSmall flex={1} minWidth={0}>
                  {file.name}
                </LabelSmall>
                <Button
                  type="button"
                  onClick={() =>
                    setFiles((prev) =>
                      prev.filter((fileItem) => fileItem.name !== file.name),
                    )
                  }
                  colors={{
                    color: theme.colors.negative,
                    backgroundColor: theme.colors.negative50,
                  }}
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  $style={{ marginLeft: theme.sizing.scale100 }}
                >
                  Remove
                </Button>
              </FlexGridItem>
            ))}
          </FlexGrid>
        </section>
      )}
    </StyledRoot>
  );
}
