/* eslint-disable react/no-unstable-nested-components */
import { Avatar } from 'baseui/avatar';
import { StatefulMenu } from 'baseui/menu';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { Skeleton } from 'baseui/skeleton';
import { LabelSmall, LabelXSmall } from 'baseui/typography';
import React, { useEffect, useState } from 'react';

import useCurrentUser from 'services/queries/users';

import { User } from 'types/auth';

import { useStyletron } from 'theme';

import ProfileForm from './ProfileForm';

export default function ProfileDropdown() {
  const [css, theme] = useStyletron();
  const { user, mutate } = useCurrentUser();
  const userData = user as Required<User>;
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      if (!user.requestingOrganization) {
        setOpen(true);
      }
    }
  }, [user]);

  if (!user) {
    return (
      <Skeleton
        animation
        height={theme.sizing.scale600}
        width={theme.sizing.scale2400}
        overrides={{
          Root: {
            style: { borderRadius: theme.sizing.scale300 },
          },
        }}
      />
    );
  }

  return (
    <>
      <StatefulPopover
        placement={PLACEMENT.bottomRight}
        returnFocus
        autoFocus
        content={() => (
          <div>
            <StatefulMenu
              items={[
                { label: 'View Profile' },
                { label: 'Logout', href: '/logout' },
              ]}
              onItemSelect={({ item }) => {
                if (item.label === 'View Profile') {
                  setOpen(true);
                }
              }}
              overrides={{
                List: {
                  style: {
                    width: '250px',
                  },
                },
              }}
            />
          </div>
        )}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          })}
        >
          <Avatar
            name={userData.name ?? ''}
            src="https://avatars.dicebear.com/api/human/yard.svg?width=285&mood=happy"
            size="32px"
          />
          {userData.status ? (
            <LabelSmall
              marginLeft="scale200"
              className={css({ userSelect: 'none' })}
            >
              {userData.name ?? ''}
            </LabelSmall>
          ) : (
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              })}
            >
              <LabelSmall
                marginLeft="scale200"
                className={css({ userSelect: 'none' })}
              >
                {userData.name ?? ''}
              </LabelSmall>
              <LabelXSmall
                marginLeft="scale200"
                color={theme.colors.primary500}
                className={css({ userSelect: 'none' })}
              >
                *Partially Verified
              </LabelXSmall>
            </div>
          )}
        </div>
      </StatefulPopover>
      <ProfileForm
        show={isOpen}
        refreshUser={mutate}
        setShow={() => setOpen(!isOpen)}
      />
    </>
  );
}
