import { Options } from 'use-mutation';

import { sendForm } from "services/api";
import useMutation from 'utils/hooks/use-mutation';

type EditUserProfilePayload = {
  firstName: string;
  lastName: string;
  requestingOrganization: string;
};

export function editProfile(payload: EditUserProfilePayload) {
  const formData = new FormData();

  formData.append('first_name', payload.firstName);
  formData.append('last_name', payload.lastName);
  formData.append('request_organization', payload.requestingOrganization);

  return sendForm('/users/myprofile', 'PATCH', formData);
}

export function useEditUserProfile(
  options?: Options<EditUserProfilePayload, any, any>,
) {
  return useMutation(editProfile, options);
}
